// Core
import { useState, useEffect, useContext, useRef } from 'react';

// Context
import { LangContext } from 'contexts/LangContext';

// Hooks
import { useClickOutside } from 'hooks';

// Icons
import clock from 'images/clock.svg';
import clock_inactive from 'images/clock_inactive.svg';
import clock_red from 'images/clock_red.svg';

// Styles
import styles from './TimePicker.module.css';

const TimePicker = ({
	setTimeInHHMM,
	initialTimeInHHMM,
	stylesData,
	handleIsSelectedTimeValid,
	timezone,
	errors,
	fieldName,
}) => {
	const { lang } = useContext(LangContext);
	const containerRef = useRef(null);

	const [hoursValue, setHoursValue] = useState('');
	const [minutesValue, setMinutesValue] = useState('');
	const [timeList, setTimeList] = useState([]);
	const [isShow, setIsShow] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [isTimeSelected, setIsTimeSelected] = useState(false);

	useClickOutside(containerRef, () => setIsShow(false));

	useEffect(() => {
		if (initialTimeInHHMM) {
			const time = initialTimeInHHMM?.split(':');
			setHoursValue(time[0]);
			setMinutesValue(time[1]);
			setIsTimeSelected(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isShow) {
			makeTimeTable();
		}
	}, [isShow]);

	useEffect(() => {
		if (
			hoursValue &&
			minutesValue &&
			hoursValue.length === 2 &&
			minutesValue.length === 2
		) {
			setTimeInHHMM(`${hoursValue}:${minutesValue}`);
			setIsTimeSelected(true);
			handleIsSelectedTimeValid(true);
		} else if (!timezone && hoursValue === '' && minutesValue === '') {
			setTimeInHHMM('00:00');
			setIsTimeSelected(true);
			handleIsSelectedTimeValid(true);
		} else {
			setIsTimeSelected(false);
			handleIsSelectedTimeValid(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hoursValue, minutesValue]);

	const handleInput = (e) => {
		const value = e.target.value;
		if (value.length > 2 || value < 0) return;
		switch (e.target.name) {
			case 'inputHours':
				if (value <= 24) setHoursValue(value);
				break;
			case 'inputMinutes':
				if (value <= 60) setMinutesValue(value);

				break;
			default:
				break;
		}
	};

	// const handleTwentyfourClock = () => {
	// 	setTwentyfourClock((prev) => (prev === 12 ? 24 : 12));
	// 	setHoursValue((prev) => (prev > 12 ? hoursValue - 12 : prev));
	// };

	const makeTimeTable = () => {
		let timeList = [];
		for (let i = 0; i < 24; i++) {
			let hours = ('' + i).padStart(2, '0');
			for (let j = 0; j < 4; j++) {
				let mins = ('' + j * 15).padStart(2, '0');
				timeList.push(hours + ':' + mins);
			}
		}
		setTimeList(timeList);
	};

	const handleSelectTime = (e) => {
		const hours = e.target.innerText.slice(0, 2);
		const mins = e.target.innerText.slice(3);
		setHoursValue(hours);
		setMinutesValue(mins);
		handleShow();
	};

	const handleShow = () => {
		setIsShow((prev) => !prev);
	};

	const isShowTimeError =
		errors &&
		Object.keys(errors).length > 0 &&
		((fieldName === 'sale_date' &&
			errors.sale_date === 'delivery_later_sale') ||
			(fieldName === 'delivery_date' &&
				errors.delivery_date === 'delivery_later_sale'));

	return (
		<div className={styles.mainWrapper} ref={containerRef}>
			<div
				className={
					hoursValue || minutesValue
						? `${styles.inputWrapper} ${styles.inputBorderFocus} `
						: `${styles.inputWrapper}`
				}
				style={
					isShowTimeError ? { borderBottom: '2px solid var(--color-red)' } : {}
				}
				onClick={handleShow}
			>
				<div style={isShowTimeError ? { color: 'var(--color-red)' } : {}}>
					<input
						type="number"
						name="inputHours"
						value={hoursValue}
						className={
							isShow
								? `${styles.inputFocus} ${styles.inputHours}`
								: `${styles.inputHours}`
						}
						onChange={handleInput}
						placeholder={lang === 'ru' ? 'чч' : 'hh'}
						style={isShowTimeError ? { color: 'var(--color-red)' } : {}}
					/>
					&nbsp;:&nbsp;
					<input
						type="number"
						name="inputMinutes"
						value={minutesValue}
						className={
							isShow
								? `${styles.inputFocus} ${styles.inputMinutes}`
								: `${styles.inputMinutes}`
						}
						onChange={handleInput}
						placeholder={lang === 'ru' ? 'мм' : 'mm'}
						style={isShowTimeError ? { color: 'var(--color-red)' } : {}}
					/>
				</div>
				<div className={styles.twentyfourClock}>
					<img
						src={
							isShowTimeError
								? clock_red
								: hoursValue || minutesValue
								? clock
								: clock_inactive
						}
						alt=""
					/>
				</div>
			</div>

			{isShow && (
				<div
					className={styles.dropdownWrapper}
					stylesData
					style={
						stylesData && stylesData.dropdown ? { ...stylesData.dropdown } : {}
					}
				>
					<div className={styles.dropTable}>
						{timeList.length > 0 &&
							timeList.map((item, index) => {
								return (
									<div
										key={index}
										onClick={(e) => handleSelectTime(e)}
										className={
											`${hoursValue}:${minutesValue}` === item
												? styles.selectedTime
												: styles.singleTime
										}
									>
										<span>{item}</span>
									</div>
								);
							})}
					</div>
				</div>
			)}
		</div>
	);
};

export default TimePicker;
