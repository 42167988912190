// Core
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// Components
import { Button } from 'components/Buttons';
import { InfoButton } from 'components/Buttons';

// Contexts
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Constants
import ModalTypes from 'constants/modalTypes';

// Utils
import { checkPaymentStatus } from 'material-design/UserReportsWithPayment/utils/check-payment-status.util';
import { checkDisabledAmountStatus } from 'material-design/UserReportsWithPayment/utils/check-disabled-amount.util';
import { getRoyaltyAmount } from './utils/getRoyaltyAmount';

// Styles
import styles from './RoyaltyBanner.module.css';

const RoyaltyBanner = (props) => {
	const { accountId } = useContext(AuthContext);
	const { showModal } = useContext(UIContext);
	const {
		getAdminReportsNextDate,
		createInvoice,
		getAccountPayments,
	} = useContext(RootContext);
	const { lang } = useContext(LangContext);
	const history = useHistory();

	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [nextReportDate, setNextReportDate] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [royaltyToPay, setRoyaltyToPay] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [amountDisabled, setAmountDisabled] = useState(null);
	const [buttonText, setButtonText] = useState('rod.reports_page.withdraw');
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [paymentId, setPaymentId] = useState(null);

	const getRoyaltyToPay = (allReports) => {
		if (allReports.length) {
			allReports = allReports.sort(
				(a, b) => new Date(b.created_at) - new Date(a.created_at)
			);
			setRoyaltyToPay(
				Array.isArray(allReports) &&
					allReports.length &&
					allReports[0].royalties
					? allReports[0].royalties
					: null
			);
		}
	};

	const handleCreateInvoice = async () => {
		setButtonDisabled(true);
		if (redirectUrl) {
			history.push(`./reports/sign?id=${paymentId}&status=${redirectUrl}`);
			setButtonDisabled(false);
			return;
		}
		try {
			await createInvoice(accountId);
			history.push('./reports/check');
			return;
		} catch (error) {
			showModal(
				{
					title: (
						<FormattedMessage id={'rod.modal.payments.wrong_currency_title'} />
					),
					text: (
						<FormattedMessage id={'rod.modal.payments.wrong_currency_text'} />
					),
					onAction: () => {
						history.push(`/accounts/${accountId}/reports/check`);
					},
					withClose: true,
					confirmBtnTxt: (
						<FormattedMessage id={'rod.modal.payments.wrong_currency_action'} />
					),
				},
				ModalTypes.FULL_MODAL
			)();
		} finally {
			setButtonDisabled(false);
		}
	};

	useEffect(() => {
		if (redirectUrl) {
			setButtonText('rod.reports_page.withdraw_continue');
		}
	}, [redirectUrl]);

	useEffect(() => {
		const fetchData = async () => {
			const { data } = await getAccountPayments(accountId, 1, 1);
			if (data.length) {
				setRedirectUrl(checkPaymentStatus(data[0].status));
				setPaymentId(data[0].id);
				setAmountDisabled(checkDisabledAmountStatus(data[0].status));
			}
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getRoyaltyToPay(props.reports);
		getAdminReportsNextDate().then((res) => {
			if (res) {
				setNextReportDate(res.next_date);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.reports]);

	const getFormattedDate = useMemo(() => {
		if (!nextReportDate) {
			return '-';
		}
		moment.locale(lang);
		return moment(nextReportDate).format('DD MMMM YYYY');
	}, [lang, nextReportDate]);

	return (
		<>
			<div className={styles.royalty__wrapper}>
				<div className={styles.royalty__title}>
					<div>
						<FormattedMessage id={'rod.reports_page.current_royalty'} />
						<p
							className={`${styles.royalty__title_amount} ${
								props.minimumOutput ? styles.disabled : ''
							}`}
						>
							{getRoyaltyAmount(props.royaltyAmount)}
						</p>
						<p>
							<FormattedMessage id={'rod.reports_page.next_royalty_period'} />
							<span> {getFormattedDate}</span>
						</p>
					</div>
				</div>
				{/* disabled for dgtal hardcode */}
				{process.env.REACT_APP_CLIENT !== 'dgtal' && (
					<>
						<Button
							disabled={
								redirectUrl && process.env.REACT_APP_CLIENT !== 'dgtal'
									? false
									: buttonDisabled || process.env.REACT_APP_CLIENT === 'dgtal'
									? true
									: !props.isDisabled
							}
							variant={'primary'}
							onClick={handleCreateInvoice}
						>
							<FormattedMessage id={buttonText} />
						</Button>
						{props.minimumOutput && (
							<InfoButton
								className={styles.royalty__help}
								onClick={showModal({
									text: (
										<FormattedHTMLMessage
											id="rod.reports_page.insufficient_funds"
											values={{ value: getRoyaltyAmount(props.minimumOutput) }}
										/>
									),
								})}
							/>
						)}
					</>
				)}
			</div>
		</>
	);
};

export default RoyaltyBanner;
