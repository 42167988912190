// Core
import React, { useEffect, useState } from 'react';
import { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';

// UI
import { Loading } from 'components';
import { Modal, Shade } from './Posed';
import { Container } from 'components/Grid';
import BaseModal from '../BaseModal';
import { Button } from 'components/Buttons/Button';
import { InfoBar } from 'components';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Styles
import styles from './DistributionModal.module.css';

const DistributionModal = ({
	title,
	subtitle,
	onClose,
	submitHandler,
	disableSubmitBtn,
	children,
}) => {
	// eslint-disable-next-line no-unused-vars
	const [loading, setLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const willUnmount = (e) => {
		setIsVisible(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	return (
		<BaseModal className={styles.modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.overlay} />,
					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container className={styles.container} fluid>
							<div>
								<div
									md={12 - 4}
									onClick={(e) => {
										willUnmount(e);
									}}
								/>
								<div className={styles.bodyContainer}>
									{loading ? (
										<Loading />
									) : (
										<div className={styles.bodyWrapper}>
											<div className={styles.bodyTopWrapper}>
												<div></div>
												<CloseIcon
													onClick={(e) => {
														willUnmount(e);
													}}
													style={{ cursor: 'pointer' }}
												/>
											</div>
											<div className={styles.bodyHeader}>
												<div className={styles.bodyTitle}>
													<FormattedMessage id={title} />
												</div>
												{subtitle && (
													<div className={styles.bodySubtitle}>
														<FormattedMessage id={subtitle} />
													</div>
												)}
											</div>
											{children}
											<div>
												<InfoBar
													text="rod.distribution.date.modal.info"
													marginBottom="20px"
												/>
											</div>
											<Button
												text={<FormattedMessage id={'rod.action.save'} />}
												variant={'primary'}
												onClick={submitHandler}
												className={styles.saveBtn}
												disabled={disableSubmitBtn}
											/>
										</div>
									)}
								</div>
							</div>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default DistributionModal;
