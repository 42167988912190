/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 20/10/2020, 13:34
 */
// eslint-disable-next-line
const ModalTypes = {
	NONE: 'none',
	INFO: 'info',
	NOTIFICATION: 'notification',
	REPORTS: 'reports',
	REPORTS_MANUAL: 'reports_manual',
	ADD_SERVICE_MODAL: 'add_service',
	REPORTS_MANUAL_EDIT: 'reports_manual_edit',
	NEXT_REPORT_DATE: 'nextReportDate',
	NOTIFICATION_ADMIN_READ: 'notification_admin_read',
	NOTIFY_EDIT: 'notify_edit',
	DISTRIBUTION_DATE_EDIT: 'distribution_date_edit',
	DISTRIBUTION_OUTLETS_EDIT: 'distribution_outlets_edit',
	CONTRACT_SMS_SIGN: 'contract_sms_sign',
	BURGER_MODAL: 'burger_modal',
	REMOVE_MODAL: 'remove_modal',
	RUBRIC_MODAL: 'rubric_modal',
	INVOICE_NUMBER_MODAL: 'invoice_number_modal',
	FULL_MODAL: 'full_modal',
	CREATE_DISTRIBUTION: 'create_distribution',
	USER_EDIT: 'user_edit',
};

export default ModalTypes;
