// Core
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

// UI
import { Pagination } from 'material-design/components';

// Icons
import emptyCoverAlbum from 'images/playlist/emptyCoverAlbum.png';

// Components
import PlaylistEmpty from '../../PlaylistEmpty/PlaylistEmpty';
import PlaylistItemInTrack from '../../PlaylistItemInTrack/PlaylistItemInTrack';
import PeriodFilter from '../../PlaylistFilters/PeriodFilter/PeriodFilter';
import CountriesFilter from '../../PlaylistFilters/CountriesFilter/CountriesFilter';

// Styles
import styles from './ContentTrack.module.css';
import '../../PlaylistFilters/PeriodFilter/PeriodFilterForDate.css';
import PlaylistSkeleton from '../../PlaylistSkeleton/PlaylistSkeleton';
import TrackHeadingSkeleton from '../../TrackHeadingSkeleton/TrackHeadingSkeleton';

const ContentTrackPage = ({
	accountId,
	recording,
	playlists,
	total,
	page,
	setPage,
	outlet,
	currentPage,
	lang,
	handleCheckedPeriod,
	loadingPage,
	handleCheckedCountry,
	coverTrack,
	disabledMonth,
	disabledThreeMonth,
}) => {
	const [loading, setLoading] = useState(true);

	return (
		<div className={styles.wrapperTrackPage}>
			<div className={styles.contentTrackPage}>
				{loadingPage && !Object.keys(recording).length ? (
					<TrackHeadingSkeleton />
				) : (
					<div className={styles.trackHeading}>
						<img
							className={styles.trackMainPhoto}
							src={coverTrack.path_xm ?? emptyCoverAlbum}
							alt={recording.raw_title}
							width={48}
							height={48}
						/>
						<div>
							<h3 className={styles.trackTitle}>{recording.raw_title}</h3>
							<p className={styles.trackPerformer}>{recording.performers}</p>
						</div>
					</div>
				)}

				<div>
					<div className={styles.listPlaylists}>
						<div className={styles.wrapperFilters}>
							<PeriodFilter
								accountId={accountId}
								handleCheckedPeriod={handleCheckedPeriod}
								disabledMonth={disabledMonth}
								disabledThreeMonth={disabledThreeMonth}
								loading={loadingPage}
							/>
							<div className={styles.filterCountries}>
								<CountriesFilter
									accountId={accountId}
									handleCheckedCountries={handleCheckedCountry}
									lang={lang}
								/>
							</div>
						</div>

						{loadingPage ? (
							<div className={styles.skeletonWrapper}>
								<PlaylistSkeleton />
							</div>
						) : (
							<>
								{playlists.data?.length ? (
									<>
										<div
											className={`${styles.headerInfo} ${
												outlet === 'spotify' ? styles.spotify : {}
											} ${outlet === 'apple' ? styles.apple : {}}`}
										>
											<p className={styles.headerInfoName}>
												<FormattedMessage
													id={'rod.statistic.playlists.playlist_name'}
												/>
											</p>
											{outlet === 'spotify' && (
												<>
													<p className={styles.headerInfoStreams}>
														<FormattedMessage
															id={'rod.statistic.playlists.playlist_streams'}
														/>
													</p>
													<p className={styles.headerInfoFollowers}>
														<FormattedMessage
															id={'rod.statistic.playlists.playlist_followers'}
														/>
													</p>
												</>
											)}
											{outlet === 'apple' && (
												<p className={styles.headerInfoStreams}>
													<FormattedMessage
														id={'rod.statistic.playlists.playlist_pos'}
													/>
												</p>
											)}
											<p
												className={
													lang === 'en'
														? styles.headerInfoLinkEn
														: styles.headerInfoLink
												}
											>
												<FormattedMessage
													id={'rod.statistic.playlists.playlist_link'}
												/>
											</p>
										</div>
										<ul className={styles.playlistList}>
											{playlists.data.map((item, idx) => (
												<PlaylistItemInTrack
													key={idx}
													item={item}
													idx={idx}
													currentPage={currentPage}
													outlet={outlet}
												/>
											))}
										</ul>
									</>
								) : (
									<div className={styles.wrapperEmpty}>
										<PlaylistEmpty outlet={outlet} />
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			{total > 10 && playlists.data.length > 0 && (
				<div className={styles.paginationWrapper}>
					<Pagination
						page={page}
						total={total}
						paginate={(pageNumber) => {
							if (pageNumber !== page) {
								setLoading(true);
								setPage(pageNumber);
							}
						}}
						items_on_page={10}
					/>
				</div>
			)}
		</div>
	);
};

export default ContentTrackPage;
