// Core
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';

// Utils
import { getCheckedOutlets } from '../utils/getCheckedOutlets';
import { formatDateToDdMmYy } from '../../Distribution/utils/formatDateToDdMmYy';

// Styles
import s from './ConfirmDistributions.module.css';
import AdditionalDistributions from './AdditionalDistributions/AdditionalDistributions';
import DataBar from 'containers/Modal/DistributionModals/DataBar/DataBar';

const ConfirmDistributions = ({ personal, recommendedOutlets, outlets }) => {
	const { isAdditionalReleaseEnable, isTimeZoneForAccount } = useContext(
		AuthContext
	);
	const isH11Edit = window.location.href.includes('/edit/');

	return (
		<div className={s.container}>
			<div>
				<span className={s.title}>
					<FormattedMessage
						id={'rod.release.create.step.submit.distribution'}
					/>
				</span>
				{isTimeZoneForAccount && !isH11Edit ? (
					<>
						<DataBar
							personal={personal.distributions}
							allOutlets={outlets}
							containerStyles={{
								backgroundColor: 'var(--color-white)',
								border: 'none',
								marginTop: '24px',
								padding: '20px',
							}}
						/>
						{personal?.distributions?.distribution_outlets?.length > 0 && (
							<div className={s.distributionOutlets}>
								<span className={s.title}>
									<FormattedMessage id="rod.release.confirm.step.distribution.outlets.title" />
								</span>
								<DataBar
									personal={personal?.distributions?.distribution_outlets[0]}
									allOutlets={outlets}
									containerStyles={{
										backgroundColor: 'var(--color-white)',
										border: 'none',
										marginTop: '24px',
										padding: '20px',
									}}
								/>
							</div>
						)}
					</>
				) : (
					<div className={s.distributions}>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.distribution_date'}
								/>
							</span>
							<span className={s.item__text}>
								{personal?.distributions?.type === 'asap' ? (
									<FormattedMessage
										id={'rod.release.create.step.distribution.asap-date'}
									/>
								) : (
									formatDateToDdMmYy(personal?.distributions?.sale_start_date)
								)}
							</span>
						</div>
						<div className={s.item}>
							<span className={s.item__title}>
								<FormattedMessage
									id={'rod.release.create.step.submit.outlets_available'}
								/>
							</span>
							<span className={s.item__textLh}>
								{personal?.distributions?.outlets &&
									personal.distributions.outlets.split(',').length -
										getCheckedOutlets(personal, recommendedOutlets)}{' '}
							</span>
						</div>
					</div>
				)}
			</div>

			{isAdditionalReleaseEnable &&
				personal.tracks.some(
					(track) => track.additional && track.additional.length > 0
				) && <AdditionalDistributions tracks={personal.tracks} />}
		</div>
	);
};

export default ConfirmDistributions;
