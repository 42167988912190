// Core
import { useContext } from 'react';

// Context
import { LangContext } from 'contexts/LangContext';

// UI
import { Checkbox } from 'components/Checkboxes/Checkbox';
import DateButton from '../../DateButton/DateButton';

// Styles
import styles from './CheckboxOutlets.module.css';

const CheckboxOutlets = ({
	item,
	selectOutletsDateCheckbox,
	changeSelectDateOutletsCheckbox,
	outlets,
	personal,
	setPersonal,
}) => {
	const { lang } = useContext(LangContext);

	return (
		<div className={styles.checkboxOutletsContainer}>
			<Checkbox
				key={item.code}
				checked={selectOutletsDateCheckbox}
				text={
					lang === 'en'
						? 'add release date for individual outlets'
						: 'добавить дату релиза для отдельных витрин'
				}
				onChange={changeSelectDateOutletsCheckbox}
				className={styles.releaseDateCheckbox}
			/>
			{selectOutletsDateCheckbox && (
				<DateButton
					item={item}
					outlets={outlets}
					title="rod.release.create.step.distribution_outlet.button"
					modalType="distribution_outlets_edit"
					personal={personal}
					setPersonal={setPersonal}
					disabled={personal.distribution_outlets?.length}
				/>
			)}
		</div>
	);
};

export default CheckboxOutlets;
