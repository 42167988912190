// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { Label } from 'components/Labels';
import moment from 'moment';

// Styles
import styles from './PeriodFilter.module.css';

const PeriodFilter = ({
	handleCheckedPeriod,
	loading,
	disabledMonth,
	disabledThreeMonth,
	playlistsPage,
	setLabelPeriod,
}) => {
	const currentDay = new Date(new Date().getTime() - 86400000 * 2)
		.toISOString()
		.split('T')[0];

	const labelSelectItem = ['week', 'month', 'threeMonths'];

	const [checkedItemPeriod, setCheckedPeriodItem] = useState('week');

	const handleCheckedPeriodItem = (period) => {
		setCheckedPeriodItem(period);

		if (playlistsPage) {
			setLabelPeriod(period);
		}

		switch (period) {
			case 'week':
				const weekStart = moment().subtract(8, 'days').format('YYYY-MM-DD');
				handleCheckedPeriod(weekStart, currentDay);
				break;
			case 'month':
				const monthStart = moment().subtract(32, 'days').format('YYYY-MM-DD');
				handleCheckedPeriod(monthStart, currentDay);
				break;
			case 'threeMonths':
				const threeMonths = moment()
					.subtract(3, 'months')
					.subtract(2, 'days')
					.format('YYYY-MM-DD');
				handleCheckedPeriod(threeMonths, currentDay);
				break;
			default:
				break;
		}
	};

	const renderPeriodItem = (period) => {
		switch (period) {
			case 'week':
				return <FormattedMessage id="rod.statistic.period-selected.week" />;
			case 'month':
				return <FormattedMessage id="rod.statistic.period-selected.month" />;
			case 'threeMonths':
				return (
					<FormattedMessage id="rod.statistic.period-selected.three-month" />
				);
			default:
				return <FormattedMessage id="rod.statistic.period-selected.week" />;
		}
	};

	useEffect(() => {
		if (playlistsPage) {
			const playlistsPagination = JSON.parse(
				localStorage.getItem('playlistsPagination')
			);
			setCheckedPeriodItem(playlistsPagination?.period ?? checkedItemPeriod);
		}
	}, [checkedItemPeriod]);

	return (
		<FormControl variant="standard" className={styles.inputPeriodWrapper}>
			<Input id="period-select-label">
				<FormattedMessage id="rod.statistic.period-selected" />
			</Input>
			<div className={styles.wrapperCalendar}>
				<SelectCustom
					disabled={loading}
					labelId="period-select-label"
					id="period-select"
					value={labelSelectItem}
					className={styles.inputPeriod}
					MenuProps={{
						style: { zIndex: 1000 },
					}}
					renderValue={() => {
						return <span>{renderPeriodItem(checkedItemPeriod)}</span>;
					}}
				>
					<div className={styles.wrapperMenuItem}>
						{labelSelectItem.map((period) => (
							<MenuItem
								sx={{
									p: '13px 20px',
								}}
								key={period}
								className={styles.selectItem}
								disabled={
									(period === 'month' && disabledMonth) ||
									(period === 'threeMonths' && disabledThreeMonth)
								}
								value={period}
								onClick={() => handleCheckedPeriodItem(period)}
							>
								<Label>{renderPeriodItem(period)}</Label>
							</MenuItem>
						))}
					</div>
				</SelectCustom>
			</div>
		</FormControl>
	);
};

export default PeriodFilter;
