// Core
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as PlusIcon } from 'images/menu/plus.svg';
import { ReactComponent as AdminIcon } from 'images/menu/admin.svg';
import { ReactComponent as SettingsIcon } from 'images/menu/settings.svg';

// Styles
import styles from '../Menu.module.css';

const ItemWrapper = ({ hide, to, text, icon }) => {
	return (
		<Link to={to} onClick={hide}>
			<li className={styles.item_wrapper}>
				{icon === 'plus' && <PlusIcon width="14" height="14" />}
				{icon === 'admin' && <AdminIcon width="20" height="20" />}
				{icon === 'settings' && <SettingsIcon width="20" height="20" />}
				<div className={styles.Item}>
					<FormattedMessage id={text} />
				</div>
			</li>
		</Link>
	);
};

export default ItemWrapper;
