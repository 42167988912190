import Account from 'pages/adminV2/Account';
import List from 'pages/adminV2/List';
import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { admin } from 'services';
import { SignPosition } from './components/SignPosition';
import { SignupList } from './SignupList';
import { UIContext } from 'contexts/UIContext';
import { StatisticsService } from 'pages/adminV2/pages/StatisticsService';
import { Issue } from 'pages/adminV2/pages/StatisticsService/Issue';
import { NotificationsPage } from 'pages/adminV2/pages';
import { ArticlesPage, DistributionListSection } from 'pages/adminV2/pages';
import { RubricSettings } from 'pages/adminV2/pages';
import { HandlingRelease, HandlingDrafts } from './pages/Handling';
import { CreateArticle } from 'pages/adminV2/pages/Articles/CreateArticle';
import { DistributionPage } from 'pages/adminV2/pages/Reports/ReportsPage/DistributionPage';
import StatisticPhono from 'material-design/Statistic/StatisticPhono';
import StatisticCompo from 'material-design/Statistic/StatisticCompo';
let interval;
export default function AdminV2(props) {
	let { path } = useRouteMatch();
	const uiContext = useContext(UIContext);

	const {
		setIsAdminLayoutShow,
		isDisplayingStatistics,
		isManagementDisable,
		isHideAdminStat,
	} = uiContext;

	const update = () => {
		//    `/mvp/users`
		// http.get('/admin/users?limit=50').then((res) => {
		// 	setList(res);
		// 	setLoading(false);
		// });
	};

	useEffect(() => {
		setIsAdminLayoutShow();
		update();
		interval = setInterval(update, 1000 * 60 * 5);
		return () => {
			setIsAdminLayoutShow();
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Switch>
			<Route
				exact
				path={`${path}/signups/list`}
				render={({ match }) => {
					return <SignupList />; //
				}}
			/>
			<Route
				exact
				path={`${path}/signups/:signupId`}
				render={({ match }) => {
					return admin
						.getSignup(match.params.signupId)
						.then((res) => {
							res = res.data.data;
							return res;
						})
						.catch((error) => {
							console.error(
								'Admin - getSignup - api - error.response: ',
								error.response
							);
						});
				}}
			/>
			<Route
				exact
				path={`${path}/:accountId/contracts/:type/:id`}
				render={({ match }) => {
					return (
						<SignPosition
							id={match.params.accountId}
							backUrl={`${path}/${match.params.accountId}/general`}
						/>
					);
				}}
			/>
			<Route
				exact
				path={`${path}/:accountId/general`}
				render={({ match }) => {
					return <Account id={match.params.accountId} backUrl={path} />;
				}}
			/>
			<Route
				exact
				path={`${path}/:signupId/signup/general`}
				render={({ match }) => {
					return (
						<Account
							id={match.params.signupId}
							isSignup={true}
							backUrl={path}
						/>
					);
				}}
			/>

			<Route
				exact
				path={`${path}/:signupId/signup/status-history`}
				render={({ match }) => {
					return (
						<Account
							id={match.params.signupId}
							isSignup={true}
							activeTab={'status-history'}
						/>
					);
				}}
			/>
			<Route
				exact
				path={`${path}/:signupId/signup/account-data`}
				render={({ match }) => {
					return (
						<Account
							id={match.params.signupId}
							isSignup={true}
							activeTab={'account-data'}
						/>
					);
				}}
			/>
			<Route
				exact
				path={`${path}/:accountId/status-history`}
				render={({ match }) => {
					return (
						<Account id={match.params.accountId} activeTab={'status-history'} />
					);
				}}
			/>
			<Route
				exact
				path={`${path}/:accountId/account-data`}
				render={({ match }) => {
					return (
						<Account id={match.params.accountId} activeTab={'account-data'} />
					);
				}}
			/>
			<Route
				exact
				path={`${path}/:accountId/account-drafts`}
				render={({ match }) => {
					return (
						<Account id={match.params.accountId} activeTab={'account-drafts'} />
					);
				}}
			/>

			{isDisplayingStatistics && (
				<Route
					exact
					path={`${path}/:accountId/statistic/bydate`}
					render={({ match }) => {
						return (
							<Account id={match.params.accountId} activeTab={'statistic'} />
						);
					}}
				/>
			)}

			{isDisplayingStatistics && (
				<Route
					exact
					path={`${path}/:accountId/statistic/bydate/phonograms`}
					render={({ match }) => {
						return (
							<Account
								id={match.params.accountId}
								activeTab={'statistic'}
								isAdmin={true}
								chartMode={'phono'}
							/>
						);
					}}
				></Route>
			)}

			{isDisplayingStatistics && (
				<Route
					exact
					path={`${path}/:accountId/statistic/bydate/compositions`}
					render={({ match }) => {
						return (
							<Account
								id={match.params.accountId}
								activeTab={'statistic'}
								isAdmin={true}
								chartMode={'compo'}
							/>
						);
					}}
				></Route>
			)}

			{isDisplayingStatistics && (
				<Route
					exact
					path={`${path}/:accountId/statistic/byterritory`}
					render={({ match }) => {
						return (
							<Account id={match.params.accountId} activeTab={'statistic'} />
						);
					}}
				/>
			)}

			{isDisplayingStatistics && (
				<Route
					path={`${path}/:accountId/statistic/statistic-reports`}
					exact
					render={({ match }) => {
						return (
							<Account id={match.params.accountId} activeTab={'statistic'} />
						);
					}}
				/>
			)}

			<Route exact path={'/admin/v2/statistics_service/:outlet/:date/issues'}>
				<Issue />
			</Route>

			{isDisplayingStatistics && !isHideAdminStat && (
				<Route exact path={'/admin/v2/statistics_service'}>
					<StatisticsService />
				</Route>
			)}

			<Route exact path="/admin/v2/notifications">
				<NotificationsPage />
			</Route>
			<Route exact path="/admin/v2/articles">
				<ArticlesPage />
			</Route>
			<Route exact path="/admin/v2/reports">
				<DistributionListSection />
			</Route>

			<Route
				exact
				path="/admin/v2/reports/distributions/:distributionId"
				render={({ match }) => {
					return (
						<DistributionPage distributionId={match.params.distributionId} />
					);
				}}
			/>

			{!isManagementDisable && (
				<Route exact path="/admin/v2/handling_release">
					<HandlingRelease />
				</Route>
			)}
			{!isManagementDisable && (
				<Route exact path="/admin/v2/handling_drafts">
					<HandlingDrafts />
				</Route>
			)}
			<Route exact path="/admin/v2/articles/rubric-settings">
				<RubricSettings />
			</Route>
			<Route
				exact
				path="/admin/v2/articles/edit/:id"
				render={({ match }) => {
					return <CreateArticle articleId={match.params.id} />;
				}}
			/>

			<Route exact path="/admin/v2/articles/create">
				<CreateArticle />
			</Route>

			<Route exact path={path}>
				<List />
			</Route>

			{/* <Route exact path={path}>
				<List />
			</Route> */}
		</Switch>
	);
}
