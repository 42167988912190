// Core
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

export const getBannerData = () => {
	let data = {};

	data = {
		title: (
			<FormattedMessage
				id={'rod.main_page.greetings.holidays.title'}
			/>
		),
		text: (
			<FormattedHTMLMessage
				id={'rod.main_page.greetings.holidays.body'}
			/>
		),
	};

	return data;
};
