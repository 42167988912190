// Core
import React, { PureComponent } from 'react';
import { Visible } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import axios from 'axios';
import { auth } from 'services';

// UI
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Dropdown } from 'components/Dropdown';
import { Form } from 'components/Form';
import { Input, PhoneInput } from 'components/Inputs';
import { Label } from 'components/Labels';
import { Link } from 'components/Links';
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';

// Utils
import { withLang, withUI } from 'hocs';
import { showError } from 'validators/showError';
import { compose } from 'recompose';
import { capitalizeWords } from 'utils';

// Types
import PropTypes from 'prop-types';

// Constants
import { isForCom } from 'constants/urlConstants';

// Styles
import styles from 'forms/auth/Registration/Register.module.css';

class Register extends PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func,
		isEmailExist: PropTypes.func,
	};

	state = {
		agreed: false,
		validation: null,
		localErrors: {},
		captchaToken: null,
		loader: false,
		emailError: false,
		emailChecked: false,
		captchaType: '',
		captchaHead: '',
		captchaContainer: '',
		widgetId: null, // Store the widget ID here for cloudflare
		sitekey: null, // Store the dynamic sitekey for cloudflare
		isCaptchaVisible: false,
		isSubmitDisabled: false,
	};

	form = React.createRef();
	first_name = React.createRef();
	last_name = React.createRef();
	lang = React.createRef();
	phone = React.createRef();
	// honeypot for recognize bots
	phone_confirmation = React.createRef();
	email = React.createRef();
	password = React.createRef();
	password_confirmation = React.createRef();
	agreedCheckbox = React.createRef();
	captchaContainer = React.createRef();

	componentDidMount() {
		const savedEmail = localStorage.getItem('validatedEmail');
		const savedInvalidEmail = localStorage.getItem('invalidEmail');

		if (savedEmail && savedEmail === this.email.current.value) {
			this.setState({ emailChecked: true });
		} else if (savedInvalidEmail === this.email.current.value) {
			this.setState({ emailError: true });
		}

		auth
			.captcha()
			.then((res) => {
				const sitekeyMatch = res.data.container.match(/data-sitekey="([^"]+)"/);
				const sitekey = sitekeyMatch ? sitekeyMatch[1] : null;

				this.setState({
					captchaType: res.data.type,
					captchaHead: res.data.head,
					captchaContainer: res.data.container,
					sitekey,
				});

				if (res.data.type === 'cloudflare' || res.data.type === 'hcaptcha') {
					// Expose the callback globally for Cloudflare or hCaptcha to access
					window.javascriptCallback = this.verifyCallback;
				}
			})
			.catch((error) => console.error(error));
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.errors.email &&
			this.props.errors.email !== prevProps.errors.email
		) {
			this.email.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}

	componentWillUnmount() {
		localStorage.removeItem('validatedEmail');
		localStorage.removeItem('invalidEmail');

		// Remove the widget if it was rendered
		const { widgetId } = this.state;
		if (widgetId && window.turnstile) {
			window.turnstile.remove(widgetId);
			delete window.javascriptCallback;
		}
	}

	injectCaptcha = () => {
		const { captchaHead, captchaContainer } = this.state;

		// Inject captcha script in the head if present
		if (captchaHead) {
			const captchaScript = document.createElement('script');
			captchaScript.defer = true;
			captchaScript.src = captchaHead.match(/src="([^"]+)"/)[1];
			if (
				this.state.captchaType === 'cloudflare' ||
				this.state.captchaType === 'hcaptcha'
			) {
				captchaScript.onload = this.renderCaptchaWidget;
			}
			document.head.appendChild(captchaScript);
		}

		// Inject captcha container HTML into ref container
		if (
			this.state.captchaType !== 'cloudflare' &&
			this.state.captchaType !== 'hcaptcha' &&
			this.captchaContainer.current &&
			captchaContainer
		) {
			this.captchaContainer.current.innerHTML = captchaContainer;
		}

		this.observeCaptchaToken();
	};

	renderCaptchaWidget = () => {
		const { sitekey, captchaType } = this.state;

		if (
			captchaType === 'cloudflare' &&
			window.turnstile &&
			this.captchaContainer.current &&
			sitekey
		) {
			const widgetId = window.turnstile.render(this.captchaContainer.current, {
				sitekey,
				callback: (token) => {
					this.verifyCallback(token);
				},
			});
			this.setState({ widgetId });
		} else if (
			captchaType === 'hcaptcha' &&
			window.hcaptcha &&
			this.captchaContainer.current &&
			sitekey
		) {
			const widgetId = window.hcaptcha.render(this.captchaContainer.current, {
				sitekey,
				callback: (token) => {
					this.verifyCallback(token);
				},
			});
			this.setState({ widgetId });
		}
	};

	observeCaptchaToken = () => {
		const retryInterval = 1000;

		const intervalId = setInterval(() => {
			const tokenInput = this.captchaContainer.current?.querySelector(
				'input[name="smart-token"], input[name="cf-turnstile-response"]'
			);

			if (tokenInput && tokenInput.value) {
				this.setState({
					captchaToken: tokenInput.value,
					isSubmitDisabled: false,
				});
				clearInterval(intervalId);
			}

			// Additional check for hCaptcha token inside iframe
			const hcaptchaIframe = this.captchaContainer.current?.querySelector(
				'iframe[data-hcaptcha-response]'
			);

			if (hcaptchaIframe && hcaptchaIframe.dataset.hcaptchaResponse) {
				const hcaptchaToken = hcaptchaIframe.dataset.hcaptchaResponse;
				this.setState({ captchaToken: hcaptchaToken });
				clearInterval(intervalId);
				return;
			}
		}, retryInterval);
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		if (this.phone_confirmation.current.value) {
			return; // Stop form submission if honeypot is filled
		}

		const { captchaToken } = this.state;
		if (!captchaToken) {
			this.setState(
				{
					isCaptchaVisible: true,
					isSubmitDisabled: true,
				},
				() => this.injectCaptcha()
			);
			console.info('Please complete the captcha.');
			return;
		}

		const formData = {
			first_name: capitalizeWords(this.first_name.current.value),
			last_name: capitalizeWords(this.last_name.current.value),
			email: this.email.current.value,
			lang: isForCom ? 'en' : this.lang.current.value,
			phone: this.phone.current.value,
			phone_confirmation: this.phone_confirmation.current.value,
			password: this.password.current.value,
			password_confirmation: this.password_confirmation.current.value,
			'g-recaptcha-token': this.state.captchaToken,
			captcha_type: this.state.captchaType,
		};

		try {
			if (!this.state.emailChecked) {
				this.setState({ loader: true });
				const emailValidationResponse = await this.handleEmailMouseLeave();
				if (emailValidationResponse.status !== 'OK') {
					this.setState({ emailError: true });
					return;
				}
			}

			if (this.props.onSubmit) {
				this.props.onSubmit(formData);
			}
		} catch (error) {
			console.error('Error during email validation', error);
		} finally {
			this.setState({ loader: false });
		}
	};

	verifyCallback = (token) => {
		this.setState({ captchaToken: token, isSubmitDisabled: false });
	};

	handleAgreed = () => {
		this.setState({ agreed: !this.state.agreed });
	};

	handleShowTerms = (e) => {
		e.preventDefault();
		const {
			UIContext: { showModal },
		} = this.props;
		showModal({
			title: <FormattedHTMLMessage id={'rod.modal.terms.header'} />,
			text: <FormattedHTMLMessage id={'rod.modal.terms.text'} />,
			width: '807px',
		})();
	};

	handleLangErrorChange = (e) => {
		const target = 'lang';

		if (this.props.errors[target]) {
			delete this.props.errors[target];
			this.forceUpdate();
		}
	};

	checkInstanceAndRedirect = () => {
		const emailValue = this.email.current.value.trim();
		const { countryCode, url } = this.props;

		const client = axios.create({
			withCredentials: true,
			baseURL: `${url}`,
		});

		client.get(`/auth/email/check/${emailValue}`).then(() => {
			switch (countryCode) {
				case 'RU':
					if (
						document.location.origin === process.env.REACT_APP_REDIRECT_URL_RU
					) {
						return;
					} else {
						window.location.assign(
							`${process.env.REACT_APP_REDIRECT_URL_RU}/registration`
						);
					}
					break;
				case 'BY':
					if (
						document.location.origin === process.env.REACT_APP_REDIRECT_URL_RU
					) {
						return;
					} else {
						window.location.assign(
							`${process.env.REACT_APP_REDIRECT_URL_RU}/registration`
						);
					}
					break;
				default:
					if (
						document.location.origin === process.env.REACT_APP_REDIRECT_URL_COM
					) {
						return;
					} else {
						window.location.assign(
							`${process.env.REACT_APP_REDIRECT_URL_COM}/registration`
						);
					}
					break;
			}
		});
	};

	handleEmailMouseLeave = async () => {
		const emailValue = this.email.current.value.trim();
		const savedInvalidEmail = localStorage.getItem('invalidEmail');
		const { isAllowLanguageDetection } = this.props;

		if (!this.state.emailChecked && emailValue && !this.state.loader) {
			if (emailValue === savedInvalidEmail) return;

			try {
				this.setState({ loader: true });

				if (isAllowLanguageDetection) {
					this.checkInstanceAndRedirect();
				}

				const emailValidationResponse = await this.props.isEmailValid(
					emailValue
				);
				if (emailValidationResponse.status === 'OK') {
					localStorage.setItem('validatedEmail', emailValue);
					this.setState({ emailError: false, emailChecked: true });
				} else {
					this.setState({ emailError: true });
					localStorage.setItem('invalidEmail', emailValue);
				}
				return emailValidationResponse;
			} catch (error) {
				console.error('Error during email validation', error);
			} finally {
				this.setState({ loader: false });
			}
		}
	};

	handleInputBlur = (target) => {
		if (this[target].current.value && this.props.errors[target]) {
			delete this.props.errors[target];
			this.forceUpdate();
		}
	};

	handleInputChange = (target) => {
		if (target === 'email') {
			this.setState({ emailError: false, emailChecked: false });
		}

		if (this.props.errors[target]) {
			delete this.props.errors[target];
		}
		this.forceUpdate();
	};

	render() {
		let { errors } = this.props;
		const {
			emailError,
			loader,
			isCaptchaVisible,
			isSubmitDisabled,
		} = this.state;

		const {
			UIContext: { isPrivatePolicyShow },
		} = this.props;

		if (errors.password) {
			if (this.password?.current?.value.match(/[а-яА-Я]+/gi)) {
				errors.password = [{ rule: 'cyrillic_invalid' }];
			}
		}

		if (errors.password_confirmation) {
			if (this.password_confirmation?.current?.value.match(/[а-яА-Я]+/gi)) {
				errors.password_confirmation = [{ rule: 'cyrillic_invalid' }];
			}
		}

		return (
			<div className={styles.Register_wrapper}>
				<Form
					ref={this.form}
					onSubmit={this.handleSubmit}
					className={styles.form_wrapper}
				>
					<div className={styles.title}>
						<div className={styles.register_header}>
							<FormattedHTMLMessage id={'rod.register.header'} />
						</div>
						<div className={styles.register_subheader}>
							<FormattedHTMLMessage id={'rod.register.subheader'} />
						</div>
					</div>
					<FormattedMessage id={'rod.field.firstname'}>
						{(placeholder) => (
							<Input
								placeholder={placeholder}
								ref={this.first_name}
								required
								showError={showError('first_name')}
								maxLength={255}
								errors={errors?.first_name}
								onBlur={() => this.handleInputBlur('first_name')}
								onChange={() => this.handleInputChange('first_name')}
								className={styles.Register_Input}
								style={{ textTransform: 'capitalize' }}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.lastname'}>
						{(placeholder) => (
							<Input
								placeholder={placeholder}
								ref={this.last_name}
								required
								showError={showError('last_name')}
								maxLength={255}
								errors={errors?.last_name}
								onBlur={() => this.handleInputBlur('last_name')}
								onChange={() => this.handleInputChange('last_name')}
								className={styles.Register_Input}
								style={{ textTransform: 'capitalize' }}
							/>
						)}
					</FormattedMessage>
					<div className={styles.emailContainer}>
						<FormattedMessage id={'rod.field.email'}>
							{(placeholder) => (
								<Input
									placeholder={placeholder}
									ref={this.email}
									required
									showError={showError('email')}
									errors={errors.email}
									onChange={() => this.handleInputChange('email')}
									onMouseLeave={this.handleEmailMouseLeave}
									className={styles.Register_Input}
								/>
							)}
						</FormattedMessage>
						{this.state.loader && (
							<div className={styles.preLoader}>
								<LoaderWhithoutProgress />
							</div>
						)}
						{this.state.emailError && (
							<div className={styles.helper}>
								<FormattedMessage id={'rod.error.email.check.invalid'} />
							</div>
						)}
					</div>

					<FormattedMessage id={'rod.field.phone'}>
						{(placeholder) => (
							<PhoneInput
								name={'phone'}
								countryId={18}
								showError={showError('phone')}
								placeholder={placeholder}
								ref={this.phone}
								required
								errors={errors?.phone}
								onBlur={() => this.handleInputBlur('phone')}
								onChange={() => this.handleInputChange('phone')}
								className={styles.Register_Phone_Input}
							/>
						)}
					</FormattedMessage>
					{/* Hidden honeypot field */}
					<Input
						type={'number'}
						placeholder="phone_confirmation"
						ref={this.phone_confirmation}
						required
						className={styles.phoneСonfirmation}
					/>
					<FormattedMessage id={'rod.field.password'}>
						{(placeholder) => (
							<Input
								type="password"
								tip={<FormattedHTMLMessage id={'rod.field.password.tip'} />}
								placeholder={placeholder}
								ref={this.password}
								required
								showError={showError('password')}
								errors={errors?.password}
								onBlur={() => this.handleInputBlur('password')}
								onChange={() => this.handleInputChange('password')}
								className={styles.Register_Input}
							/>
						)}
					</FormattedMessage>
					<FormattedMessage id={'rod.field.password.confirmation'}>
						{(placeholder) => (
							<Input
								type="password"
								tip={<FormattedHTMLMessage id={'rod.field.password.tip'} />}
								placeholder={placeholder}
								ref={this.password_confirmation}
								showError={showError('password_confirmation')}
								errors={errors?.password_confirmation}
								onBlur={() => this.handleInputBlur('password_confirmation')}
								onChange={() => this.handleInputChange('password_confirmation')}
								className={styles.Register_Input}
								required
							/>
						)}
					</FormattedMessage>
					{!isForCom && (
						<FormattedMessage id={'rod.field.lang'}>
							{(placeholder) => (
								<Dropdown
									placeholder={placeholder}
									defaultEmpty
									required
									name={'lang'}
									errors={errors?.lang}
									showError={showError('lang')}
									forwardRef={this.lang}
									items={[
										{
											keyword: 'en',
											code: 'us',
											lexeme: 'rod.field.lang.en',
										},
										{
											keyword: 'ru',
											code: 'ru',
											lexeme: 'rod.field.lang.ru',
										},
									]}
									getItem={(item) => (
										<React.Fragment>
											<FormattedMessage id={item.lexeme} />
										</React.Fragment>
									)}
									getListItem={(item) => (
										<React.Fragment>
											<FormattedMessage id={item.lexeme} />
										</React.Fragment>
									)}
									getItemValue={(item) => item.keyword}
									onChange={this.handleLangErrorChange}
									className={styles.Register_Dropdown_Input}
								/>
							)}
						</FormattedMessage>
					)}

					<Checkbox
						className={styles.Checkbox}
						ref={this.agreedCheckbox}
						onChange={this.handleAgreed}
						required
					>
						{isPrivatePolicyShow ? (
							<FormattedMessage
								id={'rod.register.terms'}
								values={{
									a: (msg) => (
										<a
											href={`/examples/privacy_policy_v3.pdf`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{msg}
										</a>
									),
								}}
							/>
						) : (
							<FormattedMessage id={'rod.register.terms_no_link'} />
						)}
					</Checkbox>
					{isCaptchaVisible && (
						<div className={styles.captchaContainer}>
							<div
								id="captcha-container"
								className={styles['recaptcha-container']}
								ref={this.captchaContainer}
							/>
							{Object.keys(errors) && Object.keys(errors).includes('captcha') && (
								<div className={styles.captchaErrorHelper}>
									<FormattedMessage id={'rod.error.something_went_wrong'} />
								</div>
							)}
						</div>
					)}
					<div className="controls">
						<Button
							className={styles.login_button}
							text={<FormattedMessage id={'rod.action.register'} />}
							type={'submit'}
							disabled={
								!this.props.isAllowSignup ||
								this.props.isRegisterInProcess ||
								isSubmitDisabled ||
								emailError ||
								loader ||
								this.first_name.current?.value.trim() === '' ||
								this.last_name.current?.value.trim() === '' ||
								this.email.current?.value.trim() === '' ||
								this.phone.current?.value.trim() === '' ||
								this.password.current?.value.trim() === '' ||
								this.password_confirmation.current?.value.trim() === '' ||
								!this.agreedCheckbox.current?.checked
							}
							variant={'primary'}
						/>
					</div>
				</Form>
				<Visible xs>
					<div className={styles.login_wrapper}>
						<Label
							font="--gilroy-Medium-28"
							black
							className={styles.login_title_header}
						>
							<FormattedHTMLMessage
								id={'rod.register.text.already_have_account_title'}
							/>
						</Label>
						<Label
							font="--gilroy-Medium-18"
							black
							className={styles.login_title_body}
						>
							<FormattedHTMLMessage
								id={'rod.register.text.already_have_account'}
							/>
						</Label>
						<div className={styles.login_button_wrapper}>
							<Link to={'/login'}>
								<Button className={styles.login_button}>
									<FormattedHTMLMessage id={'rod.landing.form.action.login'} />
								</Button>
							</Link>
						</div>
					</div>
				</Visible>
			</div>
		);
	}
}

export default compose(withUI, withLang)(Register);
