// Core
import React from 'react';

// Icons
import closeBtn from 'images/close_black.svg';

// Styles
import s from './Greetings.module.css';

const Greetings = ({ onClickBtn, data }) => {
	return (
		<div
			className={s.container}
		>
			<button type="button" className={s.close__btn} onClick={onClickBtn}>
				<img src={closeBtn} alt="" />
			</button>
			{data.title ? (
				<h2
					className={s.title}
				>
					{data.title}
				</h2>
			) : (
				''
			)}

			<p
				className={s.text}
			>
				{data.text}
			</p>
		</div>
	);
};
export default Greetings;
