// Core
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withAuth, withRoot } from 'hocs';
import lscache from 'lscache';

// Types
import ModalTypes from 'constants/modalTypes';

// Utils
import { accounts } from 'services';
import { compose } from 'recompose';
import { setCache } from 'services/cacheHelper/setCache';
import { checkUploadCount } from './utils/checkUploadCount';
import { getTracksCountError } from 'utils';
import { dropZoneTitle } from './utils/dropZoneTitle';

// UI
import ModalConfirm from './ModalConfirm/ModalConfirm';
import { BottomNavi, InfoHelper } from 'components';
import Loader from 'components/Loader';
import { DropZone } from 'components/Upload/DropZone';
import { UploadPhonogramModal } from 'material-design/modals';
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';

// Icons
import stop_button from 'images/stop_button.svg';
import play_button from 'images/play_button.svg';
import remove_cart from 'images/remove_cart.svg';
import sort_dnd from 'images/sort_dnd.svg';
import update_file_icon from 'images/update_file_icon.svg';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';
import { RootContext } from 'contexts/RootContext';

// Styles
import s from './File.module.css';

function Recording(props) {
	const [recordings, setRecordings] = useState([]);
	const [errors, setErrors] = useState({});
	const [errorsValue, setErrorsValue] = useState('');
	const [serverError, setServerError] = useState(false);
	const [formatError, setFormatError] = useState({});
	const [
		canUploadMultipleComposition,
		setCanUploadMultipleComposition,
	] = useState(true);
	const [releaseType, setReleaseType] = useState('');
	const [releaseTypeId, setReleaseTypeId] = useState('');
	const [dropFilesAccepted, setDropFilesAccepted] = useState([]);
	const [audio] = useState(new Audio(''));
	const [arraySort, setArraySort] = useState([]);
	const [currentCard, setCurrentCard] = useState(null);
	const [uploadProgressPercents, setUploadProgressPercents] = useState([]);
	const [isAllFilesDownloaded, setIsAllFilesDownloaded] = useState(false);
	const [isReqLive] = useState(false);
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [
		recordingsReadyForCheckWithType,
		setRecordingsReadyForCheckWithType,
	] = useState(false);
	const [h11Moderation, setH11Moderation] = useState(false);
	const [audioLoading, setAudioLoading] = useState(false);
	const [disableBtn, setDisableBtn] = useState(false);

	let PROGRESS_DATA = [];
	let FILE_COUNT = [];

	const { accountId } = useContext(AuthContext);
	const {
		showModal,
		play,
		handlePlay,
		audioId,
		handlePlayStop,
		setIsBottomNaviShow,
	} = useContext(UIContext);
	const { getRecordingAudio } = useContext(RootContext);

	const maxSize = useMemo(() => 1024 * 1024 * 1024 * 3, []);
	const maxFileSize = useMemo(() => 1024 * 1024 * 191, []);
	const releaseId = localStorage.getItem('releaseId');
	const isH11Edit = window.location.href.includes('/edit/');
	const lang = localStorage.getItem('lang');
	const releaseEdit = JSON.parse(localStorage.getItem('releaseEdit'));
	const h11EditId = releaseEdit?.id;

	const isDraggable = !isH11Edit || (isH11Edit && h11Moderation);
	const history = useHistory();

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			if (isH11Edit && h11EditId) {
				history.push(`/release/${h11EditId}/edit/release`);
			} else {
				history.push(`/release/${releaseId}/release`);
			}
			audio.currentTime = 0;
			audio.pause();
			props.handlePrevStep();
		}
	};

	const nextHandler = () => {
		const isCountOk = checkRecorgingsCountAcordingReleaseType();
		if (typeof props.onChange === 'function' && isCountOk) {
			setDisableBtn(true);
			audio.currentTime = 0;
			audio.pause();
			props
				.onChange()
				.then()
				.catch()
				.finally(() => setDisableBtn(false));
		}
	};

	const getReleaseTypes = () => {
		const releaseTypesCache = lscache.get('releaseTypesCache');

		if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
			releaseTypesCache.data.map((item) => {
				if (item.id === props.releaseTypeId) {
					setReleaseType(item.title);
					setReleaseTypeId(item.id);
					item.is_compound === 0
						? setCanUploadMultipleComposition(false)
						: setCanUploadMultipleComposition(true);
				}
				return true;
			});
			return releaseTypesCache;
		} else {
			return accounts
				.getReleaseTypes(lang)
				.then((res) => {
					res = res.data.data;
					setCache('releaseTypes', 'releaseTypesCache', res);
					res.map((item) => {
						if (item.id === props.releaseTypeId) {
							setReleaseType(item.title);
							setReleaseTypeId(item.id);
							item.is_compound === 0
								? setCanUploadMultipleComposition(false)
								: setCanUploadMultipleComposition(true);
						}
						return true;
					});
					return Promise.resolve(res);
				})
				.catch((err) => {
					console.error(
						'GetReleaseTypes error in ReleaseCreation - File: ',
						err
					);
					return Promise.reject();
				});
		}
	};

	const getDropFilesCount = (files) => {
		setDropFilesAccepted(files);
	};

	const handleUploadError = (err) => {
		if (err === 'Network Error') {
			const fileWithError = dropFilesAccepted.filter((item) => {
				if (!item.done) {
					const index = dropFilesAccepted.indexOf(item);
					const filesDone = dropFilesAccepted.splice(index, 1);
					setDropFilesAccepted(filesDone);
					!recordings.length
						? setRecordings(dropFilesAccepted)
						: setRecordings(recordings);
					return item.name;
				} else return false;
			});
			const value = fileWithError.map((item) => item.name).join();
			setErrorsValue(value);
			return setErrors({
				upload: [{ rule: 'universal_error' }],
			});
		}

		if (err?.response?.status === 413) {
			const fileWithError = dropFilesAccepted.filter((item) => {
				if (!item.done) {
					const index = dropFilesAccepted.indexOf(item);
					const filesDone = dropFilesAccepted.splice(index, 1);
					setDropFilesAccepted(filesDone);
					!recordings.length
						? setRecordings(dropFilesAccepted)
						: setRecordings(recordings);
					return item.name;
				} else return false;
			});

			const value = fileWithError.map((item) => item.name).join();
			setErrorsValue(value);
			return setErrors({
				upload: [{ rule: 'max_size_error' }],
			});
		}

		if (err?.response?.status === 422) {
			const fileWithError = dropFilesAccepted.filter((item) => {
				if (!item.done) {
					const index = dropFilesAccepted.indexOf(item);
					const filesDone = dropFilesAccepted.splice(index, 1);
					setDropFilesAccepted(filesDone);
					!recordings.length
						? setRecordings(dropFilesAccepted)
						: setRecordings(recordings);
					return item.name;
				} else return false;
			});

			const value = fileWithError.map((item) => item.name).join();
			setErrorsValue(value);
			if (err.response?.data?.errors?.file[0]?.rule === 'file_type') {
				return setErrors({
					upload: [{ rule: 'file_type_error' }],
				});
			}
			return setErrors({
				upload: [{ rule: 'max_size_error' }],
			});
		}

		if (err?.response?.status === 429) {
			if (err?.response?.data?.errors?.file) {
				return setErrors({
					tracksCount: [
						{
							rule: err?.response?.data?.errors.file[0]?.rule,
							max: err?.response?.data?.errors.file[0]?.max,
						},
					],
				});
			}
		}

		if (err?.response?.status >= 500 && err?.response?.status < 600) {
			return setServerError(true);
		}

		if (err?.message?.includes('500')) {
			return setServerError(true);
		} else {
			dropFilesAccepted.filter((item) => {
				if (!item.done) {
					const index = dropFilesAccepted.indexOf(item);
					const filesDone = dropFilesAccepted.splice(index, 1);
					setDropFilesAccepted(filesDone);
					setRecordings([...recordings, ...dropFilesAccepted]);
					return item.name;
				} else return false;
			});
		}
	};

	const handleFileUpload = (files, recId) => {
		setErrors({});
		setFormatError({});
		let acceptedFiles;

		if (
			(releaseTypeId === 42 ||
				releaseTypeId === 43 ||
				releaseTypeId === 51 ||
				releaseTypeId === 70) &&
			files.length > 1
		) {
			acceptedFiles = files.slice(0, 1);
			setErrors({
				tracksCount: [
					{
						max: 1,
					},
				],
			});
		} else if (releaseTypeId === 64 && files.length > 7 && !recordings.length) {
			acceptedFiles = files.slice(0, 7);
			setErrors({
				tracksCount: [
					{
						max: 7,
					},
				],
			});
		} else if (
			releaseTypeId === 64 &&
			recordings.length &&
			files.length + recordings.length > 7
		) {
			acceptedFiles = files.slice(0, 7 - recordings.length);
			setErrors({
				tracksCount: [
					{
						max: 7,
					},
				],
			});
		} else if (
			(releaseTypeId === 69 || releaseTypeId === 2) &&
			files.length > 40 &&
			!recordings.length
		) {
			acceptedFiles = files.slice(0, 40);
			setErrors({
				tracksCount: [
					{
						max: 40,
					},
				],
			});
		} else if (
			(releaseTypeId === 69 || releaseTypeId === 2) &&
			recordings.length &&
			files.length + recordings.length > 40
		) {
			acceptedFiles = files.slice(0, 40 - recordings.length);
			setErrors({
				tracksCount: [
					{
						max: 40,
					},
				],
			});
		} else {
			acceptedFiles = files;
		}

		if (!canUploadMultipleComposition && dropFilesAccepted.length > 1) {
			return setErrors({
				upload: [{ rule: 'upload_only_one_track' }],
			});
		}

		if (!canUploadMultipleComposition && recordings.length && !isH11Edit) {
			return setErrors({
				upload: [{ rule: 'upload_only_one_track' }],
			});
		}

		if (canUploadMultipleComposition && recordings.length === '500') {
			return setErrors({
				upload: [{ rule: 'max_upload_count' }],
			});
		}

		if (serverError) {
			const uploadWithOutError = recordings.filter(
				(item) => item.done !== false
			);
			setRecordings([...uploadWithOutError]);
			setServerError(false);
		}

		const sumAudios = recordings.reduce(
			(acc, item) => (item.file ? acc + item.file.size : acc + item?.size),
			0
		);

		const emptySize = maxSize - sumAudios;

		const allowedMimeTypes = [
			'audio/wav',
			'audio/flac',
			'audio/x-flac',
			'audio/x-wav',
		];

		acceptedFiles?.forEach((file, index) => {
			if (allowedMimeTypes?.includes(file.type)) {
				if (isH11Edit && file.size > maxFileSize) {
					setErrors({
						upload: [{ rule: 'max_size_error' }],
					});
					return;
				}

				if (
					(!recordings.find(
						(item) =>
							(item.file ? item.file.client_name : item?.name) === file.name
					) &&
						emptySize - file.size >= 0) ||
					(isH11Edit && !emptySize)
				) {
					file.done = false;
					if (!recId) {
						setRecordings((prev) => [...prev, file]);
					} else {
						const updatedRecordings = recordings.map((recording) => {
							if (recording.id === recId) {
								return file;
							}
							return recording;
						});
						setRecordings(updatedRecordings);
					}

					const positionNum = index === 0 ? 1 : index + 1;
					const sortNum = recordings.length
						? recordings.length + positionNum
						: index + 1;
					const fd = new FormData();
					fd.append('file', file);
					fd.append('sort', sortNum);
					console.info('file_name', file.name);
					PROGRESS_DATA.push({
						key: file.name,
						value: 0,
					});

					const config = {
						onUploadProgress: (progressEvent) => {
							const percentCompleted = Math.round(
								(progressEvent.loaded * 100) / progressEvent.total
							);

							if (+progressEvent.loaded !== +progressEvent.total) {
								if (PROGRESS_DATA.length) {
									const checkFile = PROGRESS_DATA.filter(
										(item) => item.key === file.name
									);

									if (checkFile.length) {
										const data = PROGRESS_DATA.map((item) => {
											if (item.key === file.name) {
												item.value = percentCompleted;
											}

											return {
												key: item.key,
												value: item.value,
											};
										});

										PROGRESS_DATA = [...data];
									} else {
										PROGRESS_DATA.push({
											key: file.name,
											value: percentCompleted,
										});
									}
								}
							}
						},
					};

					const intervalId = setInterval(() => {
						if (FILE_COUNT.length === PROGRESS_DATA.length) {
							clearInterval(intervalId);
							PROGRESS_DATA = [];
							FILE_COUNT = [];
							setUploadProgressPercents([]);
						}
						setUploadProgressPercents(PROGRESS_DATA);
					}, 1000);

					if (!recId) {
						accounts
							.addRecording(releaseId, fd, config)
							.then((res) => {
								file.id = res.data.data.id;
								file.url = res.data.data.file.url;
								file.done = true;
								FILE_COUNT.push(file.id);
								setRecordings((prev) => [...prev]);
								setArraySort((prev) => [...prev, file.id]);
							})
							.catch((err) => {
								handleUploadError(err);
							});
					} else {
						try {
							accounts
								.updateRecording(
									h11EditId && isH11Edit ? h11EditId : releaseId,
									recId,
									fd,
									config
								)
								.then((res) => {
									FILE_COUNT.push(recId);
									setIsAllFilesDownloaded(true);
									const recData = res.data?.data;
									const updatedRecs = recordings.map((rec) =>
										rec.id === recId
											? {
													...recData,
													done: true,
													file: { ...recData.file, done: true },
											  }
											: rec
									);
									setRecordings(updatedRecs);
									setArraySort((prev) => [...prev, file.id]);
								})
								.catch((err) => {
									handleUploadError(err);
								});
						} catch (err) {
							handleUploadError(err);
						}
					}
				}
			} else {
				setFormatError({
					error: true,
					rule: 'track_format',
					name: file.name,
				});
			}
		});
	};

	const deleteRequest = (index, item) => {
		accounts
			.deleteRecording(h11EditId && isH11Edit ? h11EditId : releaseId, item.id)
			.then(() => {
				recordings.splice(index, 1);
				arraySort.splice(index, 1);
				if (
					audio &&
					(audio?.src === item?.url || audio?.src === item?.file?.url)
				) {
					handlePlayStop();
				}
				setRecordings([...recordings]);
				setArraySort([...arraySort]);
			})
			.catch((error) => {
				console.error(
					'DeleteRecording error in Release Creation - File: ',
					error
				);
			});
	};

	const handleDelete = (index, item) => {
		if (isH11Edit && h11Moderation && recordings.length === 1) {
			showModal(
				{
					title: (
						<FormattedMessage id={'rod.modal.release.delete.last_file.text'} />
					),
					withClose: true,
					confirmBtnTxt: (
						<FormattedMessage
							id={'rod.modal.release.delete.last_file.action'}
						/>
					),
				},
				ModalTypes.FULL_MODAL
			)();
		} else {
			showModal(
				{
					title: (
						<FormattedMessage id={'rod.modal.release.delete.file.title'} />
					),
					text: <FormattedMessage id={'rod.modal.release.delete.file.text'} />,
					onAction: () => {
						deleteRequest(index, item);
						setErrors({});
					},
					confirmBtnTxt: <FormattedMessage id={'rod.modal.yes_delete'} />,
					declineBtnTxt: <FormattedMessage id={'rod.modal.cancel'} />,
				},
				ModalTypes.FULL_MODAL
			)();
		}
	};

	const dragStartHandler = (e, item, index) => {
		if (!isAllFilesDownloaded) return;
		item.index = index;
		setCurrentCard(item);
	};

	const dragEndHandler = (e) => {
		if (!isAllFilesDownloaded) return;
		e.preventDefault();
		e.currentTarget.style.background = 'var(--color-white)';
	};

	const dragOverHandler = (e) => {
		if (!isAllFilesDownloaded) return;
		e.preventDefault();
		e.currentTarget.style.background = 'var(--color-light-green)';
	};

	const dropHandler = (e, item, index) => {
		if (!isAllFilesDownloaded) return;
		e.preventDefault();
		if (index === currentCard.index) return;
		[arraySort[index], arraySort[currentCard.index]] = [
			arraySort[currentCard.index],
			arraySort[index],
		];
		[recordings[index], recordings[currentCard.index]] = [
			recordings[currentCard.index],
			recordings[index],
		];
		accounts
			.sortRecordings(h11EditId && isH11Edit ? h11EditId : releaseId, {
				recordings: arraySort,
			})
			.then(() => {
				setRecordings([...recordings]);
				setArraySort([...arraySort]);
			})
			.catch((error) => {
				console.error(
					'SortRecordings error in Release Creation - File: ',
					error
				);
			});

		e.currentTarget.style.background = 'var(--color-white)';
	};

	const closeModal = () => {
		setShowRangeModal(false);
	};

	const onClickDisabledDropZone = () => {
		if (
			(releaseTypeId === 42 ||
				releaseTypeId === 43 ||
				releaseTypeId === 51 ||
				releaseTypeId === 70) &&
			recordings.length === 1
		) {
			setErrors({
				tracksCount: [
					{
						max: 1,
					},
				],
			});
		} else if (releaseTypeId === 64 && recordings.length === 7) {
			setErrors({
				tracksCount: [
					{
						max: 7,
					},
				],
			});
		} else if (
			(releaseTypeId === 69 || releaseTypeId === 2) &&
			recordings.length === 40
		) {
			setErrors({
				tracksCount: [
					{
						max: 40,
					},
				],
			});
		}
	};

	const isTracksCountNotValid =
		((releaseTypeId === 42 ||
			releaseTypeId === 43 ||
			releaseTypeId === 51 ||
			releaseTypeId === 70) &&
			recordings.length > 1) ||
		(releaseTypeId === 64 &&
			(recordings.length < 2 || recordings.length > 7)) ||
		((releaseTypeId === 2 || releaseTypeId === 69) &&
			(recordings.length < 2 || recordings.length > 40))
			? true
			: false;

	const isDropzoneDisabled =
		((releaseTypeId === 42 ||
			releaseTypeId === 43 ||
			releaseTypeId === 51 ||
			releaseTypeId === 70) &&
			recordings.length >= 1) ||
		(releaseTypeId === 64 && recordings.length >= 7) ||
		((releaseTypeId === 2 || releaseTypeId === 69) && recordings.length >= 40)
			? true
			: false;

	const checkRecorgingsCountAcordingReleaseType = () => {
		if (
			(!isH11Edit && isTracksCountNotValid) ||
			(isH11Edit && h11Moderation && isTracksCountNotValid)
		) {
			setShowRangeModal(true);
			return false;
		}
		return true;
	};

	const handlePreparedAudio = async (index, item) => {
		if (!item.file) {
			item.file = { ...item, client_name: item.path };
		}

		if (isH11Edit) {
			if (!item.file.url) {
				setAudioLoading(true);
				try {
					const res = await getRecordingAudio(accountId, item.h11_id);
					const updatedRecordings = [...recordings];
					updatedRecordings[index] = {
						...item,
						file: { ...item.file, url: res.path, ...res },
					};
					setRecordings(updatedRecordings);
					handlePlay(
						index,
						updatedRecordings[index].file,
						updatedRecordings[index].file.client_name
					);
				} catch (error) {
					console.error(error);
					setFormatError({
						error: true,
						rule: 'track_format',
						name: item.path,
					});
				} finally {
					setAudioLoading(false);
				}
			} else {
				handlePlay(index, item.file, item.file.client_name);
			}
		} else {
			handlePlay(index, item.file, item.file.client_name);
		}
	};

	const disabledNextBtn = () => {
		if (!recordings.every((item) => item.done) || recordings.length === 0) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (releaseId && !isH11Edit) {
			accounts
				.getReleaseData(releaseId)
				.then((res) => {
					const step = res.data.data?.step;
					if (step.includes('h11')) {
						const releaseEdit = { id: h11EditId, step: 'file' };
						localStorage.setItem('releaseEdit', JSON.stringify(releaseEdit));
					}
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		if (isH11Edit && h11EditId) {
			accounts
				.getReleaseData(h11EditId)
				.then((res) => {
					const step = res.data.data?.step;
					if (step === 'h11_moderation') {
						setH11Moderation(true);
					} else {
						setH11Moderation(false);
					}
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		accounts
			.getRecordingList(h11EditId && isH11Edit ? h11EditId : releaseId)
			.then((res) => {
				res = res.data.data;
				setRecordings(res);
				setRecordingsReadyForCheckWithType(true);
				res.forEach((item) => {
					item.done = true;
					arraySort.push(item.id);
				});
				setArraySort([...arraySort]);
				getReleaseTypes();
			})
			.catch((error) => {
				console.error(
					'GetRecordingList error in Release Creation - File: ',
					error
				);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			releaseTypeId &&
			recordings.length &&
			(!isH11Edit || (isH11Edit && h11Moderation))
		) {
			checkRecorgingsCountAcordingReleaseType();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseTypeId, recordingsReadyForCheckWithType]);

	useEffect(() => {
		if (recordings.length) {
			const data = recordings.find((item) => !item.done);

			if (data && isAllFilesDownloaded) {
				setIsAllFilesDownloaded(false);
			} else if (!data && !isAllFilesDownloaded) {
				setIsAllFilesDownloaded(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recordings]);

	return (
		<>
			<div className={s.page}>
				{showRangeModal && (
					<ModalConfirm
						releaseTypeId={releaseTypeId}
						closeModal={closeModal}
						h11Moderation={h11Moderation}
					/>
				)}
				<div className={s.main}>
					<span className={s.title}>
						<FormattedMessage
							id={
								isH11Edit
									? 'rod.release.create.step.edit.recording.title'
									: 'rod.release.create.step.recording.title'
							}
						/>
					</span>
					{!isH11Edit && (
						<div className={s.input} onClick={onClickDisabledDropZone}>
							<DropZone
								accept=".wav, .flac"
								classStyles={!serverError ? s.DropZone : s.DropZoneError}
								dropzoneId={
									!serverError
										? 'rod.field.upload_v2'
										: 'rod.field.upload_server_error'
								}
								onUpload={handleFileUpload}
								kb
								maxSize={maxFileSize}
								getDropFilesCount={getDropFilesCount}
								error={Object.keys(formatError).length ? formatError : false}
								setFormatError={setFormatError}
								disabled={isDropzoneDisabled}
								title={dropZoneTitle(lang, releaseTypeId)}
							/>
							{dropFilesAccepted.length > 1 &&
								Object.keys(errors).includes('upload') && (
									<span className={s.ErrorHelper}>
										{checkUploadCount(
											errors.upload[0].rule,
											releaseType,
											errorsValue
										)}
									</span>
								)}
						</div>
					)}

					<div className={s.input}>
						{!serverError && recordings.length !== 0 && (
							<ul className={s.table}>
								{recordings.map((item, index) => (
									<li
										onDragStart={
											isDraggable
												? (e) => dragStartHandler(e, item, index)
												: null
										}
										onDragLeave={isDraggable ? (e) => dragEndHandler(e) : null}
										onDragEnd={isDraggable ? (e) => dragEndHandler(e) : null}
										onDragOver={isDraggable ? (e) => dragOverHandler(e) : null}
										onDrop={
											isDraggable ? (e) => dropHandler(e, item, index) : null
										}
										draggable={isDraggable}
										key={item?.file?.id || item.id || item.name}
										className={s.table__item}
										style={
											isH11Edit && h11Moderation
												? { paddingRight: '48px' }
												: isH11Edit && !h11Moderation
												? { cursor: 'default' }
												: {}
										}
									>
										{audioLoading ? (
											<span className={s.spinner}>
												<LoaderWhithoutProgress />
											</span>
										) : (
											<span>{index + 1}</span>
										)}

										<span>{item?.file?.client_name || item.name}</span>
										<span>3:00</span>
										{!item.done ? (
											<div className={!item.done ? s.loader : ''}>
												<Loader
													uploadProgressPercents={uploadProgressPercents.find(
														(progressObj) => progressObj.key === item.name
													)}
												/>
											</div>
										) : (
											<div
												className={
													isH11Edit && !h11Moderation
														? s.menuWrapperEdit
														: s.menuWrapper
												}
												key={item?.file?.id || item.id || item.name}
											>
												{(audioId === item?.id && play) ||
												(audioId === item.file?.id && play) ? (
													<img
														src={stop_button}
														className={s.play}
														onClick={handlePlayStop}
														alt=""
													/>
												) : (
													<img
														src={play_button}
														className={s.play}
														onClick={() => handlePreparedAudio(index, item)}
														alt=""
													/>
												)}

												{isH11Edit && h11Moderation && (
													<div>
														<label htmlFor={`upload-button-${item.id}`}>
															<img
																src={update_file_icon}
																className={s.replace}
																alt="file"
																title={
																	lang === 'ru'
																		? 'заменить фонограмму'
																		: 'replace the phonogram file'
																}
															/>
														</label>
														<input
															type="file"
															id={`upload-button-${item.id}`}
															style={{ display: 'none' }}
															onChange={(e) =>
																handleFileUpload([e.target.files[0]], item.id)
															}
															accept=".wav, .flac"
														/>
													</div>
												)}

												{(!isH11Edit || (isH11Edit && h11Moderation)) && (
													<img
														src={remove_cart}
														className={s.play}
														onClick={() => handleDelete(index, item)}
														alt=""
													/>
												)}

												{(!isH11Edit || (isH11Edit && h11Moderation)) && (
													<img src={sort_dnd} className={s.sort} alt="" />
												)}
											</div>
										)}
									</li>
								))}
							</ul>
						)}
						{Object.keys(errors).includes('upload') && (
							<span className={s.ErrorHelper}>
								{checkUploadCount(
									errors.upload[0].rule,
									releaseType,
									errorsValue
								)}
							</span>
						)}
						{Object.keys(errors).includes('tracksCount') && (
							<span className={s.ErrorHelper}>
								{getTracksCountError(errors.tracksCount[0].max)}
							</span>
						)}
						{Object.keys(props.errors).length > 0 &&
							Object.keys(props.errors).includes('file') && (
								<span className={s.ErrorHelper}>
									{getTracksCountError(props.errors?.file[0]?.max)}
								</span>
							)}
						{isH11Edit && Object.keys(formatError).length > 0 && (
							<span className={s.ErrorHelper}>
								<FormattedMessage id={'rod.field.update.error.file'} />
							</span>
						)}
					</div>
				</div>
				<InfoHelper
					title="rod.release.create.step.recording.helper_title"
					text="rod.release.create.step.recording.helper_main"
				/>
				<UploadPhonogramModal active={false} />
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledNextBtn() || disableBtn}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
}

export default compose(withRoot, withAuth)(Recording);
