// Core
import React from 'react';

// Icons
import checkbox_bd from 'images/checkbox_bd.svg';
import checkbox from 'images/checkbox.svg';
import checkbox_bd_dgtal from 'images/checkbox_bd_dgtal.svg';
import checkbox_dgtal from 'images/checkbox_dgtal.svg';
import checkbox_disabled from 'images/checkbox_disabled.svg';

// Styles
import s from '../Distribution.module.css';

const CheckBox = ({
	item,
	allChecked,
	getCheckedOutlets,
	isLocked,
	disabled,
	selectedOutlet,
}) => {
	const handleChecked = () => {
		if (allChecked) return;
		item.checked ? (item.checked = false) : (item.checked = true);
		getCheckedOutlets(item);
	};

	if (disabled) {
		return (
			<div className={s.table__item_locked} checked={item.checked}>
				<img
					src={selectedOutlet || item.checked ? checkbox_disabled : checkbox_bd}
					alt=""
					style={selectedOutlet || item.checked ? {} : { opacity: '0.5' }}
				/>
				<span style={selectedOutlet || item.checked ? {} : { opacity: '0.5' }}>
					{item.title}
				</span>
			</div>
		);
	}

	return !isLocked ? (
		<div className={s.table__item} onClick={handleChecked} checked>
			{process.env.REACT_APP_CLIENT === 'dgtal' ? (
				<img
					src={
						!item.checked && !allChecked ? checkbox_bd_dgtal : checkbox_dgtal
					}
					alt=""
				/>
			) : (
				<img
					src={!item.checked && !allChecked ? checkbox_bd : checkbox}
					alt=""
				/>
			)}

			<span>{item.title}</span>
		</div>
	) : (
		<div className={s.table__item_locked} checked>
			<img src={checkbox_disabled} alt="" />
			<span>{item.title}</span>
		</div>
	);
};

export default CheckBox;
