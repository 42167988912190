import styles from './TrackHeadingSkeleton.module.css';

const TrackHeadingSkeleton = () => {
	return (
		<div className={styles.container}>
			<div className={styles.trackCover}></div>
			<div className={styles.trackInfo}>
				<div className={styles.trackTitle}></div>
				<div className={styles.performerName}></div>
			</div>
		</div>
	);
};

export default TrackHeadingSkeleton;
