// Core
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { PoseGroup } from 'react-pose';

// UI
import { Button, CloseButton } from 'components/Buttons';
import { Col, Container, Row } from 'components/Grid';
import { CodeInput } from 'components/Inputs/CodeInput';
import { Label } from 'components/Labels/Label';
import { withRoot, withUI } from 'hocs';
import { compose } from 'recompose';
import Contracts from 'services/api/contracts';
import BaseModal from '../Modal/BaseModal';
import { Modal, Shade } from './Posed';

// Styles
import styles from './ContractSMSSignModal.module.css';

class ContractSMSSignModal extends Component {
	state = {
		isVisible: false,
		errors: null,
		isChangeSignTypeAvailable: false,
		isLoading: false,
		signTypeError: false,
		disableSubmitBtn: false,
	};

	form = React.createRef();

	componentDidMount() {
		this.setState({
			isVisible: true,
		});

		const {
			errors: parentErrors,
			UIContext: { putResendSMSTime },
		} = this.props;
		if (parentErrors && Object.keys(parentErrors).length) {
			if (
				parentErrors?.code?.[0]?.rule === 'retry' &&
				parentErrors?.code?.[0]?.value
			) {
				putResendSMSTime(new Date(parentErrors?.code?.[0]?.value).getTime());
				return;
			}
			this.setState({ errors: parentErrors });
			if (
				parentErrors?.code?.[0]?.remaining === 0 ||
				parentErrors?.code?.[0]?.count > 0 ||
				parentErrors?.code?.[0]?.rule === 'code_exhausted'
			) {
				this.setState({ isChangeSignTypeAvailable: true });
			}
		}
	}

	componentWillUnmount() {
		this.setState({
			isVisible: false,
		});
	}

	resend = () => {
		const {
			resendSignSMS,
			id,
			type,
			UIContext: { putResendSMSTime },
		} = this.props;
		this.setState({
			isLoading: true,
		});

		resendSignSMS(id, type)
			.then()
			.catch((data) => {
				if (
					data.response?.data?.errors?.code?.[0]?.rule === 'retry' &&
					data.response?.data?.errors?.code?.[0]?.value
				) {
					putResendSMSTime(
						new Date(data.response?.data?.errors?.code?.[0]?.value).getTime()
					);
					return;
				}
				if (
					data.response?.data?.errors?.code[0]?.remaining === 0 ||
					data.response?.data?.errors?.code[0].rule === 'code_exhausted' ||
					data.response?.data?.errors?.code?.[0]?.count > 0
				) {
					this.setState({ isChangeSignTypeAvailable: true });
				}
				this.setState({
					errors: data.response.data.errors || null,
				});
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	changeSignType = () => {
		this.setState({ isLoading: true });

		const { id, anotherSignType } = this.props;
		if (anotherSignType) {
			anotherSignType();
			return;
		}
		Contracts.changeContractsSignType(id)
			.then((res) => {
				res = res.data.data;

				this.props.changeSignContractsType(res.step);
				this.props.onClose();
			})
			.catch((err) => {
				this.setState({ signTypeError: true });
			})
			.finally(() => {
				this.setState({ isLoading: false });
			});
	};

	submit = (e) => {
		e.preventDefault();

		const code = this.form.current.code.value;
		const {
			signContractFromModal,
			id,
			type,
			signContractFromApi,
			signPaymentFromApi,
			contract,
			UIContext: { putResendSMSTime },
		} = this.props;

		if (signContractFromApi) {
			this.setState({ disableSubmitBtn: true });
			signContractFromApi(id, type, { code })
				.then((res) => {
					signContractFromModal();
					putResendSMSTime();
					this.props.onClose();
				})
				.catch((err) => {
					if (
						err.response.data.errors.code[0]?.remaining === 0 ||
						err.response.data.errors.code[0].rule === 'code_exhausted'
					) {
						this.setState({ isChangeSignTypeAvailable: true });
					}
					this.setState({
						errors: err.response.data.errors || {},
					});
				})
				.finally(() => {
					this.setState({ disableSubmitBtn: false });
				});
		}

		if (signPaymentFromApi) {
			const {
				UIContext: { showToast },
			} = this.props;

			this.setState({ isLoading: true });
			this.setState({ disableSubmitBtn: true });
			signPaymentFromApi(id, contract.id, code)
				.then((res) => {
					signContractFromModal();
					this.setState({ isLoading: false });
					putResendSMSTime();
					this.props.onClose();
					setTimeout(() => {
						showToast({
							title: 'rod.reports_page.payment.toast.title',
							text: 'rod.reports_page.payment.toast.text',
							type: 'info',
						});
					}, 1200);
				})
				.catch((err) => {
					if (
						err.response.data?.errors?.code[0]?.remaining === 0 ||
						err.response.data?.errors?.code[0].rule === 'code_exhausted'
					) {
						this.setState({ isChangeSignTypeAvailable: true });
					}
					this.setState({
						errors: err.response.data.errors || {},
					});
					this.setState({ isLoading: false });
				})
				.finally(() => {
					this.setState({ disableSubmitBtn: false });
				});
		}
	};

	clearAllErrors = () => {
		this.setState({
			errors: {},
		});
		if (this.state.signTypeError) this.setState({ signTypeError: false });
	};

	render() {
		const { onClose } = this.props;
		const { errors, isChangeSignTypeAvailable, disableSubmitBtn } = this.state;

		return (
			<BaseModal className={styles.Modal} onClose={onClose}>
				<PoseGroup>
					{this.state.isVisible && [
						<Shade key="shade" className={styles.Overlay} />,

						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<Container fluid>
								<Row>
									<Col md={7} onClick={onClose} />
									<Col md={5} className={styles.LeftSide}>
										<div className={styles.Content}>
											<form
												className={styles.Form}
												onSubmit={this.submit}
												ref={this.form}
											>
												<div className={styles.Header}>
													<Label
														text={
															<FormattedMessage
																id={'rod.contracts.sms_sign.title'}
															/>
														}
														font="--gilroy-Medium-40"
														black
													/>
													<Label
														text={
															<FormattedHTMLMessage
																id={'rod.contracts.sms_sign.subtitle'}
																values={{ value: this.props.phone }}
															/>
														}
														font="--gilroy-Medium-24"
														black
													/>
												</div>
												<CodeInput
													onResend={this.resend}
													clearAllErrors={this.clearAllErrors}
													errors={errors?.code}
													isLoading={this.state.isLoading}
													isChangeSignTypeAvailable={isChangeSignTypeAvailable}
													withParentErrors={this.state.errors}
												/>
												<Label
													className={styles.Description}
													text={
														<FormattedMessage
															id={'rod.contracts.sms_sign.description'}
															values={{
																value:
																	this.props.contract.hash ??
																	this.props.hash ??
																	'',
															}}
														/>
													}
													font="--gilroy-Medium-14"
													black
												/>
												<div className={styles.Controls}>
													{!this.state.isChangeSignTypeAvailable ? (
														<Button
															text={
																<FormattedMessage
																	id={'rod.action.sign_confirm'}
																/>
															}
															variant={'primary'}
															type={'submit'}
															disabled={
																this.state.isLoading || disableSubmitBtn
															}
														/>
													) : (
														<>
															<Button
																text={
																	this.state.isLoading ? (
																		<FormattedMessage
																			id={'rod.contracts.processing'}
																		/>
																	) : (
																		<FormattedMessage
																			id={
																				'rod.admin.account.contracts.switch_to_by_hand_signing'
																			}
																		/>
																	)
																}
																onClick={this.changeSignType}
																disabled={this.state.isLoading}
																variant={'primary'}
															/>
															{this.state.signTypeError && (
																<span className={styles.errorSygnType}>
																	<FormattedMessage
																		id={'rod.error.change_sign_type_contract'}
																	/>
																</span>
															)}
														</>
													)}
												</div>
											</form>
										</div>
										<CloseButton
											className={styles.CloseButton}
											onClick={onClose}
										/>
									</Col>
								</Row>
							</Container>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		);
	}
}

ContractSMSSignModal.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default compose(withRoot, withUI)(ContractSMSSignModal);
