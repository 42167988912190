// Core
import { FormattedMessage } from 'react-intl';

// UI
import InfoTable from './InfoTable/InfoTable';
import CoverBlock from './Cover/Cover';

// Styles
import styles from './Info.module.css';

const Info = ({
	info,
	cover,
	allGenres,
	isCoverLoaded,
	handleImageLoad,
	handleImageErrored,
}) => {
	return (
		<>
			<span className={styles.title}>
				<FormattedMessage id={'rod.composition.info.title'} />
			</span>
			<div className={styles.info}>
				<InfoTable info={info} allGenres={allGenres} />
				<CoverBlock
					cover={cover}
					isCoverLoaded={isCoverLoaded}
					handleImageLoad={handleImageLoad}
					handleImageErrored={handleImageErrored}
				/>
			</div>
		</>
	);
};

export default Info;
