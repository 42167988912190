/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 04/03/2021, 12:37
 */

// Core
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

// UI
import { Loading } from 'components';
import { Wizard } from 'components/Wizard';
import accountCompletionSteps from 'constants/accountCompletionSteps';
import contractStates from 'constants/contractStates';
import { withAuth, withRoot, withUI } from 'hocs';
import { NoCode } from 'pages/contracts/AddContract/NoCode';
import { ContractTerms } from 'pages/contracts/AddContract/steps/01-Terms';
import List from 'pages/contracts/AddContract/steps/02-List/List';
import { Contract } from 'pages/contracts/AddContract/steps/03-Contract';
import { Sign } from 'pages/contracts/AddContract/steps/04-Sign';
import { Next } from 'pages/contracts/AddContract/steps/05-Next';
import { Pending } from './steps/07-Pending/Pending';
import { Complete } from 'pages/contracts/AddContract/steps/06-Complete';
import { Unavailable } from 'pages/errors/Unavailable';
import { DeclinedContracts } from 'pages/account/Create/steps/08-Complete/Complete';
import Confirm from 'pages/account/Create/steps/02-Confirm/Confirm';

// Utils
import update from 'immutability-helper';
import { compose } from 'recompose';
import http from 'services/http';
import api from 'services/interceptor';
import { partner } from 'messages/EN/pages_branding';

// Steps
import { steps } from './steps/steps';
import { contractSteps } from './data/contractSteps';

// Styles
import styles from './AddContract.module.css';

class AddContract extends Component {
	constructor(props) {
		super(props);
		this.steps = contractSteps;

		this.state = {
			loading: true,
			contracts: [],
			inProgress: [],
			toSign: [],
			signed: [],
			errors: [],
			account: {},
			completionStep: '',
			step: steps.LIST,
			resetSubmitErrors: [],
			isAdditional: false,
			contractData: {},
			isAdditionalContractEnable: true,
			isAdditionalSignBySms: false,
			roles_updated: false,
			isCompleteSigningInProcess: false,
			disableSignBtn: false,
		};
	}

	update(callback) {
		const {
			authContext: { setIsAccountLoading },
			rootContext: {
				getAccountPersonalData,
				getContracts,
				getAdditionalContracts,
			},
		} = this.props;

		if (!this.state.isAdditional) {
			this.setState({ disableSignBtn: true });
			getAccountPersonalData(this.props.match.params.id)
				.then((account) => {
					account = account.data.data;
					getContracts(this.props.match.params.id)
						.then((contracts) => {
							contracts = contracts.data.data;

							const toSign = contracts.filter(
								(contract) => contract.status === contractStates.NEW
							);
							const inProgress = contracts.filter(
								(contract) => contract.status === contractStates.SIGNING
							);
							const signed = contracts.filter(
								(contract) => contract.status === contractStates.SIGNED
							);
							const contractToSign = this.state.contracts.find(
								(contract) =>
									contract.status === contractStates.SIGNING ||
									contract.status === contractStates.NEW
							);

							let step = steps.LIST;

							if (toSign.length === contracts.length) {
								step = steps.LIST;
							} else if (signed.length === contracts.length) {
								step = steps.COMPLETE;
							} else if (inProgress.length === 0) {
								step = steps.NEXT;
							} else if (
								inProgress.length > 0 &&
								account.completion_step === accountCompletionSteps.SIGN_PHYSICAL
							) {
								step = steps.SIGN;
							} else if (
								inProgress.length > 0 &&
								account.completion_step === accountCompletionSteps.SIGN_SMS
							) {
								step = steps.LIST;
							} else if (
								account.completion_step ===
								accountCompletionSteps.PENDING_CONTRACT
							) {
								step = steps.PENDING;
							} else if (account.completion_step === 'declined_contracts') {
								this.props.history.push('/');
							}

							this.setState(
								update(this.state, {
									step: { $set: step },
									toSign: { $set: toSign },
									inProgress: { $set: inProgress },
									signed: { $set: signed },
									contracts: { $set: contracts },
									account: { $set: account },
									completionStep: { $set: account.completion_step },
									loading: { $set: false },
									contractToSign: { $set: contractToSign },
								}),
								callback
							);
							setIsAccountLoading(false);
						})
						.catch((err) => {
							console.error(
								'GetContract Error - api - err.response',
								err.response
							);
							setIsAccountLoading(false);
						});
				})
				.catch((err) => {
					console.error(
						'AddContract Error.response, error - api - getAccountPersonalData',
						err.response,
						err
					);
					setIsAccountLoading(false);
				})
				.finally(() => this.setState({ disableSignBtn: false }));
		} else {
			this.setState({ disableSignBtn: true });
			getAccountPersonalData(this.props.match.params.id)
				.then((account) => {
					account = account.data.data;
					getAdditionalContracts(this.props.match.params.id)
						.then((contracts) => {
							const additionalBySms = contracts.data.additionalBySms;
							const { isConditionsChecked } = contracts.data;
							contracts = contracts.data.data;

							const noContract = contracts.length ? false : true;
							const contractsApproved = contracts.filter(
								(contract) =>
									contract.moderation_status === 'approved_contracts'
							);
							const contractsDeclined = contracts.filter(
								(contract) =>
									contract.moderation_status === 'declined_contracts'
							);
							const toSign = contracts.filter(
								(contract) => contract.status === contractStates.NEW
							);
							const inProgress = contracts.filter(
								(contract) => contract.status === contractStates.SIGNING
							);
							const signed = contracts.filter(
								(contract) => contract.status === contractStates.SIGNED
							);
							const contractToSign = this.state.contracts.find(
								(contract) =>
									contract.status === contractStates.SIGNING ||
									contract.status === contractStates.NEW
							);

							let step = steps.LIST;

							if (
								!account.contracts_signed &&
								this.getCountAdditionalOptionsSigned() > 0 &&
								!isConditionsChecked
							) {
								step = steps.NO_CONTRACT_YET;
							} else if (noContract) {
								step = steps.NO_CONTRACT_YET;
							} else if (contractsApproved.length === contracts.length) {
								step = steps.ADDITIONAL_CONTRACTS_APPROVED;
							} else if (contractsDeclined.length === contracts.length) {
								step = steps.ADDITIONAL_CONTRACTS_DECLINED;
							} else if (
								toSign.length === contracts.length &&
								!isConditionsChecked
							) {
								step = steps.NO_CONTRACT_YET;
							} else if (
								toSign.length === contracts.length &&
								isConditionsChecked
							) {
								step = steps.LIST;
							} else if (signed.length === contracts.length) {
								step = steps.COMPLETE;
							} else if (inProgress.length === 0) {
								step = steps.NEXT;
							} else if (
								inProgress.length > 0 &&
								account.completion_step === accountCompletionSteps.SIGN_PHYSICAL
							) {
								step = steps.SIGN;
							} else if (
								inProgress.length > 0 &&
								this.state.isAdditional &&
								!additionalBySms
							) {
								step = steps.SIGN;
							} else if (
								inProgress.length > 0 &&
								this.state.isAdditional &&
								additionalBySms
							) {
								step = steps.LIST;
							} else if (
								account.completion_step ===
								accountCompletionSteps.PENDING_CONTRACT
							) {
								step = steps.PENDING;
							} else if (account.completion_step === 'declined_contracts') {
								this.props.history.push('/');
							}

							this.setState(
								update(this.state, {
									step: { $set: step },
									toSign: { $set: toSign },
									inProgress: { $set: inProgress },
									signed: { $set: signed },
									contracts: { $set: contracts },
									account: { $set: account },
									completionStep: { $set: account.completion_step },
									loading: { $set: false },
									contractToSign: { $set: contractToSign },
									isAdditionalContractEnable: {
										$set: contracts.length ? true : false,
									},
									isAdditionalSignBySms: {
										$set: additionalBySms ? true : false,
									},
								}),
								callback
							);
							setIsAccountLoading(false);
						})
						.catch((err) => {
							console.error(
								'GetContract Error - api - err.response',
								err.response
							);
							setIsAccountLoading(false);
						});
				})
				.catch((err) => {
					console.error(
						'AddContract Error.response, error - api - getAccountPersonalData',
						err.response,
						err
					);
					setIsAccountLoading(false);
				})
				.finally(() => this.setState({ disableSignBtn: false }));
		}
	}

	componentDidMount() {
		const {
			authContext: { setIsAccountLoading },
			rootContext: { getContractData, getAccountPersonalData },
		} = this.props;

		setIsAccountLoading(true);
		getAccountPersonalData(this.props.match.params.id).then((account) => {
			account = account.data.data;
			this.setState({ account: account }, () => {
				if (
					(account.completion_step === accountCompletionSteps.PERSONAL_AREA ||
						account.completion_step ===
							accountCompletionSteps.REPERTOIRE_APPLY) &&
					!account.contracts_signed
				) {
					getContractData(this.props.match.params.id).then((res) => {
						res = res.data.data;
						this.setState({ contractData: res });
						this.setState({ isAdditional: res.signed_properties }, () => {
							this.update();
						});
					});
				} else if (account.contracts_signed) {
					this.setState({ isAdditional: true }, () => {
						this.update();
					});
				} else {
					this.update();
				}
			});
		});
	}

	getCountAdditionalOptionsSigned = () => {
		const contractTypes = this.state.contractData.contract_types;
		let count = 0;

		if (contractTypes.lyrics && !contractTypes.lyrics.enabled) count++;

		if (
			contractTypes.digital.resources.composition &&
			!contractTypes.digital.resources.composition.enabled
		)
			count++;
		if (
			contractTypes.digital.resources.recording &&
			!contractTypes.digital.resources.recording.enabled
		)
			count++;
		if (
			contractTypes.public_performance.resources.composition &&
			!contractTypes.public_performance.resources.composition.enabled
		)
			count++;

		if (
			contractTypes.public_performance.resources.recording &&
			!contractTypes.public_performance.resources.recording.enabled
		)
			count++;
		if (
			contractTypes.synchronization.resources.composition &&
			!contractTypes.synchronization.resources.composition.enabled
		)
			count++;

		if (
			contractTypes.synchronization.resources.recording &&
			!contractTypes.synchronization.resources.recording.enabled
		)
			count++;
		return count;
	};

	changeTerms = ({ contract_types, period, sign, currency }) => {
		const {
			rootContext: { updateContractData },
		} = this.props;
		updateContractData(this.props.match.params.id, {
			contract_types,
			currency,
			sign,
			period,
		});
	};

	submitTerms = ({ contract_types, period, sign, currency }) => {
		const {
			rootContext: { submitContractData },
		} = this.props;
		submitContractData(this.props.match.params.id, {
			contract_types,
			currency,
			sign,
			period,
		}).then((res) => {
			if (this.state.isAdditional) {
				localStorage.setItem('isAdditionalContractInProccess', true);
			}
			this.update();
		});
	};

	showContract = () => {
		this.update(() => {
			this.setState({
				step: steps.CONTRACT,
			});
		});
	};

	openUploadContracts = (contract) => {
		this.update(() => {
			if (contract.completionStep === accountCompletionSteps.SIGN_PHYSICAL) {
				this.setState({
					step: steps.SIGN,
				});
			}
		});
	};

	noCode = () => {
		this.setState({
			step: steps.NO_CODE,
		});
	};

	changeSignContractsType = (step) => {
		this.update();
	};

	resetSubmitErrorsHandle = (isEmptyBackErrors) => {
		if (isEmptyBackErrors) {
			this.setState({ resetSubmitErrors: [] });
		}
	};

	submitContract = (data) => {
		if (this.state.resetSubmitErrors.length > 0) {
			this.setState({ resetSubmitErrors: [] });
		}

		const {
			rootContext: { signContract },
		} = this.props;

		this.setState({ disableSignBtn: true });
		signContract(
			this.props.match.params.id,
			this.state.inProgress[0].contract_type
		)
			.then((res) => {
				res = res.data.data;
				this.update();
			})
			.catch((err) => {
				if (err.response.data.errors.files) {
					this.setState({ resetSubmitErrors: [{ file: 'required' }] });
				}
			})
			.finally(() => this.setState({ disableSignBtn: false }));
	};

	resetContracts = (contracts) => {
		if (this.state.isAdditional) {
			this.submitInPending(contracts);
		} else {
			api
				.post(`/qa/account/${this.props.match.params.id}/step`, {
					completion_step: accountCompletionSteps.PENDING_CONTRACT,
				})
				.then((res) => {
					this.setState({
						step: steps.PENDING,
					});
				})
				.catch((err) => {
					console.error(
						'Create - resetContracts error.response: ',
						err.response
					);
				});
		}
	};

	submitInPending = (contracts) => {
		if (this.state.resetSubmitErrors.length > 0) {
			this.setState({ resetSubmitErrors: [] });
		}

		const {
			rootContext: { signContract },
		} = this.props;

		if (contracts.length > 1) {
			contracts.map((item) => {
				signContract(this.props.match.params.id, item.type)
					.then((res) => {
						res = res.data.data;
						this.update();
					})
					.catch((err) => {
						if (err.response.data.errors.files) {
							this.setState({ resetSubmitErrors: [{ file: 'required' }] });
						}
					});
			});
		} else {
			signContract(this.props.match.params.id, contracts[0].type)
				.then((res) => {
					res = res.data.data;
					this.update();
				})
				.catch((err) => {
					if (err.response.data.errors.files) {
						this.setState({ resetSubmitErrors: [{ file: 'required' }] });
					}
				});
		}
	};

	completeSigning = () => {
		const {
			rootContext: { completeSigning },
		} = this.props;
		this.setState({ isCompleteSigningInProcess: true });
		completeSigning(this.props.match.params.id)
			.then((res) => {
				if (
					this.state.completionStep ===
						accountCompletionSteps.APPROVED_CONTRACTS ||
					this.state.completionStep === accountCompletionSteps.SIGN_SMS ||
					this.state.isAdditional
				) {
					this.props.history.push(
						`/accounts/${this.props.match.params.id}/dashboard`
					);
				}
			})
			.catch((err) => console.info('Error:', err))
			.finally(() => {
				this.setState({ isCompleteSigningInProcess: false });
			});
	};

	completeSMS = () => {
		http
			.post(`/qa/account/${this.props.match.params.id}/step`, {
				completion_step: accountCompletionSteps.PERSONAL_AREA,
			})
			.then((res) => {
				this.props.history.push(
					`/accounts/${this.props.match.params.id}/dashboard`
				);
			})
			.catch((err) => {
				console.error('AddContract Error - http - post(`/qa/account/...', err);
				this.props.history.push(
					`/accounts/${this.props.match.params.id}/dashboard`
				);
			});
	};

	submitRoles = (scopeIds) => {
		const {
			rootContext: { addRoles },
		} = this.props;
		addRoles(this.props.match.params.id, { scopeIds })
			.then((res) => {
				res = res.data.data;
				this.update(() => {
					this.setState({
						step: steps.NO_CONTRACT_YET,
						roles_updated: true,
					});
				});
			})
			.catch((data) => {
				console.error('Create - addRoles error: ', data);
				console.error('Create - addRoles error.response: ', data.response);
			});
	};

	submitAdditionalRoles = (scopeIds) => {
		const {
			rootContext: { addAdditionalRoles },
		} = this.props;
		addAdditionalRoles(this.props.match.params.id, { scopeIds })
			.then((res) => {
				res = res.data.data;
				this.update(() => {
					this.setState({
						step: steps.NO_CONTRACT_YET,
						roles_updated: true,
					});
				});
			})
			.catch((data) => {
				console.error('Create - addRoles error: ', data);
				console.error('Create - addRoles error.response: ', data.response);
			});
	};

	renderStep = () => {
		const { completionStep, step } = this.state;
		const sms = completionStep === accountCompletionSteps.SIGN_SMS;

		switch (completionStep) {
			case accountCompletionSteps.APPROVED:
				return (
					<ContractTerms
						{...this.state}
						onChange={this.changeTerms}
						onSubmit={this.submitTerms}
						contractData={this.state.contractData}
						completionStep={completionStep}
					/>
				);
			case accountCompletionSteps.REPERTOIRE_APPLY:
				if (
					this.props.location.pathname.includes('addrole') &&
					!this.state.roles_updated
				) {
					return (
						<Confirm
							onChange={this.submitAdditionalRoles}
							UIContext={this.props.UIContext}
							id={this.props.match.params.id}
							isAddRole={true}
							completionStep={completionStep}
						/>
					);
				} else if (this.state.isAdditional) {
					switch (step) {
						case steps.NO_CONTRACT_YET:
							return (
								<ContractTerms
									{...this.state}
									onChange={this.changeTerms}
									onSubmit={this.submitTerms}
									isAdditional={this.state.isAdditional}
									contractData={this.state.contractData}
									contracts={this.state.contracts}
									completionStep={completionStep}
									isAdditionalContractEnable={
										this.state.isAdditionalContractEnable
									}
									isAddRole={this.props.location.pathname.includes('addrole')}
									isAddService={this.props.location.pathname.includes(
										'addservices'
									)}
								/>
							);
						case steps.LIST:
							return (
								<List
									{...this.state}
									onSubmit={this.showContract}
									isAdditional={this.state.isAdditional}
									sms={this.state.isAdditionalSignBySms}
								/>
							);
						case steps.CONTRACT:
							return (
								<Contract
									{...this.state}
									onSubmit={this.openUploadContracts}
									onNoCode={this.noCode}
									sms={this.state.isAdditionalSignBySms}
									changeSignContractsType={this.changeSignContractsType}
								/>
							);
						case steps.NO_CODE:
							return <NoCode {...this.state} onSubmit={this.showContract} />;
						case steps.SIGN:
							return (
								<Sign
									{...this.state}
									onSubmit={this.submitContract}
									resetSubmitErrors={this.state.resetSubmitErrors}
									resetSubmitErrorsHandle={this.resetSubmitErrorsHandle}
									isAdditional={this.state.isAdditional}
								/>
							);
						case steps.NEXT:
							return (
								<Next
									{...this.state}
									sms={this.state.isAdditionalSignBySms}
									onSubmit={this.showContract}
									isAdditional={this.state.isAdditional}
								/>
							);
						case steps.COMPLETE:
							return (
								<Complete
									{...this.state}
									sms={this.state.isAdditionalSignBySms}
									onSubmit={this.completeSigning}
									isAdditional={this.state.isAdditional}
									isCompleteSigningInProcess={
										this.state.isCompleteSigningInProcess
									}
								/>
							);
						case steps.PENDING:
							return (
								<Pending
									{...this.state}
									isAdditional={this.state.isAdditional}
								/>
							);
						case steps.ADDITIONAL_CONTRACTS_APPROVED:
							return (
								<Complete
									{...this.state}
									showSigned
									sms={this.state.isAdditionalSignBySms}
									onSubmit={this.completeSigning}
									isAdditional={this.state.isAdditional}
								/>
							);
						case steps.ADDITIONAL_CONTRACTS_DECLINED:
							return (
								<DeclinedContracts
									{...this.state}
									sms={this.state.isAdditionalSignBySms}
									onSubmit={this.completeSigning}
									isAdditional={this.state.isAdditional}
									toContracts={this.resetContracts}
								/>
							);

						default:
							return <Unavailable />;
					}
				}
				break;
			case accountCompletionSteps.PERSONAL_AREA:
				if (
					this.props.location.pathname.includes('addrole') &&
					!this.state.roles_updated
				) {
					return (
						<Confirm
							onChange={this.submitAdditionalRoles}
							UIContext={this.props.UIContext}
							id={this.props.match.params.id}
							isAddRole={true}
							completionStep={completionStep}
						/>
					);
				} else if (this.state.isAdditional) {
					switch (step) {
						case steps.NO_CONTRACT_YET:
							return (
								<ContractTerms
									{...this.state}
									onChange={this.changeTerms}
									onSubmit={this.submitTerms}
									isAdditional={this.state.isAdditional}
									contractData={this.state.contractData}
									contracts={this.state.contracts}
									completionStep={completionStep}
									isAdditionalContractEnable={
										this.state.isAdditionalContractEnable
									}
									isAddService={this.props.location.pathname.includes(
										'addservices'
									)}
								/>
							);
						case steps.LIST:
							return (
								<List
									{...this.state}
									onSubmit={this.showContract}
									isAdditional={this.state.isAdditional}
									sms={this.state.isAdditionalSignBySms}
								/>
							);
						case steps.CONTRACT:
							return (
								<Contract
									{...this.state}
									onSubmit={this.openUploadContracts}
									onNoCode={this.noCode}
									sms={this.state.isAdditionalSignBySms}
									changeSignContractsType={this.changeSignContractsType}
								/>
							);
						case steps.NO_CODE:
							return <NoCode {...this.state} onSubmit={this.showContract} />;
						case steps.SIGN:
							return (
								<Sign
									{...this.state}
									onSubmit={this.submitContract}
									resetSubmitErrors={this.state.resetSubmitErrors}
									resetSubmitErrorsHandle={this.resetSubmitErrorsHandle}
									isAdditional={this.state.isAdditional}
								/>
							);
						case steps.NEXT:
							return (
								<Next
									{...this.state}
									sms={this.state.isAdditionalSignBySms}
									onSubmit={this.showContract}
									isAdditional={this.state.isAdditional}
								/>
							);
						case steps.COMPLETE:
							return (
								<Complete
									{...this.state}
									sms={this.state.isAdditionalSignBySms}
									onSubmit={this.completeSigning}
									isAdditional={this.state.isAdditional}
								/>
							);

						case steps.PENDING:
							return (
								<Pending
									{...this.state}
									isAdditional={this.state.isAdditional}
								/>
							);
						case steps.ADDITIONAL_CONTRACTS_APPROVED:
							return (
								<Complete
									{...this.state}
									sms={this.state.isAdditionalSignBySms}
									showSigned
									onSubmit={this.completeSigning}
									isAdditional={this.state.isAdditional}
								/>
							);
						case steps.ADDITIONAL_CONTRACTS_DECLINED:
							return (
								<DeclinedContracts
									{...this.state}
									sms={this.state.isAdditionalSignBySms}
									onSubmit={this.completeSigning}
									isAdditional={this.state.isAdditional}
									toContracts={this.resetContracts}
								/>
							);

						default:
							return <Unavailable />;
					}
				}
				break;
			case accountCompletionSteps.SIGN_PHYSICAL:
			case accountCompletionSteps.SIGN_SMS:
			case accountCompletionSteps.PENDING_CONTRACT:
				switch (step) {
					case steps.LIST:
						return (
							<List
								{...this.state}
								onSubmit={this.showContract}
								isAdditional={this.state.isAdditional}
								sms={completionStep === accountCompletionSteps.SIGN_SMS}
							/>
						);
					case steps.CONTRACT:
						return (
							<Contract
								{...this.state}
								onSubmit={this.openUploadContracts}
								onNoCode={this.noCode}
								sms={completionStep === accountCompletionSteps.SIGN_SMS}
								changeSignContractsType={this.changeSignContractsType}
							/>
						);
					case steps.NO_CODE:
						return <NoCode {...this.state} onSubmit={this.showContract} />;
					case steps.SIGN:
						return (
							<Sign
								{...this.state}
								onSubmit={this.submitContract}
								resetSubmitErrors={this.state.resetSubmitErrors}
								resetSubmitErrorsHandle={this.resetSubmitErrorsHandle}
								isAdditional={this.state.isAdditional}
							/>
						);
					case steps.NEXT:
						return (
							<Next
								{...this.state}
								sms={sms}
								onSubmit={this.showContract}
								isAdditional={this.state.isAdditional}
							/>
						);
					case steps.COMPLETE:
						return (
							<Complete
								{...this.state}
								sms={sms}
								onSubmit={this.completeSigning}
								isAdditional={this.state.isAdditional}
							/>
						);
					case steps.PENDING:
						return (
							<Pending {...this.state} isAdditional={this.state.isAdditional} />
						);

					default:
						return <Unavailable />;
				}
			case accountCompletionSteps.APPROVED_CONTRACTS:
				return (
					<Complete
						{...this.state}
						showSigned
						onSubmit={this.completeSMS}
						isAdditional={this.state.isAdditional}
					/>
				);

			default:
				return <Unavailable />;
		}
	};

	render() {
		const { loading, step } = this.state;

		if (loading) {
			return <Loading non_margin={true} />;
		}

		return (
			<React.Fragment>
				<Helmet>
					<title>
						{this.state.account.title} | {partner.title_rod_company}
					</title>
				</Helmet>
				<div
					className={cn(styles.Page, {
						'no-wiz': step === steps.CONTRACT || this.state.isAdditional,
					})}
				>
					{!this.state.isAdditional && (
						<Wizard steps={this.steps} className={styles.Wizard} />
					)}
					<div className={styles.Step}>{this.renderStep()}</div>
				</div>
			</React.Fragment>
		);
	}
}

export default compose(withUI, withRoot, withRouter, withAuth)(AddContract);
