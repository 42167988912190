// Core
import React, { useEffect, useState } from 'react';

// Icons
import linkIcon from 'images/playlist/link-to-platform.svg';
import emptyCoverAlbum from 'images/playlist/emptyCoverAlbum.png';

// Styles
import styles from './PlaylistItemInTrack.module.css';

function PlaylistItem({ item, idx, currentPage, outlet }) {
	const [isImageBroken, setIsImageBroken] = useState(false);

	const checkImage = async () => {
		if (item.image) {
			try {
				const response = await fetch(item.image, { method: 'HEAD' });
				if (!response.ok) {
					setIsImageBroken(true);
				}
			} catch {
				setIsImageBroken(true);
			}
		} else {
			setIsImageBroken(true);
		}
	};

	useEffect(() => {
		checkImage();
	}, [item.image]);

	const renderItem = (outlet) => {
		switch (outlet) {
			case 'apple':
				return (
					<li
						className={`${styles.playlistListItem} ${
							outlet === 'spotify' ? styles.spotify : {}
						} ${outlet === 'apple' ? styles.apple : {}}`}
					>
						<div className={styles.playlistInfoWrapper}>
							<div className={styles.playlistNumber}>
								{idx + 1 + (currentPage - 1) * 10}
							</div>
							<img
								className={styles.playlistImg}
								src={emptyCoverAlbum}
								alt={item.title}
							/>
							<div className={styles.playlistName}>{item.title}</div>
						</div>
						<div className={styles.subscribersQuantity}>{item.track_pos}</div>
						<a
							href={item.uri}
							target="_blank"
							className={styles.linkToPlatform}
						>
							<img src={linkIcon} />
						</a>
					</li>
				);
			default:
				return (
					<li className={styles.playlistListItem}>
						<div className={styles.playlistInfoWrapper}>
							<div className={styles.playlistNumber}>
								{idx + 1 + (currentPage - 1) * 10}
							</div>
							<img
								className={styles.playlistImg}
								src={
									!item.image?.length || isImageBroken
										? emptyCoverAlbum
										: item.image
								}
								alt={item.title}
							/>
							<div className={styles.playlistName}>{item.title}</div>
						</div>
						<div className={styles.auditionsQuantity}>{item.streams}</div>
						<div className={styles.subscribersQuantity}>{item.followers}</div>

						<a
							href={item.uri}
							target="_blank"
							className={styles.linkToPlatform}
						>
							<img src={linkIcon} />
						</a>
					</li>
				);
		}
	};

	return <>{renderItem(outlet)}</>;
}

export default PlaylistItem;
