// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import emptyIcon from 'images/playlist/emptyPlaylistIcon.png';

// Styles
import styles from './PlaylistEmpty.module.css';

function PlaylistEmpty({ outlet }) {
	const renderEmptyMessage = (outlet) => {
		switch (outlet) {
			case 'apple':
				return (
					<FormattedMessage
						id={'rod.statistic.playlists.no-playlists.description.apple'}
					/>
				);
			default:
				return (
					<FormattedMessage
						id={'rod.statistic.playlists.no-playlists.description'}
					/>
				);
		}
	};

	return (
		<div className={styles.emptyPlaylistWrapper}>
			<img
				className={styles.emptyPlaylistIcon}
				src={emptyIcon}
				alt="empty playlist image"
			/>
			<p className={styles.emptyPlaylistTitle}>
				<FormattedMessage id={'rod.statistic.playlists.no-playlists.title'} />
			</p>
			<p className={styles.emptyPlaylistDescription}>
				{renderEmptyMessage(outlet)}
			</p>
		</div>
	);
}

export default PlaylistEmpty;
