// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { addDays } from 'date-fns';

// Utils
import { showError } from 'validators/showError';

// UI
import { FormInput } from 'components/Form';

// Styles
import styles from '../RadioGroup.module.css';

const H11EditRadioGroup = ({
	h11Moderation,
	personal,
	changeField,
	errors,
}) => {
	return (
		<div className={styles.input}>
			{h11Moderation ? (
				<FormattedMessage
					id={'rod.release.create.step.distribution.input_date'}
				>
					{(placeholder) => (
						<FormInput
							className={`${styles.floatingInput} ${styles.widthCorrect}`}
							type={'datePickerForAssets'}
							placeholder={placeholder}
							name={'sale_start_date'}
							errors={errors}
							onChange={changeField}
							data={personal}
							minDate={addDays(new Date(), 3)}
							floatingLabel
						/>
					)}
				</FormattedMessage>
			) : (
				<FormattedMessage
					id={'rod.release.create.step.distribution.input_date'}
				>
					{(placeholder) => (
						<FormInput
							className={`${styles.floatingInput} ${styles.widthCorrect}`}
							type={'datePickerForAssets'}
							placeholder={placeholder}
							name={'sale_start_date'}
							errors={errors}
							onChange={changeField}
							data={personal}
							minDate={addDays(new Date(), 2)}
							floatingLabel
						/>
					)}
				</FormattedMessage>
			)}
			{Object.keys(errors).includes('sale_start_date') && (
				<span className={styles.Helper}>
					{showError('sale_start_date', errors.sale_start_date[0])}
				</span>
			)}
		</div>
	);
};

export default H11EditRadioGroup;
