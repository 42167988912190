import React, { useEffect, useState } from 'react';
import {
	TextFieldInput,
	AutocompleteInputGenres,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';
import { Popper } from '@mui/material';
import { accounts } from 'services';
import styles from './MuiAutocompleteMultipleCheckboxOutlets.module.css';
import lscache from 'lscache';
import { setCache } from 'services/cacheHelper/setCache';
import isItemSelected from 'logic/multiselectHighlight.js';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				margin: 0,
				padding: 0,
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px 8px',
					'&:hover': {
						backgroundColor: 'var(--color-light-grey) !important',
					},
				},
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom" />;
};

const MuiAutocompleteMultipleCheckboxOutlets = ({
	onChange,
	label,
	value,
	required,
	errors,
}) => {
	const [genresArr, setGenresArr] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [isReqLive, setIsReqLive] = useState(false);
	const lang = localStorage.getItem('lang');

	useEffect(() => {
		let genresCache = lscache.get('genresCache');

		if (genresCache && !isReqLive) {
			if (lang === 'ru') {
				genresCache = genresCache.sort((a, b) =>
					a.title_ru > b.title_ru ? 1 : -1
				);
			} else {
				genresCache = genresCache.sort((a, b) =>
					a.title_en > b.title_en ? 1 : -1
				);
			}
			setGenresArr(genresCache);
		} else {
			accounts
				.getGenres()
				.then((res) => {
					res = res.data.data;
					setCache('genres', 'genresCache', res);
					if (lang === 'ru') {
						res = res.sort((a, b) => (a.title_ru > b.title_ru ? 1 : -1));
					} else {
						res = res.sort((a, b) => (a.title_en > b.title_en ? 1 : -1));
					}
					setGenresArr(res);
				})
				.catch((error) => {
					console.info('Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (genresArr.length) {
		for (let i = 0; i < value.length; i++) {
			for (let j = 0; j < genresArr.length; j++) {
				if (value[i] === genresArr[j].code) {
					value[i] = {
						id: genresArr[j].id,
						code: genresArr[j].code,
						title_ru: genresArr[j].title_ru,
						title_en: genresArr[j].title_en,
					};
				}
			}
		}
	}

	const filterOptions = (_, state) => {
		const newOptions = [];
		if (state.inputValue) {
			genresArr.forEach((item) => {
				const word = lang === 'ru' ? item.title_ru : item.title_en;
				if (
					word.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					word.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				) {
					newOptions.push(item);
				}
			});
			return newOptions;
		} else return genresArr;
	};

	return (
		<div className={styles.autoGenres_wrapper}>
			<AutocompleteInputGenres
				multiple
				id="size-small-standard-multi"
				size="small"
				limitTags={2}
				onChange={(_, obj) => onChange(obj)}
				options={[
					{
						id: 127,
						code: 'Acapella',
						title_ru: 'Acapella',
						title_en: 'Acapella',
					},
					{
						id: 41,
						code: 'Acid',
						title_ru: 'Acid',
						title_en: 'Acid',
					},
				]}
				value={value}
				renderInput={(params) => (
					<TextFieldInput
						{...params}
						label={label}
						variant="standard"
						required={required}
						error={errors}
					/>
				)}
				getOptionLabel={(option) => {
					return lang === 'ru' ? option.title_ru : option.title_en;
				}}
				renderOption={(option, item) => {
					return (
						<li
							key={item}
							{...option}
							style={{
								backgroundColor: isItemSelected(item, value)
									? 'var(--color-green)'
									: 'var(--color-white)',
								color: isItemSelected(item, value)
									? 'var(--color-white)'
									: 'unset',
							}}
						>
							{`${lang === 'ru' ? item.title_ru : item.title_en}`}
						</li>
					);
				}}
				isOptionEqualToValue={(option, value) => option.code === value.code}
				PopperComponent={CustomPopper}
				filterOptions={filterOptions}
			/>
			{value.length > 2 && (
				<span className={styles.autoTag}>+{value.length - 2}</span>
			)}
		</div>
	);
};

export default MuiAutocompleteMultipleCheckboxOutlets;
