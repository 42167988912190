export const getDateData = (dateString) => {
	// Check if the input includes a GMT offset at the end
	const gmtMatch = dateString.match(/[+-]\d{2}:\d{2}$/);
	const gmt = gmtMatch ? gmtMatch[0] : '+00:00';

	const time = dateString.substring(11, 16); // Extract time (HH:mm)
	const day = dateString.substring(8, 10); // Extract day (DD)
	const month = dateString.substring(5, 7); // Extract month (MM)
	const year = dateString.substring(0, 4); // Extract year (YYYY)
	const date = `${year}-${month}-${day}`;
	const dateRow = `${day}.${month}.${year} ${time} GMT${gmt}`;

	return {
		gmt: gmt,
		time: time,
		date,
		dateRow,
	};
};
