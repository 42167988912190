// Core
import React, { useContext, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Services
import { accounts } from 'services';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// UI
import Explicit from 'components/Explicit/Explicit';
import { FullScreenModalConfirm } from 'containers/Modal';
import LoaderWhithoutProgress from 'components/LoaderWhithoutProgress';

// Icons
import chevronDownImg from 'images/chevron_down_black.svg';
import chevronUpImg from 'images/chevron_up_white.svg';
import icon_cd from 'images/icon_cd.svg';

// Styles
import styles from '../RepertoireRelease.module.css';

const Header = ({
	allOutlets,
	infoFromRelease,
	tracks,
	handleActionBtn,
	handleBlur,
	isActionMenuVisible,
	isShipmentsBtnEnable,
	releaseTypeId,
	shipmentsArr,
	isTakenDownsBtnEnable,
	takenDownsArr,
	hasPriveleges,
	isEnableToEdit,
	isDraftStatus,
	isShipmentsInProcess,
	isTakenDownsInProcess,
}) => {
	const { id: releaseId } = useParams();
	const history = useHistory();
	const {
		isEditReleaseAfterShipmentForAccount,
		isShipmentsTakedownsEnableFeatureForAccount,
	} = useContext(AuthContext);
	const {
		isEditingReleasesAfterShipment,
		isShipmentsTakedownsEnableFeature,
	} = useContext(UIContext);

	const [loading, setLoading] = useState(false);
	const [editBtnDisabled, setEditBtnDisabled] = useState(false);
	const [isInfoModalActive, setIsInfoModalActive] = useState(false);

	const onEditReleaseAfterShipment = async () => {
		setLoading(true);
		setEditBtnDisabled(true);
		try {
			const res = await accounts.createDraftForReleaseEdit(releaseId);
			if (res?.data?.data) {
				const releaseEdit = { id: res?.data?.data, step: 'release' };
				history.push(`/release/${res.data.data}/edit/release`);
				localStorage.setItem('releaseEdit', JSON.stringify(releaseEdit));
				localStorage.setItem('releaseInfoId', releaseId);
			}
		} catch (error) {
			console.error('Error of post create draft release for edit', error);
			setIsInfoModalActive(true);
		} finally {
			setEditBtnDisabled(false);
			setLoading(false);
		}
	};

	return (
		<>
			<div className={styles.header}>
				<div>
					<img
						className={styles.repertoireSong__logo}
						src={icon_cd}
						width="32px"
						height="32px"
						alt=""
					/>
					<span className={styles.title}>{infoFromRelease.title}</span>
					{tracks.length > 0 &&
						tracks.some(
							(track) =>
								track.parental_warning_type &&
								track.parental_warning_type === 'explicit'
						) && <Explicit />}
				</div>
				<div className={styles.rightSideBtnsWrapper}>
					<span className={styles.ean}>
						<FormattedMessage id={'EAN/UPC'} />:
					</span>

					<span className={styles.ean_number}>
						{infoFromRelease.ean ? infoFromRelease.ean : '-'}
					</span>

					{hasPriveleges &&
						(isEditingReleasesAfterShipment ||
							isEditReleaseAfterShipmentForAccount ||
							isShipmentsTakedownsEnableFeature ||
							isShipmentsTakedownsEnableFeatureForAccount) && (
							<>
								{(isShipmentsTakedownsEnableFeatureForAccount ||
									isEditReleaseAfterShipmentForAccount) && (
									<div className={styles.shipmentActionsWrapper}>
										<div
											className={styles.actionBtn}
											onClick={handleActionBtn}
											tabindex="0"
											onBlur={handleBlur}
										>
											<span>
												<FormattedMessage id={'rod.field.admin.actions'} />
											</span>
											<div className={styles.Chevron}>
												<img src={chevronDownImg} alt="" />
											</div>
										</div>

										{isActionMenuVisible && (
											<div
												className={styles.shipmentBtnsWrapper}
												tabindex="0"
												onBlur={() => {
													handleActionBtn();
												}}
												value={'actionChild'}
											>
												<div className={styles.shipmentBtnsHeader}>
													<span>
														<FormattedMessage id={'rod.field.admin.actions'} />
													</span>
													<div className={styles.Chevron} onClick={handleBlur}>
														<img src={chevronUpImg} alt="" />
													</div>
												</div>

												{(isEditingReleasesAfterShipment ||
													isEditReleaseAfterShipmentForAccount) && (
													<>
														{isEnableToEdit &&
														!editBtnDisabled &&
														!isDraftStatus &&
														!isShipmentsInProcess ? (
															<button
																className={styles.editAfterShipmentBtn}
																onClick={onEditReleaseAfterShipment}
																value={'actionChild'}
															>
																<FormattedMessage
																	id={'rod.release.edit_release'}
																/>
																{loading && (
																	<div className={styles.loader}>
																		<LoaderWhithoutProgress />
																	</div>
																)}
															</button>
														) : (
															<button
																className={`${styles.editAfterShipmentBtn}, ${styles.editAfterShipmentBtnDisabled}`}
																disabled
															>
																<FormattedMessage
																	id={'rod.release.edit_release'}
																/>
																{loading && (
																	<div className={styles.loader}>
																		<LoaderWhithoutProgress />
																	</div>
																)}
															</button>
														)}
													</>
												)}

												{(isShipmentsTakedownsEnableFeature ||
													isShipmentsTakedownsEnableFeatureForAccount) && (
													<>
														{allOutlets.length &&
														isShipmentsBtnEnable &&
														!isDraftStatus &&
														!isShipmentsInProcess &&
														!isTakenDownsInProcess ? (
															<Link
																to={{
																	pathname: `/repertoire/releases/${releaseId}/shipment`,
																	state: {
																		typeId: releaseTypeId,
																		shipmentsArr: shipmentsArr,
																	},
																}}
																className={styles.shipmentBtn}
																value={'actionChild'}
															>
																<FormattedMessage
																	id={'rod.release.release_shipment'}
																/>
															</Link>
														) : (
															<div className={styles.shipmentBtnDisabled}>
																<FormattedMessage
																	id={'rod.release.release_shipment'}
																/>
															</div>
														)}
														{isTakenDownsBtnEnable &&
														!isDraftStatus &&
														!isShipmentsInProcess &&
														!isTakenDownsInProcess ? (
															<Link
																to={{
																	pathname: `/repertoire/releases/${releaseId}/takendown/releases`,
																	state: {
																		typeId: releaseTypeId,
																		takenDownsArr: takenDownsArr,
																	},
																}}
																className={styles.fallbackBtn}
																value={'actionChild'}
															>
																<FormattedMessage
																	id={'rod.release.release_fallback_enable'}
																/>
															</Link>
														) : (
															<div className={styles.fallbackBtnDisabled}>
																<FormattedMessage
																	id={'rod.release.release_fallback'}
																/>
															</div>
														)}
													</>
												)}
											</div>
										)}
									</div>
								)}
							</>
						)}
				</div>
			</div>
			{isInfoModalActive && (
				<FullScreenModalConfirm
					title={<FormattedMessage id={'rod.release.edit_release.modal'} />}
					confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
					onClose={() => setIsInfoModalActive(false)}
				/>
			)}
		</>
	);
};

export default Header;
