/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */

import { Button } from 'components/Buttons';
import { Label } from 'components/Labels';
import { Link } from 'components/Links';
import { withAuth, withLang, withUI } from 'hocs';
import { ReactComponent as Bullet } from 'images/bullet-triangle.svg';
import React, { useEffect, useState } from 'react';
// import { Col, Container, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// import { auth } from 'services';
import { Helmet } from 'react-helmet';
import { DEFAULT_LANG } from 'contexts/LangContext';
import { partner } from 'messages/EN/pages_branding';
import { Form } from 'components/Form';
import { Input } from 'components/Inputs';
import { showError } from 'validators/showError';

import styles from './LandingNew.module.css';

const Landing = (props) => {
	const lang = localStorage.getItem('lang');
	if (!lang) localStorage.setItem('lang', DEFAULT_LANG);

	const form = React.createRef();

	const [allowSignup, setAllowSignup] = useState(true);
	const [username, setUsername] = useState();
	const [password, setPassword] = useState();

	const {
		authContext: { login, errors },
	} = props;

	const handlePassword = (event) => {
		setPassword(event);
	};

	const handleUsername = (event) => {
		setUsername(event);
	};

	const submitHandler = (event) => {
		event.preventDefault();
		login({
			username,
			password,
		});
	};

	useEffect(() => {
		setAllowSignup(props.UIContext.isAllowSignup);
	}, [props.UIContext.isAllowSignup]);

	return (
		<>
			<Helmet>
				<title>{partner.title_rod_company}</title>
			</Helmet>
			<div
				className={
					process.env.REACT_APP_CLIENT === 'dgtal' ? styles.Bg_dgtal : styles.Bg
				}
			>
				<div
					className={
						process.env.REACT_APP_CLIENT === 'dgtal'
							? styles.LoginWrapper_dgtal
							: styles.LoginWrapper
					}
				>
					<Form
						ref={form}
						onSubmit={submitHandler}
						className={styles.form_wrapper}
					>
						<Label className="title" black>
							<FormattedMessage id={'rod.login.header'} />
						</Label>
						<FormattedMessage id={'rod.field.email'}>
							{(placeholder) => (
								<Input
									placeholder={placeholder}
									onChange={handleUsername}
									errors={errors.email}
									showError={showError('email')}
									className={styles.Input}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.password'}>
							{(placeholder) => (
								<Input
									placeholder={placeholder}
									type={'password'}
									onChange={handlePassword}
									errors={errors.password}
									showError={showError('password')}
									className={styles.Input}
								/>
							)}
						</FormattedMessage>
						<div className={styles.submit}>
							<Button
								type={'submit'}
								variant={'primary'}
								disabled={!password || !username}
								className={styles.Button}
							>
								<FormattedMessage id={'rod.action.login'} />
							</Button>
						</div>

						{process.env.REACT_APP_CLIENT !== 'dgtal' && (
							<div className="links">
								<Link to={'/password/restore'}>
									<FormattedMessage id={'rod.login.text.forgot_password'} />
								</Link>
							</div>
						)}

						{props.UIContext.isAllowSignup &&
							process.env.REACT_APP_CLIENT !== 'dgtal' && (
								<div className={styles.register_button}>
									<FormattedMessage id={'rod.landing.form.have_not'} />
									&nbsp;
									<Link to={'/registration'}>
										<FormattedHTMLMessage id={'rod.action.create_single'} />
									</Link>
								</div>
							)}
					</Form>
				</div>
				{process.env.REACT_APP_CLIENT !== 'dgtal' && (
					<div className={styles.JoinWrapper}>
						<div className={styles.Hero}>
							<div>
								<div
									className={styles.Hero_header}
									style={{
										textTransform: 'lowercase',
									}}
								>
									<FormattedHTMLMessage
										id={
											process.env.REACT_APP_CLIENT === 'mts'
												? 'rod.landing.header.text.mts'
												: 'rod.landing.header.text'
										}
									/>
								</div>
							</div>
							<div>
								<div className={styles.HeroBox}>
									<div>
										<Bullet className={styles.svg_branding} />
									</div>

									<div className={styles.Hero_text}>
										<FormattedHTMLMessage id={'rod.landing.header.subtext_1'} />
									</div>
								</div>
								<div className={styles.HeroBox}>
									<div>
										<Bullet className={styles.svg_branding} />
									</div>
									<div className={styles.Hero_text}>
										<FormattedHTMLMessage id={'rod.landing.header.subtext_2'} />
									</div>
								</div>

								<div className={styles.HeroBox}>
									<div>
										<Bullet className={styles.svg_branding} />
									</div>
									<div className={styles.Hero_text}>
										<FormattedHTMLMessage id={'rod.landing.header.subtext_3'} />
									</div>
								</div>
							</div>
						</div>
						<div className={styles.formRow}>
							{allowSignup && (
								<div className={styles.Bottom_text}>
									<div className="bar"></div>

									<div className={styles.Bottom_text_title}>
										<FormattedMessage id={'rod.landing.footer.text_title'} />
									</div>

									<div className={styles.Bottom_text_body}>
										<FormattedMessage id={'rod.landing.footer.text_body'} />
									</div>
									<Button
										onClick={() => {
											props.history.push('/registration/special');
										}}
										className={styles.Button}
										style={{ marginTop: '20px' }}
									>
										<FormattedHTMLMessage
											id={'rod.landing.footer.link.leave_request'}
										/>
									</Button>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default compose(withRouter, withLang, withUI, withAuth)(Landing);
