// Icons
import spotifyIcon from 'images/playlist/spotifyIcon.png';
import appleIcon from 'images/playlist/appleMusicIcon.png';

// Styles
import styles from './OutletTabs.module.css';

const OutletTabs = ({ handleClickOutlet, outlet }) => {
	const outletsData = [
		{
			code: 'spotify',
			title: 'spotify',
			img: spotifyIcon,
		},
		{
			code: 'apple',
			title: 'apple music',
			img: appleIcon,
		},
	];

	return (
		<div className={styles.wrapperOutletTabs}>
			{outletsData.map((item) => (
				<button
					key={item.code}
					className={`${styles.outletTab} ${
						outlet === item.code ? styles.active : ''
					}`}
					onClick={() => handleClickOutlet(item.title)}
				>
					<img className={styles.outletImg} src={item.img} alt={item.title} />
					{item.title}
				</button>
			))}
		</div>
	);
};

export default OutletTabs;
