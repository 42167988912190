// Core
import { FormattedMessage } from 'react-intl';

// Data
import { timeZones } from './timeZone';

// UI
import DateTimeBlock from './DateTimeBlock/DateTimeBlock';
import { FormInput } from 'components/Form/FormInput';

// Styles
import styles from './DateTimeZone.module.css';

const DateTimeZone = ({
	data,
	changeField,
	setDeliveryTimeInHHMM,
	setSaleTimeInHHMM,
	itemValue,
	errors,
}) => {
	return (
		<>
			<div className={styles.dateTimeContainer}>
				<DateTimeBlock
					title="rod.distribution.date.shipment.title"
					name="delivery_date"
					data={data}
					changeField={changeField}
					setTimeInHHMM={setDeliveryTimeInHHMM}
					initialTimeInHHMM={data.delivery_time}
					itemValue={itemValue}
					errors={errors}
				/>
				{setSaleTimeInHHMM && (
					<DateTimeBlock
						name="sale_date"
						title="rod.distribution.date.sale_start_date.title"
						data={data}
						changeField={changeField}
						setTimeInHHMM={setSaleTimeInHHMM}
						initialTimeInHHMM={data.sale_time}
						itemValue={itemValue}
						errors={errors}
					/>
				)}
			</div>
			<div className={styles.timeZoneContainer}>
				<FormInput
					type="muiInputSelect"
					name={'time_zone'}
					onChange={changeField}
					errors={{}}
					data={data}
					label={
						<FormattedMessage
							id={'rod.distribution.date.sale_start_date.time_zone'}
						/>
					}
					items={timeZones}
					sort={false}
					required
				/>
			</div>
		</>
	);
};

export default DateTimeZone;
