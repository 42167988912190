// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import lscache from 'lscache';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { useLocation } from 'react-router-dom';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { getDateInYYYYMMDD } from './utils/getDateInYYYYMMDD';
import { convertDataToCumulative } from './utils/convertDataToCumulative';
import { cumulativeDataToUnitsThousMill } from './utils/cumulativeDataToUnitsThousMill';
import { checkRangeInOneYear } from '../utils/checkRangeInOneYear';
import { getStartEndDatesForOneYear } from '../utils/getStartEndDatesForOneYear';
import { getPreviousMonth } from '../utils/getPreviousMonth';

// UI
import StatisticHeader from 'material-design/Statistic/StatisticHeader/StatisticHeader';
import DataLoadingError from '../DataLoadingError/DataLoadingError';
import { Loading, NoData, TopTracksSkeleton } from 'components';
import DatesBlockNew from './DatesBlockNew/DatesBlockNew';
import TopTracks from './TopTracks/TopTracks';
import MultipleSelectOutletsCheckmarks from 'material-design/components/MuiMultipleCheckedOutlets/MuiMultipleCheckedOutlets';
import StatisticDownloadBtn from 'components/StatisticDownloadBtn/StatisticDownloadBtn';
import { FullScreenModalConfirm } from 'containers/Modal';
import { StatisticByDatesChart } from './StatisticByDatesChart';
import StatisticFilterModalDate from '../StatisticFilterModalDate/StatisticFilterModalDate';
import close from 'images/close_button.svg';

// Styles
import styles from './StatisticByDatesSection.module.css';
import './DatesBlockNew/DateBlockNewForDate.css';

import filter_svg from 'images/filter_small.svg';
import filter_dot_svg from 'images/filter_small_dot.svg';

const StatisticByDatesSection = (props) => {
	let { pathname } = useLocation();
	const {
		rootContext: {
			getAccountStatisticOutlets,
			getAccountStatisticAuthorOutlets,
			getAccountStatisticTopTracks,
			getAccountStatisticAuthorTopTracks,
			getAccountStatisticStreams,
			getAccountStatisticAuthorStreams,
			getReleaseStatisticCheck,
			getAccountStatisticCheck,
		},

		adminAccountId,
	} = props;

	const { showModal, isCopyrightingStatisticsEnabled } = useContext(UIContext);
	const { lang } = useContext(LangContext);

	let maxY = 0;
	const MAX_DATE = Date.now();
	const MIN_DATE = '2020-01-01';

	const isAdmin = adminAccountId ? true : false;
	const accountId = adminAccountId
		? adminAccountId
		: props.authContext.accountId;

	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
		outlets: [],
		tracks: [],
	});

	const [datePickerData, setDatePickerData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
	});

	const [page, setPage] = useState(1);
	const [pageLoading, setPageLoading] = useState(true);
	const [isPaginationPressed, setIsPaginationPressed] = useState(false);
	const [helper, setHelper] = useState(false);
	const [isFilterModalActive, setIsFilterModalActive] = useState(false);

	// Arrays data
	const [recordingsForRender, setRecordingsForRender] = useState([]);
	const [releasesForRender, setReleasesForRender] = useState([]);

	// For tracks
	const [topTracks, setTopTracks] = useState([]);
	const [tracksIds, setTracksIds] = useState([]);
	const [isAllTracks, setIsAllTracks] = useState(false);
	const [trackSearchValue, setTrackSearchValue] = useState('');
	const [checkedTopTrack, setCheckedTopTrack] = useState(null);
	const [isTracksFilterEmpty, setIsTracksFilterEmpty] = useState(false);

	// For outltes
	const [initialOutlets, setInitialOutlets] = useState([]);
	const [outletsStatistic, setOutletsStatistic] = useState({});
	const [accountOutlets, setAccountOutlets] = useState([]);
	const [isOutletsFilterEmpty, setIsOutletsFilterEmpty] = useState(false);
	const [outletCodes, setOutletCodes] = useState([]);

	// Other
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState({});
	const [amountYAxisTitle, setAmountYAxisTitle] = useState('');
	const [accountAuditions, setAccountAuditions] = useState([]);
	const [xAxisSize, setXAxisSize] = useState(0);
	const [isReqLive] = useState(false);
	const [showRangeModal, setShowRangeModal] = useState(false);
	const [dataError, setDataError] = useState(false);
	const [lastStatDate, setLastStatDate] = useState(null);
	const [chartMode, setChartMode] = useState(props.chartMode ?? 'phono');

	const id = localStorage.getItem('accountId');
	const [showHeading, setShowHeading] = useState(false);
	const [showContent, setShowContent] = useState(true);

	const [hasRecordingsForRender, setHasRecordingsForRender] = useState(true);

	const [activeFilters, setActiveFilters] = useState(false);

	const [applyDate, setApplyDate] = useState(false);

	const [isLastCalendar, setIsLastCalendar] = useState(false);

	const outletRequest =
		chartMode === 'compo' && !props.releaseId
			? getAccountStatisticAuthorOutlets
			: getAccountStatisticOutlets;

	const streamsRequest =
		chartMode === 'compo' && !props.releaseId
			? getAccountStatisticAuthorStreams
			: getAccountStatisticStreams;

	const topTracksRequest =
		chartMode === 'compo' && !props.releaseId
			? getAccountStatisticAuthorTopTracks
			: getAccountStatisticTopTracks;

	const getDataForEachOutlet = (selectedOutlet, startDate, endDate) => {
		if (startDate.includes('T')) {
			startDate = startDate.slice(0, startDate.indexOf('T'));
		}
		if (endDate.includes('T')) {
			endDate = endDate.slice(0, endDate.indexOf('T'));
		}
		let result = [];
		const rangeInDays = Math.ceil(
			(new Date(endDate).getTime() - new Date(startDate).getTime()) / 86400000
		);
		const xSize =
			rangeInDays <= 10
				? rangeInDays
				: Math.ceil(rangeInDays / Math.ceil(rangeInDays / 10));
		setXAxisSize(xSize);
		const accountAuditionsByOutlet = accountAuditions.filter(
			(element) => element.outlet === selectedOutlet.code
		);
		maxY = 0;
		for (let i = 0; i <= rangeInDays; i++) {
			let startDateinMs = new Date(
				new Date(startDate).getTime() + 86400000 * i
			);
			let y;
			let x =
				startDateinMs.getFullYear() +
				'-' +
				(startDateinMs.getMonth() + 1).toString().padStart(2, '0') +
				'-' +
				startDateinMs.getDate().toString().padStart(2, '0');
			let succesfullyCompared = accountAuditionsByOutlet.find(
				(data) => data.date === x
			);
			y = succesfullyCompared ? +succesfullyCompared.stat_count : 0;
			if (y > maxY) {
				maxY = y;
			}
			result.push({
				x: new Date(x),
				y: y,
			});
		}
		return result;
	};

	const handleCheckedOutlets = (outlets) => {
		setIsOutletsFilterEmpty(outlets.length ? false : true);
		const codes = outlets.map((item) => item.code);
		if (outlets.length !== outletCodes.length) {
			setOutletCodes(codes);
			statisticData.outlets = outlets;
			setStatisticData({ ...statisticData });
		}
	};

	const prepareFinalData = () => {
		setApplyDate(false);
		let newStatForSelectedOutlets = {};
		let index = 1;

		const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
		const outletsForRendering =
			statisticData['outlets'].length > 0
				? statisticData['outlets']
				: accountOutlets;
		outletsForRendering.forEach((selectedOutlet) => {
			newStatForSelectedOutlets['id' + index++] = {
				title: selectedOutlet[`title_${lang}`],
				code: selectedOutlet.code,
				data: getDataForEachOutlet(
					selectedOutlet,
					startEndDates[0],
					startEndDates[1]
				),
				maxY: maxY,
			};
		});

		const cumulativeVariant = convertDataToCumulative(
			newStatForSelectedOutlets
		);

		cumulativeDataToUnitsThousMill(cumulativeVariant, setAmountYAxisTitle);
		setOutletsStatistic({ ...cumulativeVariant });
	};

	const applyDateFilter = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setStatisticData({ ...statisticData });
	};

	const changeField = (field) => (e) => {
		setPage(1);
		switch (field) {
			case 'start_date':
				if (Date.parse(e) > Date.parse(datePickerData['end_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) >= Date.parse(MIN_DATE)) {
						if (!isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								start_date: e,
							});
						}
					} else if (Date.parse(e) > Date.parse(statisticData['end_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['start_date'];
							return a;
						});
					} else {
						let errText = '';
						if (Date.parse(e) < Date.parse(MIN_DATE)) {
							errText = 'minDate';
						} else errText = 'invalid date';
						setErrors((prev) => ({ ...prev, start_date: errText }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 32)
					);
				}

				break;
			case 'end_date':
				if (Date.parse(e) < Date.parse(datePickerData['start_date'])) {
					return;
				}
				if (e && e.getFullYear().toString().length === 4) {
					e = getDateInYYYYMMDD(e);
					if (Date.parse(e) <= MAX_DATE) {
						if (isLastCalendar) {
							setDatePickerData({
								...datePickerData,
								end_date: e,
							});
						}
					} else if (Date.parse(e) < Date.parse(statisticData['start_date'])) {
						setErrors((prev) => {
							const a = { ...prev };
							delete a['end_date'];
							return a;
						});
					} else {
						setErrors((prev) => ({ ...prev, end_date: 'invalid date' }));
					}
				}
				if (e === null) {
					statisticData[field] = getDateInYYYYMMDD(
						new Date(new Date().getTime() - 86400000 * 2)
					);
				}

				break;

			case 'recordings':
				statisticData[field] = e.map((item) => item.raw_title);
				setStatisticData({ ...statisticData });
				break;

			case 'tracks':
				statisticData[field] = e.map((item) => item.title);
				setStatisticData({ ...statisticData });
				break;
			default:
				statisticData[field] = e.target.value;
				setStatisticData({ ...statisticData });
				break;
		}
	};

	const startEndDates =
		statisticData.start_date !== MIN_DATE
			? getStartEndDatesForOneYear(statisticData, isAdmin)
			: [MIN_DATE, statisticData.end_date];

	const closeHelper = () => {
		setHelper(false);
		localStorage.setItem('helper', 'showed');
	};

	const closeModal = () => {
		setShowRangeModal(false);
	};

	useEffect(() => {
		if (props.releaseId) {
			setLoading(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountId, props.releaseId]);

	useEffect(() => {
		const isShowedHelper = localStorage.getItem('helper');
		if (isShowedHelper === 'showed') {
			setHelper(false);
		} else {
			setHelper(true);
		}

		outletRequest().then((res) => {
			res = res.data.data;
			setAccountOutlets(res);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isTracksFilterEmpty || isOutletsFilterEmpty) {
			return;
		} else if (
			accountOutlets?.length &&
			tracksIds?.length &&
			!Object.keys(errors).length
		) {
			setPageLoading(true);
			setLoading(true);

			const outlets =
				statisticData['outlets']?.length > 0
					? statisticData['outlets']
					: accountOutlets;

			const outletsCodes = outlets.map((outlet) => outlet.code);
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);

			// Хешируем ключ - Аутлеты сортируем по алфавиту. Соеденяем масивы данных в строку через * начиная с аутлетов
			const sortOutlets = outletsCodes.sort();
			const chartHash = `${sortOutlets.join('*')}*${startEndDates.join('*')}
			*${isAllTracks ? 'all' : tracksIds.join('*')}*chart*${accountId}`;

			const chartReqFromCash = lscache.get(chartHash);

			if (chartReqFromCash && !isReqLive) {
				setAccountAuditions(chartReqFromCash.global);
			} else {
				setLoading(true);
				streamsRequest(
					isAdmin,
					accountId,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					recordingsForRender,
					releasesForRender,
					tracksIds.length && !isAllTracks ? false : true
				)
					.then((res) => {
						res = res.data.data;
						setHasRecordingsForRender(!res.length ? false : true);
						setLoading(false);
						setApplyDate(false);
						if (isReqLive) {
							setAccountAuditions(res.global);
						} else {
							setAccountAuditions(res.global);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							setDataError(true);
						}
						console.error(error.response);
					});
			}

			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${
				isAllTracks ? 'all' : tracksIds.join('*')
			}*topTracks*${accountId}*${page}`;

			const topTracksReqFromCash = lscache.get(topTracksHash);
			setPage(1);
			setIsPaginationPressed(false);

			if (topTracksReqFromCash && !isReqLive) {
				setTopTracks(topTracksReqFromCash.recordings);
				setTotal(topTracksReqFromCash.total);
				setPageLoading(false);
			} else {
				topTracksRequest(
					isAdmin,
					accountId,
					1,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					10,
					recordingsForRender,
					releasesForRender // props.releaseId ? [props.releaseId] : []
				).then((res) => {
					res = res.data.data;
					setApplyDate(false);
					if (!res.recordings.length) {
						setAccountAuditions([]);
						setPageLoading(false);
					} else if (isReqLive) {
						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					} else {
						setTopTracks(res.recordings);
						setTotal(res.total);
						setPageLoading(false);
					}
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		accountOutlets,
		statisticData,
		recordingsForRender,
		startEndDates[0],
		startEndDates[1],
		props.release,
	]);

	useEffect(() => {
		const outlets =
			statisticData['outlets']?.length > 0
				? statisticData['outlets']
				: accountOutlets;
		if (!outlets.length) {
			return;
		}
		const outletsCodes = outlets.map((outlet) => outlet.code);
		setLoading(true);
		streamsRequest(
			isAdmin,
			accountId,
			startEndDates[0],
			startEndDates[1],
			outletsCodes,
			recordingsForRender,
			releasesForRender,
			tracksIds.length && !isAllTracks ? false : true
		)
			.then((res) => {
				res = res.data.data;
				setHasRecordingsForRender(!res.length ? false : true);
				setLoading(false);
				setApplyDate(false);
				if (isReqLive) {
					setAccountAuditions(res.global);
				} else {
					setAccountAuditions(res.global);
				}
			})
			.catch((error) => {
				if (error.response.status === 422) {
					setDataError(true);
				}
				console.error(error.response);
			});

		topTracksRequest(
			isAdmin,
			accountId,
			1,
			startEndDates[0],
			startEndDates[1],
			outletsCodes,
			10,
			recordingsForRender,
			releasesForRender // props.releaseId ? [props.releaseId] : []
		).then((res) => {
			res = res.data.data;
			if (!res.recordings.length) {
				setAccountAuditions([]);
				setPageLoading(false);
				setApplyDate(false);
			} else if (isReqLive) {
				setTopTracks(res.recordings);
				setTotal(res.total);
				setPageLoading(false);
			} else {
				setTopTracks(res.recordings);
				setTotal(res.total);
				setPageLoading(false);
			}
		});
	}, [recordingsForRender, statisticData, startEndDates[0], startEndDates[1]]);

	useEffect(() => {
		prepareFinalData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountAuditions]);

	useEffect(() => {
		if (Object.values(outletsStatistic).length) setLoading(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outletsStatistic]);

	useEffect(() => {
		if (
			accountOutlets.length &&
			isPaginationPressed &&
			statisticData !== null
		) {
			const outlets =
				statisticData['outlets'].length > 0
					? statisticData['outlets']
					: accountOutlets;

			const outletsCodes = outlets.map((outlet) => outlet.code);
			const startEndDates = getStartEndDatesForOneYear(statisticData, isAdmin);
			const sortOutlets = outletsCodes.sort();
			const topTracksHash = `${sortOutlets.join('*')}*${startEndDates.join(
				'*'
			)}*${
				isAllTracks ? 'all' : tracksIds.join('*')
			}*topTracks*${accountId}*${page}`;

			const topTracksReqFromCash = lscache.get(topTracksHash);

			if (topTracksReqFromCash && !isReqLive) {
				setTopTracks(topTracksReqFromCash.recordings);
				setTotal(topTracksReqFromCash.total);
				setPageLoading(false);
			} else {
				topTracksRequest(
					isAdmin,
					accountId,
					page,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					10,
					recordingsForRender ? recordingsForRender : [],
					releasesForRender ? releasesForRender : [],
					trackSearchValue ? tracksIds : isAllTracks ? [] : tracksIds,
					props.releaseId ? [props.releaseId] : []
				).then((res) => {
					res = res.data.data;

					setTopTracks(res.recordings);
					setTotal(res.total);
					setPageLoading(false);
					setApplyDate(false);
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPaginationPressed, page]);

	const setRouteData = async () => {
		try {
			const { data: res } = await getAccountStatisticCheck(id);
			const { statistic_types } = res;
			if (!statistic_types.length || statistic_types.length === 2) {
				setShowHeading(true);
			} else {
				setShowHeading(false);
			}

			if (!statistic_types.length) {
				setShowContent(false);
			} else {
				setShowContent(true);
			}
		} catch (error) {
			console.error('Error fetching account statistics:', error);
		}
	};

	useEffect(() => {
		const fetchRouteData = async () => {
			try {
				await setRouteData();
			} catch (error) {
				console.error('Error fetching account statistics:', error);
			}
		};
		if (id) {
			fetchRouteData();
		}
	}, [id]);

	const handleResetAllFilters = () => {
		const outlets =
			statisticData['outlets']?.length > 0
				? statisticData['outlets']
				: accountOutlets;
		const outletsCodes = outlets.map((outlet) => outlet.code);
		setLoading(true);
		topTracksRequest(
			isAdmin,
			accountId,
			page,
			startEndDates[0],
			startEndDates[1],
			outletsCodes,
			10,
			recordingsForRender ? recordingsForRender : [],
			releasesForRender ? releasesForRender : [],
			trackSearchValue ? tracksIds : isAllTracks ? [] : tracksIds,
			props.releaseId ? [props.releaseId] : []
		).then((res) => {
			res = res.data.data;
			setTopTracks(res.recordings);
			setTotal(res.total);
			setRecordingsForRender([]);
			setReleasesForRender([]);
			setPageLoading(false);
		});
		streamsRequest(
			isAdmin,
			accountId,
			startEndDates[0],
			startEndDates[1],
			outletsCodes,
			trackSearchValue ? tracksIds : isAllTracks ? [] : tracksIds,
			props.releaseId ? [props.releaseId] : [],
			tracksIds.length && !isAllTracks ? false : true
		).then((res) => {
			res = res.data.data;
			setHasRecordingsForRender(!res.length ? false : true);
			setLoading(false);
			setAccountAuditions(res.global);
		});
	};

	const handleCheckedTopTrack = (track) => {
		setRecordingsForRender(track.id);
		setActiveFilters(true);
		setPage(1);
		setCheckedTopTrack(track);
	};

	const handleClearAllFilters = () => {
		setStatisticData({
			...statisticData,
			outlets: initialOutlets,
			tracksIds: [],
			countries: [],
			page: 1,
		});
		setRecordingsForRender([]);
		setReleasesForRender([]);
		setCheckedTopTrack([]);
		setLoading(false);
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setPage(1);
		setApplyDate(true);
		setStatisticData({ ...statisticData });
	};

	useEffect(() => {
		if (applyDate) {
			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.end_date
			);

			checkRangeInOneYear(
				statisticData,
				setShowRangeModal,
				showModal,
				statisticData.start_date
			);
		}
	}, [applyDate]);

	useEffect(() => {
		if (pathname.includes('statistic')) {
			document.body.classList.add('statisticByDateTabSection');
		}

		return () => {
			document.body.removeAttribute('class');
		};
	}, [pathname]);

	const handleResetTopTrack = () => {
		setCheckedTopTrack([]);
	};

	return (
		<>
			{helper && !props.release && (
				<div className={styles.helper}>
					<img onClick={closeHelper} src={close} alt="" />
					<span>
						<FormattedHTMLMessage id={'rod.statistic.notification'} />
					</span>
				</div>
			)}
			{showHeading && !props.releaseId && isCopyrightingStatisticsEnabled && (
				<StatisticHeader
					chartMode={chartMode}
					setChartMode={setChartMode}
					accountId={accountId}
					statisticData={statisticData}
					accountOutlets={accountOutlets}
					isAdmin={isAdmin}
					trackSearchValue={trackSearchValue}
					tracksIds={tracksIds}
					isAllTracks={isAllTracks}
					releaseId={props.releaseId}
					statType="bydate"
				/>
			)}
			{outletsStatistic && (
				<div className={styles.statisticByDate__mainContent}>
					<div className={styles.inputsWrapper}>
						<div className={styles.inputOutlets}>
							<MultipleSelectOutletsCheckmarks
								handleCheckedOutlets={handleCheckedOutlets}
								lang={lang}
								className={styles.tracksCheckmarks}
								releaseId={props.releaseId}
								buttonReset={false}
								chartMode={chartMode}
							/>
							{Object.keys(errors).includes('outlets') && (
								<span className={styles.Helper}>error</span>
							)}
						</div>

						{showRangeModal && (
							<FullScreenModalConfirm
								title={<FormattedMessage id={'rod.modal.statistic_title'} />}
								text={<FormattedMessage id={'rod.modal.statistic_text'} />}
								confirmBtnTxt={<FormattedMessage id={'rod.modal.understood'} />}
								onClose={closeModal}
							/>
						)}

						<DatesBlockNew
							accountId={accountId}
							accountOutlets={accountOutlets}
							isAdmin={isAdmin}
							changeField={changeField}
							statisticData={datePickerData}
							errors={errors}
							trackSearchValue={trackSearchValue}
							tracksIds={tracksIds}
							isAllTracks={isAllTracks}
							releaseId={props.releaseId}
							lastStatDate={lastStatDate}
							handleCheckedPeriod={handleCheckedPeriod}
							setApplyDate={setApplyDate}
							loading={loading}
							applyDateFilter={applyDateFilter}
							setIsLastCalendar={setIsLastCalendar}
						/>

						<div
							onClick={() => setIsFilterModalActive(true)}
							className={styles.filtersButton}
						>
							<img src={activeFilters ? filter_dot_svg : filter_svg} alt="" />
							<span>
								<FormattedMessage id={'rod.admin.reports.filters'} />
							</span>
						</div>
						<div className={styles.wrapperDownloadBtn}>
							<StatisticDownloadBtn
								accountId={accountId}
								statisticData={statisticData}
								accountOutlets={accountOutlets}
								isAdmin={isAdmin}
								releaseId={releasesForRender ? releasesForRender : []}
								tracksIds={recordingsForRender ? recordingsForRender : []}
								chartMode={chartMode}
								withTitle
								v2
							/>
						</div>
					</div>

					{!loading && dataError && <DataLoadingError />}
					{!loading &&
						props.releaseId &&
						lastStatDate &&
						(isOutletsFilterEmpty || isTracksFilterEmpty) && (
							<>
								<NoData textId="rod.statistic.no-data" />
							</>
						)}
					{!loading && props.releaseId && !lastStatDate && (
						<div className={styles.noReleaseData}>
							<h4>
								<FormattedMessage id={'rod.admin.no-data'} />
							</h4>
						</div>
					)}
					{!loading && !props.releaseId && isOutletsFilterEmpty && (
						<>
							<NoData textId="rod.statistic.no-data" />
						</>
					)}

					{!loading &&
						!props.releaseId &&
						!hasRecordingsForRender &&
						!accountAuditions.length && (
							<>
								<NoData textId="rod.statistic.no-data" />
							</>
						)}

					<>
						{loading ? (
							<Loading className={styles.loader} />
						) : (
							<>
								{isOutletsFilterEmpty ||
								isTracksFilterEmpty ||
								accountAuditions.length === 0 ||
								!outletsStatistic ||
								!amountYAxisTitle ? (
									<></>
								) : (
									<>
										{!dataError && (
											<StatisticByDatesChart
												data={outletsStatistic}
												selectedOutlets={
													statisticData['outlets'].length
														? statisticData['outlets']
														: accountOutlets
												}
												xAxisSize={xAxisSize}
												amountYAxisTitle={amountYAxisTitle}
											/>
										)}

										<>
											{pageLoading ? (
												<TopTracksSkeleton />
											) : (
												<>
													{topTracks && topTracks.length > 0 && (
														<>
															<div className={styles.topTracksTitle}>
																<span>
																	{activeFilters ? (
																		<FormattedHTMLMessage
																			id={'rod.statistic.list_tracks'}
																		/>
																	) : (
																		<FormattedHTMLMessage
																			id={'rod.statistic.top_tracks'}
																		/>
																	)}
																</span>
															</div>
															<TopTracks
																topTracks={topTracks}
																page={page}
																total={total}
																setPage={setPage}
																handleCheckedTopTrack={handleCheckedTopTrack}
																setIsPaginationPressed={setIsPaginationPressed}
																setPageLoading={setPageLoading}
																release={props.release}
																chartMode={chartMode}
																accountId={accountId}
																isAdmin={isAdmin}
																statisticData={statisticData}
																outlets={accountOutlets}
															/>
														</>
													)}
												</>
											)}
										</>
									</>
								)}
							</>
						)}
					</>

					<StatisticFilterModalDate
						lang={lang}
						isAdmin={isAdmin}
						accountId={accountId}
						statisticData={statisticData}
						errors={errors}
						startEndDates={startEndDates}
						active={isFilterModalActive}
						setLoading={setLoading}
						handleResetAllFilters={handleResetAllFilters}
						setActive={() => setIsFilterModalActive(false)}
						setActiveFilters={setActiveFilters}
						recordingsForRender={setRecordingsForRender}
						releasesForRender={setReleasesForRender}
						checkedTopTrack={checkedTopTrack}
						handleResetTopTrack={handleResetTopTrack}
						setHasRecordingsForRender={setHasRecordingsForRender}
						setPage={setPage}
						handleSetFilters={(filtersData, isAllChecked, searchValue) => {
							Object.keys(filtersData).forEach((filterKey) => {
								if (filtersData[filterKey]) {
									statisticData[filterKey] = filtersData[filterKey];
								}
							});
							if (isAllChecked) {
								statisticData.tracksIds = [];
							}
							if (searchValue) {
								statisticData.trackSearchValue = searchValue;
							}

							statisticData.checkedAllTracks = isAllChecked;
							statisticData.page = 1;
							setStatisticData({ ...statisticData });
						}}
						handleClearAllFilters={handleClearAllFilters}
						chartMode={chartMode}
					/>
				</div>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(StatisticByDatesSection);
