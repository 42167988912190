// Core
import { parseISO, format } from 'date-fns';

export const formatDateToDdMmYy = (dateString) => {
	try {
		const date = parseISO(dateString);
		return format(date, 'dd.MM.yyyy');
	} catch (error) {
		return dateString;
	}
};
