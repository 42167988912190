// Core
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { UIContext } from 'contexts/UIContext';

// Icons
import calendarImgActive from 'images/calendar_icon_active.svg';

// Styles
import styles from './DateButton.module.css';
import { LangContext } from 'contexts/LangContext';

const DateButton = ({
	item,
	outlets,
	title,
	modalType,
	personal,
	setPersonal,
	disabled,
	resetErrors,
	resetBackErrors,
}) => {
	const { lang } = useContext(LangContext);
	const { showModal } = useContext(UIContext);

	return (
		<button
			className={
				disabled
					? `${styles.selectDateBtn} ${styles.disabled}`
					: styles.selectDateBtn
			}
			onClick={showModal(
				{
					item,
					outlets,
					lang,
					personal,
					setPersonal,
					resetErrors,
					resetBackErrors,
				},
				modalType
			)}
			disabled={disabled}
		>
			<img
				style={disabled ? { opacity: '0.3' } : {}}
				src={calendarImgActive}
				alt="calendar"
			/>
			<span style={disabled ? { borderBottom: '1px solid #1010104D' } : {}}>
				<FormattedMessage id={title} />
			</span>
		</button>
	);
};

export default DateButton;
