// Core
import React from 'react';
import { useHistory } from 'react-router-dom';

// Icons
import emptyCoverAlbum from 'images/playlist/emptyCoverAlbum.png';

// Styles
import styles from './PlaylistItem.module.css';

function PlaylistItem({ item, idx, currentPage, accountId }) {
	const history = useHistory();

	return (
		<>
			<li
				className={styles.playlistListItem}
				onClick={() =>
					history.push(
						`/accounts/${accountId}/statistic/playlist/${item.heaven11_track_id}/${item.heaven11_release_id}`
					)
				}
			>
				<div className={styles.playlistInfoWrapper}>
					<div className={styles.playlistNumber}>
						{idx + 1 + (currentPage - 1) * 10}
					</div>
					<img
						className={styles.playlistImg}
						src={item.cover ?? emptyCoverAlbum}
						alt={item.raw_title}
					/>
					<div>
						<p className={styles.playlistName}>{item.raw_title}</p>
						<span className={styles.performer}>{item.performers}</span>
					</div>
				</div>
				<div className={styles.auditionsQuantity}>
					{item.release_name?.length ? item.release_name : '-'}
				</div>
				<div className={styles.subscribersQuantity}>{item.playlists_count}</div>
			</li>
		</>
	);
}

export default PlaylistItem;
