// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { accounts } from 'services';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { LangContext } from 'contexts/LangContext';

// Components
import MultipleSelectCheckmarksPlaylist from './PlaylistFilters/RecordingsFilter/RecordingsFilter';
import PeriodFilter from './PlaylistFilters/PeriodFilter/PeriodFilter';
import PlaylistEmpty from './PlaylistEmpty/PlaylistEmpty';
import PlaylistSkeleton from './PlaylistSkeleton/PlaylistSkeleton';
import PlaylistItem from './PlaylistItem/PlaylistItem';

// UI
import { Pagination } from 'material-design/components';

// Styles
import styles from './Playlist.module.css';
import './PlaylistFilters/PeriodFilter/PeriodFilterForDate.css';

const Playlist = () => {
	const { accountId } = useContext(AuthContext);
	const { lang } = useContext(LangContext);

	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 8)
			.toISOString()
			.split('T')[0],
		end_date: new Date(new Date().getTime() - 86400000 * 2)
			.toISOString()
			.split('T')[0],
		outlets: [],
		tracks: [],
	});

	const [playlistData, setPlaylistData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [recordingsState, setRecordingsState] = useState([]);
	const [isAllTracks, setIsAllTracks] = useState(false);
	const [tracksIds, setTracksIds] = useState([]);

	const [page, setPage] = useState(1);

	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	const [isFirstRender, setIsFirstRender] = useState(true);
	const [labelPeriod, setLabelPeriod] = useState('week');

	const handleCheckedTracks = (tracks, isAll) => {
		if (tracks.length !== tracksIds.length || tracksIds.length === 1) {
			setIsAllTracks(isAll);
			setTracksIds(tracks);
			statisticData.tracks = tracks;
			setStatisticData({ ...statisticData });
		}
	};

	const handleCheckedPeriod = (startDate, endDate) => {
		statisticData.start_date = startDate;
		statisticData.end_date = endDate;
		setPage(1);
		setStatisticData({ ...statisticData });
	};

	const getRecordingsInPlaylists = async () => {
		setLoading(true);
		setPlaylistData([]);
		try {
			const topReleasesRes = await accounts.getAccountRecordingsInPlaylists(
				accountId,
				page,
				statisticData.tracks,
				statisticData.start_date,
				statisticData.end_date
			);

			setTotal(topReleasesRes.data.total);
			setCurrentPage(topReleasesRes.data.current_page);

			const topReleases = topReleasesRes.data;

			if (topReleases.total) {
				const releasesIds = topReleases.recordings.map(
					(release) => release.heaven11_release_id
				);

				const getReleasesInfo = await accounts.getAccountStatisticTopReleasesInfo(
					accountId,
					releasesIds
				);

				if (getReleasesInfo.data.data.length) {
					const topReleasesData = getReleasesInfo.data.data;
					const topReleasesInfo = topReleases.recordings.map((release) => ({
						...release,
						cover:
							topReleasesData.find(
								(item) => item.id === release.heaven11_release_id
							)?.covers.path_xm ?? null,
					}));
					setPlaylistData(topReleasesInfo);
				} else {
					setPlaylistData(topReleases.recordings);
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		statisticData.tracks = recordingsState;
		setStatisticData({ ...statisticData });
	}, [recordingsState]);

	useEffect(() => {
		const playlistsPagination = JSON.parse(
			localStorage.getItem('playlistsPagination')
		);

		statisticData.start_date =
			playlistsPagination?.startDate ?? statisticData.start_date;
		statisticData.end_date =
			playlistsPagination?.endDate ?? statisticData.end_date;

		setPage(playlistsPagination?.pageActive ?? 1);
		setStatisticData({ ...statisticData });
		setLabelPeriod(playlistsPagination?.period);
	}, []);

	useEffect(() => {
		localStorage.setItem(
			'playlistsPagination',
			JSON.stringify({
				pageActive: page,
				startDate: statisticData.start_date,
				endDate: statisticData.end_date,
				period: labelPeriod,
			})
		);
	}, [page, recordingsState, statisticData]);

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}
		getRecordingsInPlaylists();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountId, page, recordingsState, statisticData]);

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<p className={styles.title}>
						<FormattedHTMLMessage id={'rod.statistic.tracks'} />
					</p>
					<div className={styles.wrapperFilers}>
						<div className={styles.wrapperInputFilter}>
							<MultipleSelectCheckmarksPlaylist
								accountId={accountId}
								handleCheckedTracks={handleCheckedTracks}
								dateFrom={statisticData.start_date}
								dateTo={statisticData.end_date}
								setLoading={setLoading}
								lang={lang}
								recordingsState={setRecordingsState}
								setPage={setPage}
							/>
						</div>

						<PeriodFilter
							handleCheckedPeriod={handleCheckedPeriod}
							loading={loading}
							playlistsPage={true}
							setLabelPeriod={setLabelPeriod}
						/>
					</div>
				</div>

				{loading ? (
					<div className={styles.skeletonWrapper}>
						<PlaylistSkeleton />
					</div>
				) : playlistData && playlistData.length > 0 ? (
					<div>
						<div className={styles.headerInfo}>
							<p className={styles.headerInfoName}>
								<FormattedMessage
									id={'rod.statistic.playlists.recording_name'}
								/>
							</p>
							<p className={styles.headerInfoStreams}>
								<FormattedMessage id={'rod.statistic.playlists.album_name'} />
							</p>
							<p className={styles.headerInfoFollowers}>
								<FormattedMessage
									id={'rod.statistic.playlists.number_playlists'}
								/>
							</p>
						</div>

						<ul className={styles.playlistList}>
							{playlistData.map((item, idx) => (
								<PlaylistItem
									key={idx}
									item={item}
									idx={idx}
									accountId={accountId}
									currentPage={currentPage}
								/>
							))}
						</ul>
					</div>
				) : (
					<PlaylistEmpty />
				)}
			</div>
			{total > 10 && playlistData.length > 0 && (
				<div className={styles.paginationWrapper}>
					<Pagination
						page={page}
						total={total}
						paginate={(pageNumber) => {
							if (pageNumber !== page) {
								setLoading(true);
								setPage(pageNumber);
							}
						}}
						items_on_page={10}
					/>
				</div>
			)}
		</>
	);
};

export default Playlist;
