// Core
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// Utils
import update from 'immutability-helper';
import { withAuth, withRoot, withUI } from 'hocs';
import { compose } from 'recompose';

// Constants
import contractStates from 'constants/contractStates';

// UI
import { Loading, TitleWithHelpBtn, Wizard } from 'components';
import { Button } from 'components/Buttons/Button';
import { ContractListItem } from 'components/ContractListItem';
import { Label } from 'components/Labels/Label';

// Styles
import styles from './Sign.module.css';

class Sign extends PureComponent {
	constructor(props) {
		super(props);
		this.steps = [
			{
				id: 1,
				status: 'complete',
				title: <FormattedMessage id={'rod.account.create.step_3_1.title'} />,
			},
			{
				id: 2,
				status: 'active',
				title: <FormattedMessage id={'rod.account.create.step_3_2.title'} />,
			},
		];

		this.state = {
			loading: true,
			data: {},
			isFileUploaded: false,
			isSubmitPressed: false,
		};
	}

	componentDidMount() {
		const {
			authContext: { setIsAccountLoading },
			rootContext: { getContracts, getAdditionalContracts },
		} = this.props;

		setIsAccountLoading(true);
		if (this.props.isAdditional) {
			getAdditionalContracts(this.props.account.id).then((res) => {
				res = res.data.data;
				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);
				setIsAccountLoading(false);
			});
		} else {
			getContracts(this.props.account.id).then((res) => {
				res = res.data.data;
				this.setState(
					update(this.state, {
						data: { $set: res },
						loading: { $set: false },
					})
				);
				setIsAccountLoading(false);
			});
		}
	}

	submit = () => {
		if (typeof this.props.onSubmit === 'function') {
			if (this.state.isSubmitPressed) return;
			this.setState({ isSubmitPressed: true });
			this.props.onSubmit(this.state.data);
		}
	};

	isFileUploaded = (data) => {
		this.setState({ isSubmitPressed: false });
		this.setState({ isFileUploaded: data });
		this.props.resetSubmitErrorsHandle(true);
	};

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		const { disableSignBtn } = this.props;
		const { data } = this.state;

		return (
			<Container fluid className={styles.Page}>
				<Wizard steps={this.steps} className={styles.Wizard} />
				<div className={styles.Form}>
					<Row>
						<Col sm={10} offset={{ sm: 1 }} className={styles.Header}>
							<TitleWithHelpBtn title="rod.contracts.sign.header" />
							<Label
								className="sub"
								text={
									<FormattedHTMLMessage id={'rod.contracts.sign.subheader'} />
								}
								font="--gilroy-Medium-24"
								black
							/>

							<ul className={styles.List}>
								{data.map((item, index) => (
									<li>
										<ContractListItem
											{...item}
											errors={this.props.errors}
											resetSubmitErrors={this.props.resetSubmitErrors}
											index={index}
											showDownload={item.status === contractStates.SIGNING}
											showFiles={item.status === contractStates.SIGNING}
											showUpload={item.status === contractStates.SIGNING}
											isFileUploaded={this.isFileUploaded}
										/>
									</li>
								))}
							</ul>
							<div className={styles.Controls}>
								<Button
									text={<FormattedMessage id={'rod.action.send_contract'} />}
									variant={'primary'}
									disabled={disableSignBtn}
									onClick={this.submit}
								/>
								{this.props.resetSubmitErrors.length > 0 &&
									!this.state.isFileUploaded && (
										<div
											style={{
												color: 'var(--color-red)',
												marginTop: '10px',
											}}
										>
											<FormattedMessage id={'rod.error.upload_file'} />
										</div>
									)}
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		);
	}
}

Sign.propTypes = {
	onSubmit: PropTypes.func,
};

Sign.defaultProps = {};

export default compose(withAuth, withUI, withRoot)(Sign);
