// Core
import { FormattedMessage } from 'react-intl';

// Styles
import s from '../Distribution.module.css';

const ErrorsBlock = ({ errors }) => {
	return (
		<>
			{Object.keys(errors).includes('check_box_outlets') && (
				<div className={s.error_container}>
					<span className={s.error}>
						{
							<FormattedMessage
								id={`rod.error.${errors.check_box_outlets[0].rule}`}
							/>
						}
					</span>
				</div>
			)}
		</>
	);
};

export default ErrorsBlock;
