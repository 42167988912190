// Core
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { FormattedHTMLMessage } from 'react-intl';
import {
	ComposableMap,
	Geographies,
	Geography,
	ZoomableGroup,
} from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';

// Utils
import { getCountryCode } from './utils/getCountryCode';
import { selectCountryHandler } from './utils/selectCountryHandler';
import { getTenTopCountries } from './utils/getTenTopCountries';
import lscache from 'lscache';
import { compose } from 'recompose';
import { withUI, withAuth, withRoot } from 'hocs';

// UI
import TopTen from './TopTen/TopTen';

// Icons
import close from 'images/close_button.svg';

// Styles
import styles from './StatisticByTerritoriesChart.module.css';

const StatisticByTerritoriesChart = ({ data, selectedOutlets, sort }) => {
	const countriesCache = lscache.get('countriesCashe');

	const [selectedOutletsCodes, setSelectedOutletsCodes] = useState([]);
	const [helper, setHelper] = useState(false);
	const [content, setContent] = useState('');
	const [auditionsBySelectedCountry, setAuditionsBySelectedCountry] = useState(
		0
	);
	const [maxAuditions, setMaxAuditions] = useState(0);
	const [geo, setGeo] = useState([]);
	const [countries, setCountries] = useState([]);
	const [tenTopCountries, setTenTopCountries] = useState([]);
	const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });

	function handleZoomIn() {
		if (position.zoom >= 4) return;
		setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
	}

	function handleZoomOut() {
		if (position.zoom <= 1) return;
		setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
	}

	function handleReset() {
		setPosition({ coordinates: [0, 0], zoom: 1 });
	}

	function handleMoveEnd(position) {
		setPosition(position);
	}

	useEffect(() => {
		setMaxAuditions(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (geo.length) {
			getCountriesList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geo]);

	useEffect(() => {
		if (countries.length && data.length) {
			const getAuditionsByCountries = countries.map((item) => ({
				...item,
				auditions: sumByCountry(item),
			}));
			const filterNonEmptyCountries = getAuditionsByCountries.filter(
				(item) => item.auditions
			);
			const sortedCountriesByAuditions = filterNonEmptyCountries.sort((a, b) =>
				sort === 'desc' ? b.auditions - a.auditions : a.auditions - b.auditions
			);

			setTenTopCountries(
				getTenTopCountries(sortedCountriesByAuditions, countriesCache)
			);

			if (sortedCountriesByAuditions[0]) {
				setMaxAuditions(sortedCountriesByAuditions[0].auditions);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countries]);

	useEffect(() => {
		setSelectedOutletsCodes(selectedOutlets.map((element) => element.code));
	}, [selectedOutlets]);

	const sumByCountry = (countryProps) => {
		countryProps.code = getCountryCode(countryProps.name);

		const dataBySelectedCountry = data.filter(
			(element) =>
				element.territory_code === countryProps.code &&
				selectedOutletsCodes.includes(element.outlet)
		);

		let auditions = 0;
		dataBySelectedCountry.forEach(
			(element) => (auditions = auditions + parseInt(element.stat_count))
		);

		return auditions;
	};

	const getCountriesList = () => {
		if (geo) {
			const result = geo.map((item) => ({
				code: item.id,
				name: item.properties.name,
			}));

			setCountries(result);
		}
	};

	const paintEachCountry = (countryProps) => {
		if (data && Array.isArray(data)) {
			const result = sumByCountry(countryProps);

			if (result === 0) return 0;
			if (result > 0 && result <= 100) return 1;
			if (result > 100 && result <= 1000) return 2;
			if (result > 1000 && result <= 10000) return 3;
			if (result > 10000 && result <= 50000) return 4;
			if (result > 50000 && result <= 100000) return 5;
			if (result > 100000 && result <= 1000000) return 6;
			return 7;
		}
	};

	const colorScale = scaleLinear()
		.domain([0, 1, 2, 3, 4, 5, 6, 7])
		.range([
			'#FFFFFF',
			'#F6F8E3',
			'#C7CC5037',
			'#C7CC5080',
			'#C7CC50B2',
			'#D6D97C',
			'#C7CC50D8',
			'#C7CC50',
		]);

	return (
		<>
			{helper && (
				<div className={styles.helper}>
					<img onClick={() => setHelper(false)} src={close} alt="" />
					<span>
						<FormattedHTMLMessage id={'rod.drafts.title'} />
					</span>
				</div>
			)}

			{data && (
				<div className={styles.mainContent}>
					<span className={styles.topTerritoriesChartTitle}>
						<FormattedHTMLMessage id={'rod.statistic.top-ten'} />
					</span>
					<div className={styles.mapAndTopTen_wrapper}>
						<div className={styles.topTenWrapper}>
							<div className={styles.zoomBtnWrapper}>
								{tenTopCountries && (
									<TopTen tenTopCountries={tenTopCountries} />
								)}
								<div className={styles.reset}>
									<button onClick={handleZoomIn}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth="3"
										>
											<line x1="12" y1="5" x2="12" y2="19" />
											<line x1="5" y1="12" x2="19" y2="12" />
										</svg>
									</button>
									<button onClick={handleReset}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											height="24"
											width="24"
										>
											<path
												fill="#fff"
												d="M11.9 20.85q-3.675 0-6.262-2.588Q3.05 15.675 3.05 12q0-3.675 2.588-6.263Q8.225 3.15 11.9 3.15q1.95 0 3.688.787 1.737.788 2.962 2.288V3.15h2.4v8.225H12.7V9h4.05q-.8-1.25-2.075-1.975Q13.4 6.3 11.9 6.3q-2.375 0-4.037 1.663Q6.2 9.625 6.2 12t1.663 4.038Q9.525 17.7 11.9 17.7q1.775 0 3.238-1.025Q16.6 15.65 17.25 14h3.275q-.725 3-3.125 4.925-2.4 1.925-5.5 1.925Z"
											/>
										</svg>
									</button>
									<button onClick={handleZoomOut}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth="3"
										>
											<line x1="5" y1="12" x2="19" y2="12" />
										</svg>
									</button>
								</div>
							</div>
						</div>
						<div className={styles.mapWrapper}>
							<ComposableMap data-tip="" tabindex="-1" className={styles.map}>
								<ZoomableGroup
									zoom={position.zoom}
									center={position.coordinates}
									onMoveEnd={handleMoveEnd}
								>
									<Geographies geography="/countries.json">
										{({ geographies }) => {
											setGeo(geographies);
											return geographies.map((geo) => {
												const countryData = paintEachCountry(geo.properties);
												return (
													<Geography
														key={geo.rsmKey}
														geography={geo}
														onMouseEnter={() => {
															selectCountryHandler(
																geo.properties,
																setContent,
																data,
																setAuditionsBySelectedCountry,
																sumByCountry
															);
														}}
														onMouseLeave={() => {
															setContent('');
														}}
														fill={colorScale(countryData)}
														stroke="gray"
														strokeWidth={0.3}
														tabIndex="-1"
													/>
												);
											});
										}}
									</Geographies>
								</ZoomableGroup>
							</ComposableMap>
						</div>
					</div>
					<div className={styles.territories__gradient}>
						<div className={styles.territories__gradientId}>0</div>
						<div className={styles.territories__gradientBox}></div>
						<div className={styles.territories__gradientContent}>
							{maxAuditions && maxAuditions}
						</div>
					</div>
					<ReactTooltip type={'light'} className={styles.territories__tooltip}>
						<div className={styles.territories__tooltip_country}>{content}</div>
						<div>
							<span className={styles.territories__tooltip_title}>
								<FormattedHTMLMessage id={'rod.statistic.value'} />
								:&nbsp;
							</span>
							<span className={styles.territories__tooltip_value}>
								{auditionsBySelectedCountry}
							</span>
						</div>
					</ReactTooltip>
				</div>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(StatisticByTerritoriesChart);
