// Core
import { useEffect, useRef } from 'react';

// UI
import CheckboxOutlets from './CheckboxOutlets/CheckboxOutlets';
import DataBar from 'containers/Modal/DistributionModals/DataBar/DataBar';
import DateErrorsBlock from './ErrorsBlock/DateErrorsBlock';
import OutletsErrorsBlock from './ErrorsBlock/OutletsErrorsBlock';

// Styles
import styles from './ReleaseDateBlock.module.css';

const ReleaseDateBlock = ({
	item,
	personal,
	setPersonal,
	outlets,
	selectOutletsDateCheckbox,
	changeSelectDateOutletsCheckbox,
	outletsList,
	errors,
	resetErrors,
	resetBackErrors,
}) => {
	const errorsRef = useRef(null);

	const fieldRefs = [
		'delivery_start_time',
		'sale_start_date',
		'distribution_outlets',
		'distribution_outlets.0.delivery_start_time',
	];

	useEffect(() => {
		const errorFields = Object.keys(errors);
		if (
			errorFields.length > 0 &&
			errorFields.some((key) => fieldRefs.includes(key))
		) {
			if (errorsRef?.current) {
				errorsRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors]);

	return (
		<div ref={errorsRef} className={styles.releaseDateBlock}>
			<DataBar
				item={item}
				modalType="distribution_date_edit"
				personal={personal}
				setPersonal={setPersonal}
				resetErrors={resetErrors}
				resetBackErrors={resetBackErrors}
			/>
			<DateErrorsBlock errors={errors} item={item} />
			{outlets?.length > 1 && (
				<CheckboxOutlets
					item={item}
					selectOutletsDateCheckbox={selectOutletsDateCheckbox}
					changeSelectDateOutletsCheckbox={changeSelectDateOutletsCheckbox}
					outlets={outlets}
					personal={personal}
					setPersonal={setPersonal}
				/>
			)}
			{selectOutletsDateCheckbox && personal.distribution_outlets?.length > 0 && (
				<div className={styles.outletsDataBar}>
					<DataBar
						item={item}
						outlets={outletsList}
						modalType="distribution_outlets_edit"
						personal={personal}
						setPersonal={setPersonal}
						resetErrors={resetErrors}
						resetBackErrors={resetBackErrors}
					/>
					<OutletsErrorsBlock errors={errors} item={item} />
				</div>
			)}
		</div>
	);
};

export default ReleaseDateBlock;
