// Core
import moment from 'moment';

export const correctDate = (date) => {
	if (!date) return;

	if (typeof date === 'string' && date.includes('T')) {
		return date.split('T')[0];
	}

	if ([10, 19].includes(date.length)) return date;
	const currDate = isNaN(date) ? date : new Date(date);
	const currMonth = currDate.getMonth() + 1;
	const currYear = currDate.getFullYear();
	let currDay = moment(currDate).format('MMM Do YY').split(' ')[1];
	currDay = isNaN(currDay.slice(0, 2))
		? currDay.slice(0, 1)
		: currDay.slice(0, 2);
	let result =
		currYear +
		'-' +
		currMonth.toString().padStart(2, '0') +
		'-' +
		currDay.padStart(2, '0');
	return result;
};
