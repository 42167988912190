// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from '../ReleaseDateBlock.module.css';

const DateErrorsBlock = ({ errors, item }) => {
	return (
		<>
			{Object.keys(errors).includes('delivery_start_time') &&
			Object.keys(errors).includes('sale_start_date') &&
			errors.sale_start_date[0].rule === 'after_or_equal_date' &&
			errors.delivery_start_time[0].rule === 'before_or_equal_date' ? (
				<>
					{Object.keys(errors).includes('delivery_start_time') && (
						<span className={styles.releaseDateBlockHelper}>
							{<FormattedMessage id="rod.error.delivery_later_sale" />}
						</span>
					)}
				</>
			) : (
				<>
					{Object.keys(errors).includes('delivery_start_time') && (
						<span className={styles.releaseDateBlockHelper}>
							{
								<FormattedMessage
									id={
										item.value === '7+'
											? 'rod.error.delivery_min_date_seven'
											: 'rod.error.delivery_min_date_one'
									}
								/>
							}
						</span>
					)}
					{Object.keys(errors).includes('sale_start_date') && (
						<span
							className={styles.releaseDateBlockHelper}
							style={
								Object.keys(errors).includes('delivery_start_time')
									? { marginTop: '10px' }
									: {}
							}
						>
							{
								<FormattedMessage
									id={
										item.value === '7+'
											? 'rod.error.min_date_seven'
											: 'rod.error.min_date_one'
									}
								/>
							}
						</span>
					)}
				</>
			)}
		</>
	);
};

export default DateErrorsBlock;
