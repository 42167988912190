// Core
import React, { useEffect, useState, useRef } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'react-grid-system';
import moment from 'moment';
import union from 'lodash/union';
import { withRoot, withAuth, withUI } from 'hocs';
import { compose } from 'recompose';

// Constants
import { isForCom } from 'constants/urlConstants';

// UI
import { InfoButton } from 'components/Buttons';
import RepertoireInfoFilter from 'material-design/components/RepertoireInfoFilter';
import RepertoireInfoTable from 'material-design/components/RepertoireInfoTable';
import { Loading, NoData } from 'components';
import { Label } from 'components/Labels';

// Icons
import chevronUp from 'images/chevron_up.svg';
import chevronDown from 'images/chevron_down.svg';

// Styles
import styles from './RepertoireRecordingInfo.module.css';

function RepertoireRecordingInfo(props) {
	const [loading, setLoading] = useState(true);
	const [unknownError, setUnknownError] = useState('');
	const [isChevronOpen, setIsChevronOpen] = useState(false);
	const [recordingData, setRecordingData] = useState({
		recordingMetadata: {},
		exploitationTypes: [],
		repertoireOutlets: [],
		repertoireProducts: [],
		recordingOwnership: {},
		recordingTypes: [],
		rightTypes: [],
		commercialModels: [],
	});
	const [ownershipTableData, setOwnershipTableData] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [langs, setLangs] = useState([]);
	const [filtersData, setFiltersData] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [errors, setErrors] = useState({});
	const [countries, setCountries] = useState([]);
	const [genres, setGenres] = useState([]);
	const [isFilterExists, setIsFilterExists] = useState(false);
	const [originRightholders, setOriginRightholders] = useState([]);
	const [contributorRoles, setContributorRoles] = useState([]);
	const [requestParameters, setRequestParameters] = useState({});
	const [incompatibilityError, setIncompatibilityError] = useState('');

	const prevCountRef = useRef();
	const match = useRouteMatch();
	const recordingId = match.params.id;
	const lang = localStorage.getItem('lang');

	const {
		UIContext: { showModal },
	} = props;

	const notSelected = {
		id: -1,
		code: 'EMPTY',
		title_ru: 'Не выбрано',
		title_en: 'Not selected',
	};

	useEffect(() => {
		if (Object.keys(filtersData).length)
			localStorage.setItem('recordingFilters', JSON.stringify(filtersData));
	}, [filtersData]);

	const {
		rootContext: {
			getRecordingMetadata,
			getRecordingOutlets,
			getRecordingOwnership,
			getRecordingTypes,
			getExploitationTypes,
			getCommercialModels,
			getGenres,
			getCountries,
			getLanguages,
			getRecordingsOriginRightholders,
			getContributorRoles,
			getAccountPersonalData,
		},
		authContext: { accountId },
	} = props;

	useEffect(() => {
		const p0 = getCountries();
		const p1 = getGenres();
		const p2 = getCommercialModels();
		const p3 = getRecordingTypes();
		const p4 = getExploitationTypes();
		const p5 = getRecordingMetadata(accountId, recordingId);
		const p6 = getAccountPersonalData(accountId);

		Promise.all([p0, p1, p2, p3, p4, p5, p6])
			.then((values) => {
				setCountries([...correctCountries(values[0])]);
				setGenres(values[1]);

				const rightTypesData =
					values[4].data.length && !isFilterExists
						? values[4].data[0].right_types
						: [];

				setRecordingData((prev) => ({
					...prev,
					commercialModels: values[2].data,
					recordingTypes: values[3].data,
					exploitationTypes: values[4].data,
					rightTypes: rightTypesData,
					recordingMetadata: values[5].data,
				}));

				if (
					localStorage.getItem('recordingFilters') &&
					localStorage.getItem('recordingFilters') !== '{}'
				) {
					setIsFilterExists(true);
					setFiltersData({
						...JSON.parse(localStorage.getItem('recordingFilters')),
						country_id: values[5]?.data?.created_country_id
							? values[5]?.data?.created_country_id
							: isForCom
							? 18
							: 1,
					});
				} else {
					setFiltersData((prev) => ({
						...prev,
						country_id: values[5]?.data?.created_country_id
							? values[5]?.data?.created_country_id
							: isForCom
							? 18
							: 1,
						exploitation_type: values[4].data[0],
						right_type: values[4].data[0].right_types[0],
					}));
				}
			})
			.then(() => {
				const p0 = getLanguages();
				const p1 = getRecordingsOriginRightholders(accountId, recordingId);
				const p2 = getContributorRoles();
				Promise.all([p0, p1, p2]).then((values) => {
					setLangs(values[0]);
					setOriginRightholders(values[1].data);
					setContributorRoles(values[2].data);
				});
			})
			.catch((error) => console.error('promiseAll error', error));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (contributorRoles.length) {
			const exploitationTypeID =
				filtersData.exploitation_type &&
				Object.keys(filtersData.exploitation_type).length > 0
					? filtersData.exploitation_type.id
					: '';
			const rightTypeID =
				filtersData.right_type && Object.keys(filtersData.right_type).length > 0
					? filtersData.right_type.id
					: '';
			const outletID =
				filtersData.outlet && Object.keys(filtersData.outlet).length > 0
					? filtersData.outlet.id
					: '';
			const productID =
				filtersData.product && Object.keys(filtersData.product).length > 0
					? filtersData.product.id
					: '';

			const currDate = new Date();
			const currMonth = currDate.getMonth() + 1;
			const currYear = currDate.getFullYear();
			let currDay = moment().format('MMM Do YY').split(' ')[1];
			currDay = isNaN(currDay.slice(0, 2))
				? currDay.slice(0, 1)
				: currDay.slice(0, 2);
			let dateFrom =
				currDay.padStart(2, '0') +
				'.' +
				currMonth.toString().padStart(2, '0') +
				'.' +
				currYear;

			setRequestParameters({
				country_id: filtersData.country_id,
				exploitationTypeID: exploitationTypeID,
				rightTypeID: rightTypeID,
				outletID: outletID,
				productID: productID,
				dateFrom: dateFrom,
			});

			if (
				prevCountRef.current &&
				(filtersData.country_id !== prevCountRef.current?.country_id ||
					filtersData.exploitation_type.id !==
						prevCountRef.current?.exploitation_type.id ||
					filtersData.right_type.id !== prevCountRef.current?.right_type.id)
			) {
				const p0 = getRecordingOutlets(
					accountId,
					recordingId,
					'en',
					filtersData.country_id,
					exploitationTypeID,
					rightTypeID
				);
				const p1 = getRecordingOutlets(
					accountId,
					recordingId,
					'ru',
					filtersData.country_id,
					exploitationTypeID,
					rightTypeID
				);

				Promise.all([p0, p1])
					.then((values) => {
						setRecordingData((prev) => ({
							...prev,
							repertoireOutlets: values[0].data.map((outlet) => {
								return {
									...outlet,
									title_en: outlet.title,
									title_ru: values[1].data.find(
										(outlet_ru) => outlet_ru.id === outlet.id
									).title,
								};
							}),
						}));
					})
					.then(() => {
						if (filtersData.outlet && Object.keys(filtersData.outlet).length) {
							getProductsByOutletID(filtersData.outlet);
						} else {
							setRecordingData((prev) => ({
								...prev,
								repertoireProducts: [],
							}));
						}
					})
					.then(() => {
						getRecordingOwnership(
							accountId,
							recordingId,
							filtersData.country_id,
							exploitationTypeID,
							rightTypeID,
							outletID,
							productID,
							dateFrom,
							lang
						).then((ownershipData) => {
							setRecordingData((prev) => ({
								...prev,
								repertoireOwnership:
									ownershipData.status === 'ok'
										? ownershipData.data.splitsheet
										: { status: 'Not Ok' },
							}));

							if (ownershipData.status === 'ok') {
								setIncompatibilityError('');
								combineOwnershipData(ownershipData.data.splitsheet);
							} else if (ownershipData.status === 0 && ownershipData.error) {
								setOwnershipTableData([]);
								setIncompatibilityError(ownershipData.error);
							} else if (ownershipData.status !== 0 && ownershipData.error) {
								setUnknownError(ownershipData.error);
							}
							setLoading(false);
						});
					});
			} else if (Object.keys(filtersData).length) {
				getRecordingOwnership(
					accountId,
					recordingId,
					filtersData.country_id,
					exploitationTypeID,
					rightTypeID,
					outletID,
					productID,
					dateFrom,
					lang
				).then((ownershipData) => {
					setRecordingData((prev) => ({
						...prev,
						repertoireOwnership:
							ownershipData.status === 'ok'
								? ownershipData.data.splitsheet
								: { status: 'Not Ok' },
					}));

					if (ownershipData.status === 'ok') {
						setIncompatibilityError('');
						combineOwnershipData(ownershipData.data.splitsheet);
					} else if (ownershipData.status === 0 && ownershipData.error) {
						setOwnershipTableData([]);
						setIncompatibilityError(ownershipData.error);
					} else if (ownershipData.status !== 0 && ownershipData.error) {
						setUnknownError(ownershipData.error);
					}
					setLoading(false);
				});
			}
			prevCountRef.current = filtersData;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersData, contributorRoles, lang]);

	const addContributors = (data) => {
		const titleBlockHolders = originRightholders.filter(
			(item) => item.titles?.length
		);

		if (titleBlockHolders.length) {
			titleBlockHolders.forEach((item) => {
				const recordWithItemsBlock = data.find(
					(lic) => lic.account_id === item.account_id
				);
				if (recordWithItemsBlock) {
					recordWithItemsBlock['titles'] = item.titles;
				}
			});
			return data;
		} else {
			return data;
		}
	};

	const combineOwnershipData = (ownershipData) => {
		ownershipData = originRightholders.length
			? addContributors(Object.values(ownershipData))
			: Object.values(ownershipData);
		let ownershipJustRecords = [];
		let commonRecords = [];
		let restOriginRecords = [];
		ownershipData.forEach((item) => {
			const commonAccount = originRightholders.find(
				(origin) => origin.account_id === item.account_id
			);
			const ownershipRoles = Array.isArray(item.roles)
				? []
				: Object.keys(item.roles).map((str) => +str);
			if (commonAccount) {
				const rightholdersRoles = commonAccount.contributor_roles;
				item.roles = union(ownershipRoles, rightholdersRoles);
				commonRecords.push(item);
			} else {
				item.roles = ownershipRoles;
				ownershipJustRecords.push(item);
			}
		});

		originRightholders.forEach((item) => {
			if (
				!commonRecords.find((common) => common.account_id === item.account_id)
			) {
				if (item.titles && item.titles.length === 0) {
					delete item.titles;
				}

				restOriginRecords.push({
					...item,
					title: item.account_title,
					licensors: [],
					roles: item.contributor_roles,
				});
			}
		});

		const combinedOwnershipData = [
			...ownershipJustRecords,
			...commonRecords,
			...restOriginRecords,
		];
		setOwnershipTableData(combinedOwnershipData);
	};

	const getGenresTitlesByTypes = (genresIDsArr) => {
		if (genresIDsArr && Array.isArray(genresIDsArr)) {
			const result = genresIDsArr.map(
				(id) =>
					genres.find((genre) => genre.id === id || id === genre.code)[
						`title_${lang}`
					]
			);
			return result.join(', ');
		} else return;
	};

	const getTypeTitleById = (id) => {
		return recordingData.recordingTypes.find((type) => type.id === id)[
			`title_${lang}`
		];
	};

	function correctCountries(data) {
		const modifyTitle = (title) => {
			const exeptions = ['the', 'of', 'and', 'и'];
			const lowerTitle = title.toLowerCase();
			if (!lowerTitle.includes(' '))
				return lowerTitle.charAt(0).toUpperCase() + lowerTitle.slice(1);

			const titleAsArray = lowerTitle.split(' ');
			const modifiedTitle = titleAsArray
				.map((word) => {
					return exeptions.includes(word)
						? word
						: word.charAt(0).toUpperCase() + word.slice(1);
				})
				.join(' ');
			return modifiedTitle;
		};

		const modifiedCountries = data.map((obj) => {
			let result = obj;
			result.title_en = modifyTitle(obj.title_en);
			result.title_ru = modifyTitle(obj.title_ru);
			return result;
		});
		return modifiedCountries;
	}

	const getProductsByOutletID = (e) => {
		const {
			rootContext: { getRecordingProducts },
			authContext: { accountId },
		} = props;
		const p0 = getRecordingProducts(accountId, recordingId, e.id);
		const p1 = getRecordingProducts(accountId, recordingId, e.id, 'ru');

		Promise.all([p0, p1])
			.then((values) => {
				setRecordingData((prev) => ({
					...prev,
					repertoireProducts: values[0].data.map((product) => {
						return {
							...product,
							title_en: product.title,
							title_ru: values[1].data.find(
								(product_ru) => product_ru.id === product.id
							).title,
						};
					}),
				}));
				return values[0];
			})
			.then((products) => {
				if (
					!filtersData.product ||
					!products.data.find((prod) => prod.id === filtersData.product.id)
				) {
					setFiltersData((prev) => ({
						...prev,
						outlet: e,
						product: {},
					}));
				} else
					setFiltersData((prev) => ({
						...prev,
						outlet: e,
					}));
			});
	};

	const changeField = (field) => (e) => {
		let newFilter = {};
		if (e.code === 'EMPTY' && field === 'right_type') {
			setFiltersData((prev) => ({
				...prev,
				[field]: {},
				outlet: {},
				product: {},
			}));
			setRecordingData((prev) => ({
				...prev,
				repertoireOutlets: [],
				repertoireProducts: [],
			}));

			return;
		}
		if (e.code === 'EMPTY') {
			setFiltersData((prev) => {
				return field === 'outlet'
					? {
							...prev,
							[field]: {},
							product: {},
					  }
					: {
							...prev,
							[field]: {},
					  };
			});
			setRecordingData((prev) => {
				return field === 'outlet'
					? {
							...prev,
							repertoireProducts: [],
					  }
					: {
							...prev,
					  };
			});
			return;
		}

		switch (field) {
			case 'exploitation_type':
				setRecordingData((prev) => ({
					...prev,
					rightTypes: e.right_types,
				}));
				setFiltersData((prev) => ({
					...prev,
					exploitation_type: e,
					right_type:
						e.right_types && e.right_types.length
							? e.right_types[0]
							: notSelected,
					outlet: {},
					product: {},
				}));
				break;

			case 'outlet':
				getProductsByOutletID(e);
				break;

			case 'country_id':
				newFilter[field] = e.id;
				setFiltersData((prev) => ({
					...prev,
					outlet: {},
					product: {},
				}));
				break;

			case 'right_type':
				newFilter[field] = e;
				setFiltersData((prev) => ({
					...prev,
					outlet: {},
					product: {},
				}));
				break;
			default:
				newFilter[field] = e;
				break;
		}
		if (field !== 'exploitation_type' && field !== 'outlet')
			setFiltersData((prev) => ({
				...prev,
				...newFilter,
			}));
	};

	const handleOnClick = () => {
		setIsChevronOpen((prev) => !prev);
	};

	const mainRender = () => {
		return (
			<>
				<div className={styles.grid}>
					<div>
						<span className={styles.title}>
							<FormattedMessage id={'rod.composition.info.title'} />
						</span>
						{recordingData.recordingMetadata && (
							<div className={styles.header__info}>
								<div className={styles.headerInfo__item}>
									<span className={styles.headerInfoItem__title}>
										<FormattedMessage id={'rod.for_all.performers'} />
									</span>
									<span>
										{recordingData.recordingMetadata.performers.join(',') ||
											'-'}
									</span>
								</div>
								<div className={styles.headerInfo__item}>
									<span className={styles.headerInfoItem__title}>
										<FormattedMessage
											id={'rod.composition.info.catalog_number'}
										/>
									</span>
									<span>
										{recordingData.recordingMetadata.catalogue_number || '-'}
									</span>
								</div>
								<div className={styles.headerInfo__item}>
									<span className={styles.headerInfoItem__title}>
										<FormattedMessage id={'rod.composition.info.genres'} />
									</span>
									<span>
										{getGenresTitlesByTypes(
											recordingData.recordingMetadata.genres
										) || '-'}
									</span>
								</div>
								<div className={styles.headerInfo__item}>
									<span className={styles.headerInfoItem__title}>
										<FormattedMessage
											id={'rod.recordery.info.recorded_in_country'}
										/>
									</span>
									{countries && recordingData.recordingMetadata && (
										<span className={styles.RepertoireInfoItem__flagContainer}>
											<span>
												{countries.find(
													(country) =>
														country.id ===
														recordingData.recordingMetadata.created_country_id
												)?.title_en || '-'}
											</span>
										</span>
									)}
								</div>
								<div className={styles.headerInfo__item}>
									<span className={styles.headerInfoItem__title}>
										<FormattedMessage
											id={'rod.recordery.info.recording_type'}
										/>
									</span>
									<span>
										{getTypeTitleById(
											recordingData.recordingMetadata.recording_type_id
										) || '-'}
									</span>
								</div>
								<div className={styles.headerInfo__item}>
									<span className={styles.headerInfoItem__title}>
										<FormattedMessage
											id={'rod.composition.info.creation_date'}
										/>
									</span>
									<span>
										{recordingData.recordingMetadata.published_date || '-'}
									</span>
								</div>
								<div className={styles.headerInfo__item}>
									<span className={styles.headerInfoItem__title}>
										<FormattedMessage
											id={'rod.composition.info.lyrics_language'}
										/>
									</span>
									<span>{recordingData.recordingMetadata.langs || '-'}</span>{' '}
								</div>
								<div className={styles.headerInfo__item}>
									<span className={styles.headerInfoItem__title}>
										<FormattedMessage
											id={'rod.recordery.info.parents_notice'}
										/>
									</span>
									<span>
										{recordingData.recordingMetadata.parental_warning_type
											? lang === 'en'
												? recordingData.recordingMetadata
														.parental_warning_type_en
												: recordingData.recordingMetadata
														.parental_warning_type_ru
											: '-'}
									</span>
								</div>
								<div className={styles.headerInfo__item}>
									<span className={styles.headerInfoItem__title}>
										<FormattedMessage id={'rod.for_all.subtitle'} />
									</span>
									<span>
										{recordingData.recordingMetadata.subtitle
											? recordingData.recordingMetadata.subtitle
											: '-'}
									</span>
								</div>
							</div>
						)}
						<div className={styles.RepertoireSongInfo__main}>
							<span className={styles.title}>
								<FormattedMessage
									id={'rod.composition.info.original_rightholders'}
								/>
							</span>
							<div className={styles.RepertoireSongInfo__mainInfoTable}>
								{ownershipTableData.length > 0 &&
								Object.keys(requestParameters).length > 0 ? (
									<>
										<Row className={styles.row_header}>
											<Col width={'20vw'} className={styles.col_rightHolder}>
												<FormattedHTMLMessage
													id={'rod.composition.info.rightholder'}
												/>
											</Col>
											<Col
												width={
													process.env.REACT_APP_CLIENT !== 'mts' &&
													process.env.REACT_APP_CLIENT !== 'dgtal'
														? '20vw'
														: '25vw'
												}
												className={styles.col_role}
											>
												<FormattedHTMLMessage
													id={'rod.composition.info.role'}
												/>
											</Col>
											<Col width={'5vw'} className={styles.col_own}>
												<span>OWN</span>
											</Col>
											<Col width={'5vw'} className={styles.col_col}>
												<span>COL</span>
											</Col>
											{process.env.REACT_APP_CLIENT !== 'mts' &&
												process.env.REACT_APP_CLIENT !== 'dgtal' && (
													<Col width={'5vw'} className={styles.col_rev}>
														<span>REV</span>
													</Col>
												)}
											<Col className={styles.col_help}>
												<InfoButton
													onClick={showModal({
														text: (
															<>
																{process.env.REACT_APP_CLIENT === 'mts' ||
																process.env.REACT_APP_CLIENT === 'dgtal' ? (
																	<FormattedHTMLMessage
																		id={'rod.repertoire.help.own_col_rev.mts'}
																	/>
																) : (
																	<FormattedHTMLMessage
																		id={'rod.repertoire.help.own_col_rev'}
																	/>
																)}
															</>
														),
													})}
												></InfoButton>
											</Col>
										</Row>

										<RepertoireInfoTable
											repertoireName={'recordings'}
											data={ownershipTableData}
											contributorRoles={contributorRoles}
										/>
									</>
								) : incompatibilityError ? (
									<div className={styles.error}>{incompatibilityError}</div>
								) : unknownError ? (
									<div className={styles.Page}>
										<div className={styles.Form}>
											<div className={styles.Title}>
												<Label font="--gilroy-Medium-32" black>
													<FormattedMessage
														id={'rod.error_page.unavailable.header'}
													/>
												</Label>
												<div style={{ marginBottom: '10px' }}></div>
												<Label font="--gilroy-Medium-24" black>
													<FormattedMessage
														id={'rod.error_page.unavailable.body'}
													/>
												</Label>
											</div>
											{unknownError && (
												<>
													<div
														className={styles.detailsWrapper}
														onClick={handleOnClick}
													>
														<Label
															className={styles.detailsHeader}
															font="--gilroy-Medium-22"
															black
														>
															<FormattedMessage
																id={'rod.error_page.unavailable.datails'}
															/>
														</Label>
														<img
															src={!isChevronOpen ? chevronUp : chevronDown}
															alt=""
														/>
													</div>
													{isChevronOpen && (
														<div
															className={styles.detailsText}
															font="--gilroy-Medium-20"
														>
															{window.btoa(
																unescape(encodeURIComponent(unknownError))
															)}
														</div>
													)}
												</>
											)}
										</div>
									</div>
								) : (
									!ownershipTableData.length && <NoData non_margin={true} />
								)}
							</div>
						</div>
					</div>
					<div className={styles.filter__container}>
						<span className={styles.title}>
							<FormattedMessage id={'rod.composition.info.filters.title'} />
						</span>
						<RepertoireInfoFilter
							repertoireData={recordingData}
							filtersData={filtersData}
							changeField={changeField}
							errors={errors}
							countries={countries}
						/>
					</div>
				</div>
			</>
		);
	};

	return loading ? <Loading /> : <>{mainRender()}</>;
}
export default compose(withAuth, withRoot, withUI)(RepertoireRecordingInfo);
